import React from 'react';
import {Button, FormGroup, Modal} from 'react-bootstrap';
import FloatingLabels from '../FloatingLabels';
import './style.scss';

const labelStyle = {
  color: '#b3a369',
};

const VerifyModal = React.memo(props => {
  const form = {
    username: props.verify.username,
    password: props.verify.password,
    clubId: props.clubId,
    pinCode: props.verify.pinCode,
  };

  return (
    <div className="verify-modal">
      <Modal className="verifyModal" show={props.verify.modal} onHide={props.sendForm('cancel', props.verify.action)}>
        <Modal.Body>
          <h4>{props.lang('RE-ENTER YOUR ACCESS INFORMATION')}</h4>
          {!props.verify.ifPin ? (
            <FormGroup className="line-form--gold">
              <FloatingLabels
                style={labelStyle}
                type="password"
                placeholder={props.lang('Password')}
                value={props.verify.password}
                onChange={props.verifyPassword}
                autoComplete="new-password"
              />
            </FormGroup>
          ) : (
            <FormGroup className="line-form--gold">
              <FloatingLabels
                style={labelStyle}
                type="password"
                placeholder={props.lang('PIN')}
                id="pinCode"
                value={props.verify.pinCode}
                onChange={props.verifyPin}
                autoComplete="new-password"
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/*<Button className="modalCancel" onClick={props.resetPerson}>RESET</Button>*/}
          <Button className="modalOk" onClick={props.sendForm('ok', props.verify.action, form)}>
            {props.lang('OK')}
          </Button>
          <Button className="modalCancel" onClick={props.logout()}>
            {props.lang('Log out')}
          </Button>
          <Button className="modalCancel" onClick={props.sendForm('cancel', props.verify.action)}>
            {props.lang('CANCEL')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default VerifyModal;
