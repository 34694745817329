import React from 'react';
import {Button, Row, FormGroup, Col} from 'react-bootstrap';
import moment from 'moment';
import DatePicker from '../DatePicker';
import FloatingLabel from '../FloatingLabels';
import PhonePrefix from '../../components/PhonePrefix';
import GDPR from '../../containers/ContactInformation/components/gdpr';
import GDPRlang from '../../utils/translator';
import RequiredField from '../../components/RequiredField/index';

import './style.scss';

class TellUsMoreForm extends React.PureComponent {
  componentDidMount() {
    const birthDate = moment(this.props.data.birthDate || new Date(1990, 0, 1));
    this.props.onChange('birthDate', birthDate.format('YYYY-MM-DD'));
  }
  render() {
    const birthDate = moment(this.props.data.birthDate || new Date(1990, 0, 1));

    return (
      <div className="tellUsMoreForm line-form">
        <Row style={{margin: 0}}>
          <Col md={8} mdOffset={2} sm={12}>
            <Col md={6}>
              <FormGroup className="input-group">
                <FloatingLabel
                  placeholder={this.props.lang('Name')}
                  id="name"
                  onChange={e => this.props.onChange(e.target.id, e.target.value)}
                  value={this.props.data.name || ''}
                />
                <RequiredField valid={this.props.validate('name')} />
              </FormGroup>
              <FormGroup className="input-group">
                <FloatingLabel
                  placeholder={this.props.lang('Surname')}
                  id="surname"
                  onChange={e => this.props.onChange(e.target.id, e.target.value)}
                  value={this.props.data.surname || ''}
                />
                <RequiredField valid={this.props.validate('surname')} />
              </FormGroup>
              <FormGroup className="input-group">
                <FloatingLabel
                  placeholder={this.props.lang('Email')}
                  id="email"
                  onChange={e => this.props.onChange(e.target.id, e.target.value)}
                  value={this.props.data.email || ''}
                />
                <RequiredField valid={this.props.validate('email')} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="input-group">
                <DatePicker
                  value={birthDate}
                  placeholder={this.props.lang('birth date')}
                  lang={this.props.lang}
                  onChange={e => this.props.onChange('birthDate', moment(e.time).format('YYYY-MM-DD'))}
                />
                <RequiredField valid={this.props.validate('birthDate')} />
              </FormGroup>
              <FormGroup className="input-group input-group__phonePrefix">
                <PhonePrefix info={this.props.data} codes={this.props.codes} change={this.props.onChange} clubInfo={this.props.clubInfo} />
                <FloatingLabel
                  placeholder={this.props.lang('Phone number')}
                  id="mobile"
                  onChange={e => this.props.onChange(e.target.id, e.target.value)}
                  value={this.props.data.mobile || ''}
                />
                <RequiredField valid={this.props.validate('mobile')} />
              </FormGroup>
              <FormGroup id="radioGroup" className="input-group">
                <ul>
                  <li style={{width: '46%'}}>
                    <label htmlFor="radio1" className={this.props.checked === 'female' || this.props.checked === 'F' || this.props.checked === 'Female' ? 'checkedLabel' : ''}>
                      <Button className="btnRadio radio__button" onClick={() => this.props.onChange('gender', 'F')}>
                        {this.props.lang('Female')} <i className="fa fa-venus fa-lg" aria-hidden="true"></i>
                      </Button>
                    </label>
                  </li>
                  <li style={{width: '8%'}}> </li>
                  <li style={{width: '46%'}}>
                    <label htmlFor="radio2" className={this.props.checked === 'male' || this.props.checked === 'M' || this.props.checked === 'Male' ? 'checkedLabel' : ''}>
                      <Button className="btnRadio radio__button" onClick={() => this.props.onChange('gender', 'M')}>
                        {this.props.lang('Male')} <i className="fa fa-mars fa-lg" aria-hidden="true"></i>
                      </Button>
                    </label>
                  </li>
                </ul>
              </FormGroup>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col md={6} mdOffset={3} style={{color: '#fff'}}>
            <GDPR headingStyle={{marginBottom: 16}} value={this.props.gdprOption} onChange={this.props.change('gdpr')} lang={GDPRlang} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default TellUsMoreForm;
