import {connect} from 'react-redux';
import TellUsMoreComponent from './TellUsMore.js';
import {connectLanguage} from '../../utils/connectLanguage';

import {selectDuplicate, createPersonContainer, checkPersonContainer} from './actions.js';
import {tellusmoreShowModal, personChangeField} from '../../Actions/index';
import {selectPerson, validateField, selectApp, selectClubInfo} from '../../App/selectors';
import {selectDuplicatePerson, selectAllClubs} from './selectors';
import {codes} from '../../utils/phoneCodes';

import {selectGdprOption} from '../../containers/ContactInformation/selectors';

let selectedPersonId = null;

const mapStateToProps = state => {
  const clubInfo = selectClubInfo(state);
  return {
    duplicates: selectDuplicatePerson(state).duplicates,
    selected: selectDuplicatePerson(state).selected,
    person: selectPerson(state),
    selectedDuplicate: selectedPersonId,
    modal: selectDuplicatePerson(state).showModal,
    validateField: name => validateField(state, name),
    app: selectApp(state),
    clubs: selectAllClubs(state),
    phoneCodes: codes,
    clubInfo,
    loader: selectDuplicatePerson(state).loader,
    gdprOption: selectGdprOption(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkPerson: person => () => checkPersonContainer(dispatch, person),
    showModal: () => () => dispatch(tellusmoreShowModal(true)),
    hideModal: () => () => dispatch(tellusmoreShowModal(false)),
    onChange: () => (id, value) => dispatch(personChangeField(id, value)),
    change: id => value => dispatch(personChangeField(id, value)),
    createPerson: (duplicate, type, person, index) => createPersonContainer(dispatch, duplicate, type, person, index),
    selectDuplicatePerson: index => () => dispatch(selectDuplicate(index)),
  };
};

const TellUsMore = connect(mapStateToProps, mapDispatchToProps)(TellUsMoreComponent);
export default connectLanguage(TellUsMore, connect);
