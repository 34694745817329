import './index.scss';
import React from 'react';
import DatePicker from './DatePicker.js';
import Modal from './Modal.js';

function EnhanceDatePicker(props) {
  function onModalClose(event) {
    if (event.target === event.currentTarget) props.onCancel();
  }

  return (
    <div style={{display: props.isOpen ? '' : 'none'}} onClick={props.modal ? null : onModalClose} className="datepicker-modal">
      <DatePicker {...props} />
    </div>
  );
}

function ModalDatePicker(props) {
  if (!props.isPopup) return <DatePicker {...props} />;

  // document.body.setAttribute('style', 'overflow-y: hidden');
  return (
    <Modal {...props}>
      <EnhanceDatePicker />
    </Modal>
  );
}

ModalDatePicker.defaultProps = {
  modal: true,
  isPopup: true,
  isOpen: false,
  theme: 'default',
  value: new Date(),
  min: new Date(1901, 0, 1),
  max: new Date(),
  dateFormat: ['YYYY', 'M', 'D'],
  onSelect: () => {},
  onCancel: () => {},
};

export default ModalDatePicker;
