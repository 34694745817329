/* eslint-disable no-useless-escape */
export const validatePhoneNumber = (number, code) => {
  const rePhone = /[0-9]/g;
  if (!number || !number.match(rePhone) || number.match(rePhone).length < 0) return false;
  if (!code) return false;
  return number.match(rePhone).join('').length === number.length;
};

export const validateBirthdate = (dateStr, type) => {
  let date = new Date(),
    currentDate = new Date(dateStr);
  if (type === 'birthDate') {
    date.setFullYear(date.getFullYear() - 16);
    if (currentDate > date || dateStr === '' || !dateStr) return false;
  }
  if (type === 'startDate') {
    date.setFullYear(date.getFullYear() - 1);
    if (currentDate < date || dateStr === '' || !dateStr) return false;
  }
  return true;
};

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
