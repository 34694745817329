import React from 'react';
import {Button, Table} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import TextFilter from 'react-text-filter';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import './style.scss';

class Contacts extends React.PureComponent {
  state = {
    activePage: 1,
    headMenuActive: 'leads list',
    filter: '',
  };

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
  }

  contactsFilter() {
    return contact => {
      return (
        contact.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1 ||
        contact.surname.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1 ||
        contact.club.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1 ||
        contact.source.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1
      );
    };
  }
  render() {
    return (
      <Page nav>
        <div className="contacts">
          <div className="contacts__head">
            <ul>
              <li>
                <Button onClick={() => this.setState({headMenuActive: 'leads list'})} className={this.state.headMenuActive === 'leads list' ? 'customStyle active' : 'customStyle'}>
                  {lang('leads list')}
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => this.setState({headMenuActive: 'converted list'})}
                  className={this.state.headMenuActive === 'converted list' ? 'customStyle active' : 'customStyle'}>
                  {lang('converted list')}
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => this.setState({headMenuActive: 'leavers list'})}
                  className={this.state.headMenuActive === 'leavers list' ? 'customStyle active' : 'customStyle'}>
                  {lang('leavers list')}
                </Button>
              </li>
              <div className="addContact__icon">
                <img src={require('../../assets/add_user_g.png')} alt={'add-user'} />
                <div className="icon-search">
                  <TextFilter onFilter={e => this.setState({filter: e.target.value})} debounceTimeout={100} minLength={1} placeholder={lang('Search')} className="text-filter" />
                </div>
              </div>
            </ul>
          </div>
          {this.renderContacts(this.props.contacts.filter(this.contactsFilter()))}
        </div>
      </Page>
    );
  }

  renderContacts(contacts) {
    return (
      <div className="tablePadding">
        {contacts.length > 0 ? (
          <div className="heightTable">
            <Table className="contacts__table">
              <thead>
                <tr>
                  <th>{lang('Name')}</th>
                  <th>{lang('Surname')}</th>
                  <th>{lang('Club')}</th>
                  <th>{lang('Source')}</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => {
                  if (index >= (this.state.activePage - 1) * 10 && index < (this.state.activePage - 1) * 10 + 10) {
                    return (
                      <tr key={`contact${index}`}>
                        <td>{contact.name}</td>
                        <td>{contact.surname}</td>
                        <td>{contact.club}</td>
                        <td>{contact.source}</td>
                        <td> </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="ContactsNoFound">{lang('Contacts are not found')}</div>
        )}
        {contacts.length > 10 && (
          <Pagination activePage={this.state.activePage} itemsCountPerPage={10} totalItemsCount={contacts.length} pageRangeDisplayed={5} onChange={e => this.handlePageChange(e)} />
        )}
      </div>
    );
  }
}

export default Contacts;
