import moment from 'moment';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Wrapper from '../../../components/Wrapper/index';
import getDateFormat from '../../../utils/helpers/getDateFormat';
import ContractFooter from '../components/ContractFooter';
import ContractHeader from '../components/ContractHeader';
import FieldRenderer from '../components/FieldRenderer';
import FuturePayments from '../components/FuturePayments';
import InicialPayment from '../components/InicialPayment';
import MemberInfo from '../components/MemberInfo';
import {getInObj} from '../helpers';

const SpainContract = React.memo(props => {
  const getRenderData = () => {
    const headerFields = [[{label: props.lang('CLUB'), value: getInObj(props, ['clubInfo', 'club', 'name'], ''), size: 12}]];

    const memberInfoFields = [
      [
        {label: props.lang('NAME'), value: getInObj(props, ['person', 'name'], ''), size: 6},
        {label: props.lang('SURNAME'), value: getInObj(props, ['person', 'surname'], ''), size: 6},
      ],
      [
        {
          label: props.lang('DATE OF BIRTH'),
          value: moment(getInObj(props, ['person', 'birthDate'], '')).format(getDateFormat(9)),
          size: 4,
        },
        {label: props.lang('STREET'), value: getInObj(props, ['person', 'street'], ''), size: 4},
        {
          label: `${props.lang('ZIP CODE')}/${props.lang('CITY')}`,
          value: `${getInObj(props, ['person', 'postalCode'], '')}/${getInObj(props, ['person', 'city'], '')}`,
          size: 4,
        },
      ],
      [
        {
          label: props.lang('PHONE NUMBER'),
          value: `${getInObj(props, ['person', 'phoneCode'], '')} ${getInObj(props, ['person', 'mobile'], '')}`,
          size: 4,
        },
        {label: props.lang('E-MAIL'), value: getInObj(props, ['person', 'email'], ''), size: 4},
        {
          label: props.lang('TYPE OF MEMBERSHIP'),
          value: `${getInObj(props, ['selectedMembership', 'title'], '')} (${getInObj(props, ['selectedMembership', 'externalId'], '')})`,
          size: 4,
        },
      ],
      [
        {
          label: props.lang('START OF MEMBERSHIP'),
          value: moment(getInObj(props, ['person', 'startDate'])).format(getDateFormat(9)),
          size: 6,
        },
      ],
    ];

    const inicialPaymentFields = [
      [
        {
          label: props.lang('FIRST FEE'),
          value: `${getInObj(props, ['contractInfo', 'joiningFeePrice'], '0')} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: props.lang('ADVANCED PAYMENT'),
          value: `${getInObj(props, ['contractInfo', 'deposit'], '0')} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: props.lang('MONTHLY FEE (PRO RATA)'),
          value: `${getInObj(props, ['contractInfo', 'proRataPrice'], '0')} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: props.lang('TOTAL'),
          value: `${Number(getInObj(props, ['contractInfo', 'totalAmount'], '0')).toFixed(2)} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ],
    ];

    const futurePaymentsFields = [
      [
        {
          label: props.lang('Monthly Fee'),
          value: `${getInObj(props, ['contractInfo', 'normalPeriodPrice'], '0')} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ],
    ];

    if (props.contractInfo.startupCampaignEndDate) {
      const date = `(${moment()
        .add(1, 'month')
        .startOf('month')
        .format('DD/MM')} - 
			${
        moment(props.contractInfo.startupCampaignEndDate).isBefore(
          moment()
            .add(1, 'month')
            .startOf('month')
        )
          ? moment(
              moment()
                .add(1, 'month')
                .startOf('month')
            )
              .endOf('month')
              .format('DD/MM/YYYY')
          : moment(props.contractInfo.startupCampaignEndDate).format('DD/MM/YYYY')
      })`;

      futurePaymentsFields.unshift([
        {
          label: `${props.lang('SPECIAL OFFER')} ${date}`,
          value: `${getInObj(props, ['contractInfo', 'startupCampaignPrice'], '0')} ${getInObj(props, ['currency'], '')}`,
          size: 12,
        },
      ]);
    }

    return {
      headerFields,
      memberInfoFields,
      inicialPaymentFields,
      futurePaymentsFields,
    };
  };

  const renderData = getRenderData();
  return (
    <Wrapper>
      <div className="contract">
        <ContractHeader lang={props.lang} tryToSavePhoto={props.tryToSavePhoto} photo={props.photo}>
          <FieldRenderer rows={renderData.headerFields} />
        </ContractHeader>
        <br />
        <MemberInfo tryToSavePhoto={props.tryToSavePhoto} photo={props.photo} lang={props.lang}>
          <FieldRenderer rows={renderData.memberInfoFields} />
        </MemberInfo>
        <br />
        <Row>
          <Col md={6}>
            <InicialPayment lang={props.lang}>
              <FieldRenderer rows={renderData.inicialPaymentFields} />
            </InicialPayment>
          </Col>
          <Col md={6}>
            <FuturePayments lang={props.lang}>
              <FieldRenderer rows={renderData.futurePaymentsFields} />
            </FuturePayments>
          </Col>
        </Row>
        <br />
        <ContractFooter
          signature={props.signature}
          signatureSalesperson={props.signatureSalesperson}
          saveSignature={props.saveSignature}
          payWithCard={props.payWithCard}
          clubInfo={props.clubInfo}
          language={props.language}
          lang={props.lang}
        />
      </div>
      {props.children}
    </Wrapper>
  );
});

export default SpainContract;
