import {createSelector} from 'reselect';
import {CLUB_TOUR_EVENTS_DETAIL_PAGE} from '../../../App/constants.js';
import {getClub} from '../../../App/selectors';

const getEvents = state => getClub(state).get('events');
const getEventDetailId = (state, props) => props.params.eventId;

/**
 * create a {src,title} object from image src
 * @param src string image src (uri or base64)
 * @param title image title string
 */
const imageToObject = (src, title) => ({src: src, title: title});

/**
 * fill event identifier in a route mask
 * club-tour/event/:eventId -> club-tour/event/123456
 * @param event event item object loaded from the API
 * @return event
 */

const addEventUrl = event => Object.assign({}, event, {url: CLUB_TOUR_EVENTS_DETAIL_PAGE.replace(':eventId', event.structureId)});

/**
 * Transform contents of images keys on a news item to objects with format { src, title } from src strings (can be image url or base64)
 *
 * @param event object loaded from the API
 * @return event
 * */
const transformImagesToObjects = event =>
  Object.assign({}, event, {
    coverImage: event.coverImage ? imageToObject(event.coverImage, event.title) : null,
    cover2Image: event.cover2Image ? imageToObject(event.cover2Image, event.title) : null,
    titleImage: event.titleImage ? imageToObject(event.titleImage, event.title) : null,
  });

export const selectEvents = createSelector([getEvents], events => (events ? events.toJS().map(event => transformImagesToObjects(addEventUrl(event))) : null));

export const selectEventDetail = createSelector([getEvents, getEventDetailId], (allEvents, eventId) => {
  const event = allEvents.find(item => Number(item.get('structureId')) === Number(eventId));
  return event ? transformImagesToObjects(addEventUrl(event.toJS())) : null;
});
