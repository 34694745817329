import moment from 'moment';
import React, {PureComponent} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import getDateFormat from '../../../utils/helpers/getDateFormat';

class ContractHeader extends PureComponent {
  state = {
    showModal: false,
    signatureSalesperson: null,
    signatureCanvasWidth: 0,
    signatureCanvasHeight: 0,
  };

  componentWillMount() {
    var width = window.innerWidth;
    var signatureCanvasWidth = 0;
    var signatureCanvasHeight = 0;

    if (width < 480) {
      signatureCanvasWidth = window.innerWidth - 50;
      signatureCanvasHeight = (signatureCanvasWidth * 1) / 5;
      this.setState({signatureCanvasWidth: signatureCanvasWidth, signatureCanvasHeight: signatureCanvasHeight});
    } else {
      signatureCanvasWidth = Math.max(window.innerWidth || 0) / 1.5;
      signatureCanvasHeight = (signatureCanvasWidth * 1) / 5;
      this.setState({signatureCanvasWidth: signatureCanvasWidth, signatureCanvasHeight: signatureCanvasHeight});
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={4} className="contract__field" onClick={() => this.setState({showModal: true, signatureSalesperson: false})}>
            <p className="contract__field__label">{this.props.lang('MEMBER SIGNATURE')}</p>
            {(this.props.signature && (
              <p className="contract__signature">
                <img src={this.props.signature} alt="signature" />
              </p>
            )) || <p className="contract__signature"></p>}
            <hr />
          </Col>
          {this.props.payWithCard && <Col md={8} className="contract__field" />}
          <Col md={4} className="contract__field">
            <p className="contract__field__label">{this.props.lang('DATE')}</p>
            <p style={{minHeight: '30px', color: 'black', textAlign: 'left'}}>{moment().format(getDateFormat(8))}</p>
            <hr />
          </Col>
        </Row>

        <Row>
          <Modal dialogClassName="customModal" show={this.state.showModal} onHide={() => this.setState({showModal: false})} title={'Signature'}>
            <SignatureCanvas
              ref={ref => (this.SignatureCanvas = ref)}
              onEnd={() => this.props.saveSignature('customer')(this.state.signatureSalesperson)(this.SignatureCanvas.getCanvas())}
              velocityFilterWeight={0.00001}
              canvasProps={{
                width: this.state.signatureCanvasWidth,
                height: this.state.signatureCanvasHeight,
              }}
            />
            <Modal.Footer style={{textAlign: 'center'}}>
              <Button onClick={() => this.setState({showModal: false})}>{this.props.lang('Close')}</Button>
            </Modal.Footer>
          </Modal>
        </Row>

        <Row className="contract__footer">
          <Col md={4}>
            <p dangerouslySetInnerHTML={{__html: this.props.clubInfo.club.address.replace(/\n/g, '<br />')}} className="text-gold"></p>
          </Col>

          <Col md={4}>
            <p>
              <span className="text-gold">{'T: '}&nbsp;&nbsp;&nbsp;</span>
              {this.props.clubInfo.club.phone}
            </p>
          </Col>

          <Col md={4}>
            <p>
              <span className="text-gold">{'E: '}&nbsp;&nbsp;&nbsp;</span>
              {this.props.clubInfo.club.email}
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContractHeader;
