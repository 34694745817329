import {connect} from 'react-redux';
import {connectLanguage} from '../../../utils/connectLanguage';
import NewsComponent from './News.js';
import {selectNews} from './selectors.js';

const mapStateToProps = state => {
  return {
    news: selectNews(state),
  };
};

const News = connect(mapStateToProps, null)(NewsComponent);
export default connectLanguage(News, connect);
