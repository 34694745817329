import {
  API_LOGIN_SUCCESS,
  API_GET_CLUBS_SUCCESS,
  API_CLUB_INFO_SUCCESS,
  API_PERSON_DUPLICATE_SUCCESS,
  LOGIN_REMOVE_DATA,
  API_UPDATE_CLUB_INFO,
  API_REQUEST_CHECKOUT_DATA,
  API_CHECKOUT_DATA_SUCCESS,
  API_NOTIFY,
} from './constants';

import {CONFIRM_LOADER} from '../App/constants';

export const loginSuccess = data => {
  return {type: API_LOGIN_SUCCESS, data};
};

export const getClubsSuccess = data => {
  return {type: API_GET_CLUBS_SUCCESS, data};
};

export const clubInfoSuccess = data => {
  return {type: API_CLUB_INFO_SUCCESS, data};
};

export const clubUpdateInfo = data => {
  return {type: API_UPDATE_CLUB_INFO, data};
};

export const personDuplicateSuccess = data => {
  return {type: API_PERSON_DUPLICATE_SUCCESS, data};
};

export const loginRemoveData = () => {
  return {type: LOGIN_REMOVE_DATA};
};

export const confirmLoader = load => {
  return {type: CONFIRM_LOADER, load};
};

export function callCheckoutRequest(data) {
  return {type: API_REQUEST_CHECKOUT_DATA, data};
}

export function saveCheckoutData(data) {
  return {type: API_CHECKOUT_DATA_SUCCESS, data};
}

export function notify(reference) {
  return {type: API_NOTIFY, reference};
}
