import {connect} from 'react-redux';
import {connectLanguage} from '../../../../utils/connectLanguage';
import {selectNews, selectNewsDetail} from '../selectors.js';
import DetailComponent from './Detail.js';

const mapStateToProps = (state, ownProps) => {
  return {
    newsItem: selectNewsDetail(state, ownProps),
    allNews: selectNews(state),
  };
};

const Detail = connect(mapStateToProps, null)(DetailComponent);
export default connectLanguage(Detail, connect);
