/* eslint-disable no-restricted-globals */
let refresh_prepare = 1;

export function checkRefresh() {
  // Get the time now and convert to UTC seconds
  var today = new Date();
  var now = Date.parse(today);

  // Get the cookie
  var cookie = document.cookie;
  console.warn(cookie);
  var cookieArray = cookie.split('; ');
  // Parse the cookies: get the stored time
  for (var loop = 0; loop < cookieArray.length; loop++) {
    var nameValue = cookieArray[loop].split('=');
    // Get the cookie time stamp
    if (nameValue[0].toString() === 'SHTS') var cookieTime = parseInt(nameValue[1]);
    // Get the cookie page
    if (nameValue[0].toString() === 'SHTSP') var cookieName = nameValue[1];
  }
  console.warn(cookieName, cookieTime, cookieName === escape(location.hostname), Math.abs(now - cookieTime) / 1000, 'seconds');
  if (cookieName && cookieTime && cookieName === escape(location.hostname) && Math.abs(now - cookieTime) < 60000)
    console.warn('refresh in', Math.abs(now - cookieTime) / 1000, 'seconds');
  // Refresh detected
  // Insert code here representing what to do on
  // a refresh
  // If you would like to toggle so this refresh code
  // is executed on every OTHER refresh, then
  // uncomment the following line
  // refresh_prepare = 0;
  else {
    console.warn('deleting storage');
    localStorage.clear();
  }

  // You may want to add code in an else here special
  // for fresh page loads
}

export function prepareForRefresh() {
  console.warn('setting up a cookie', location.hostname);
  if (refresh_prepare > 0) {
    // Turn refresh detection on so that if this
    // page gets quickly loaded, we know it's a refresh
    var today = new Date();
    var now = Date.parse(today);
    document.cookie = 'SHTS=' + now + '; Path=/';
    document.cookie = 'SHTSP=' + escape(location.hostname) + '; Path=/';
  } else {
    // Refresh detection has been disabled
    document.cookie = 'SHTS=;';
    document.cookie = 'SHTSP=;';
  }
}

export function disableRefreshDetection() {
  // The next page will look like a refresh but it actually
  // won't be, so turn refresh detection off.
  refresh_prepare = 0;

  // Also return true so this can be placed in onSubmits
  // without fear of any problems.
  return true;
}
