import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Checkbox from '../../../components/Checkbox';

import {store} from '../../../App/store';
import {selectLangObj, selectClubInfo, selectRegion} from '../../../App/selectors';

export default class GDPR extends Component {
  render() {
    const {lang} = this.props;
    const state = store.getState();

    const langObj = selectLangObj(state);
    const clubInfo = selectClubInfo(state);
    const region = selectRegion(state);

    const clubAttachment = clubInfo.attachment.length && clubInfo.attachment.find(a => a.languageId === langObj.id);
    const fileUrl = clubAttachment && clubAttachment.file ? 'https://memberjourneyhub.com/files/' + clubAttachment.file : null;

    const privacyURL = (() => {
      const location = region.id === 8 ? 'pt' : 'es';
      return `https://www.holmesplace.com/${location}/${langObj.code}/privacy-center`;
    })();

    return (
      <Row>
        <Col md={12}>
          <h3 style={this.props.headingStyle} className="contract-info__heading">
            {lang('Privacy Opt-In')}
          </h3>
          <Row style={{marginTop: 8, marginBottom: 8}}>
            <Col xs={6} style={{textAlign: 'right'}}>
              <Checkbox checked={this.isChecked(true)} onClick={this.toggle(true)}>
                {lang('YES')}
              </Checkbox>
            </Col>
            <Col xs={6} style={{textAlign: 'left'}}>
              <Checkbox checked={this.isChecked(false)} onClick={this.toggle(false)}>
                {lang('NO')}
              </Checkbox>
            </Col>
          </Row>
          <div>
            {[4, 5].includes(region.id) &&
              lang('Please provide me information, offers and benefits via communication channels indicated above. I can revoke my agreement any time with effect for the future.')}
            {[6, 8, 9].includes(region.id) && lang('You can change your preferences at any time.')}
          </div>
          <div
            className="gdprOptin"
            dangerouslySetInnerHTML={{
              __html: lang(
                'By submitting, you agree to Holmes Place Group using your personal data in accordance with our <a target="_blank" href="%{privacyURL}">Privacy Policy</a>. We use your data to personalize and improve your experience on our digital platforms, provide products and services you request from us, and carry out profiling and market research.',
                {privacyURL: fileUrl || privacyURL}
              ),
            }}
          />
          {region.externalId === 'ES' && (
            <div style={{marginTop: '20px'}}>
              {lang(
                'To complete the registration process, at the reception of your Holmes Place Club you will be informed about the operation of our facilities, the contractual conditions and the different treatment purposes in terms of data protection. Also, you will be asked to complete a health questionnaire.'
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  toggle(state) {
    return () => {
      this.props.onChange(state);
    };
  }

  isChecked(state) {
    if (this.props.value === state) return true;
    return false;
  }
}
