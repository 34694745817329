import {connect} from 'react-redux';
import HelloComponent from './HelloFirstName.js';
import {selectName} from './selectors.js';
import {goToClubTour} from './actions.js';
import {connectLanguage} from '../../utils/connectLanguage';
import {selectClub, getSalespersonExternalId, getPerson} from '../../App/selectors';

const mapStateToProps = state => {
  return {
    firstName: selectName(state),
    club: selectClub(state),
    salesPersonId: getSalespersonExternalId(state),
    person: getPerson(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToClubTour: () => dispatch(goToClubTour()),
  };
};

const HelloFirstName = connect(mapStateToProps, mapDispatchToProps)(HelloComponent);
export default connectLanguage(HelloFirstName, connect);
