import {createSelector} from 'reselect';
import {CLUB_TOUR_NEWS_DETAIL_PAGE} from '../../../App/constants.js';
import {getClub} from '../../../App/selectors';

const getNews = state => getClub(state).get('news');
const getNewsDetailId = (state, props) => props.params.newsId;

/**
 * create a {src,title} object from image src
 * @param src string image src (uri or base64)
 * @param title image title string
 */
const imageToObject = (src, title) => ({src: src, title: title});

/**
 * fill news identifier in a route mask
 * club-tour/blog/:newsId -> club-tour/blog/123456
 * @param news news item object loaded from the API
 * @return news
 */

const addNewsUrl = news => Object.assign({}, news, {url: CLUB_TOUR_NEWS_DETAIL_PAGE.replace(':newsId', news.structureId)});

/**
 * Transform contents of images keys on a news item to objects with format { src, title } from src strings (can be image url or base64)
 *
 * @param newsItem object loaded from the API
 * @return newsItem
 * */
const transformImagesToObjects = newsItem =>
  Object.assign({}, newsItem, {
    coverImage: newsItem.coverImage ? imageToObject(newsItem.coverImage, newsItem.title) : null,
    cover2Image: newsItem.cover2Image ? imageToObject(newsItem.cover2Image, newsItem.title) : null,
    titleImage: newsItem.titleImage ? imageToObject(newsItem.titleImage, newsItem.title) : null,
  });

export const selectNewsDetail = createSelector([getNews, getNewsDetailId], (allNews, newsId) => {
  const newsItem = allNews.find(item => Number(item.get('structureId')) === Number(newsId));

  return newsItem ? transformImagesToObjects(addNewsUrl(newsItem.toJS())) : null;
});

export const selectNews = createSelector([getNews], news => (news ? news.toJS().map(newsItem => transformImagesToObjects(addNewsUrl(newsItem))) : null));
