export default {
  AF: {
    name: 'Afghanistan',
    phone_code: '+93',
  },
  AX: {
    name: 'Aland Islands',
    phone_code: '+358',
  },
  AL: {
    name: 'Albania',
    phone_code: '+355',
  },
  DZ: {
    name: 'Algeria',
    phone_code: '+213',
  },
  AS: {
    name: 'AmericanSamoa',
    phone_code: '+1 684',
  },
  AD: {
    name: 'Andorra',
    phone_code: '+376',
  },
  AO: {
    name: 'Angola',
    phone_code: '+244',
  },
  AI: {
    name: 'Anguilla',
    phone_code: '+1 264',
  },
  AQ: {
    name: 'Antarctica',
    phone_code: '+672',
  },
  AG: {
    name: 'Antigua and Barbuda',
    phone_code: '+1268',
  },
  AR: {
    name: 'Argentina',
    phone_code: '+54',
  },
  AM: {
    name: 'Armenia',
    phone_code: '+374',
  },
  AW: {
    name: 'Aruba',
    phone_code: '+297',
  },
  AU: {
    name: 'Australia',
    phone_code: '+61',
  },
  AT: {
    name: 'Austria',
    phone_code: '+43',
  },
  AZ: {
    name: 'Azerbaijan',
    phone_code: '+994',
  },
  BS: {
    name: 'Bahamas',
    phone_code: '+1 242',
  },
  BH: {
    name: 'Bahrain',
    phone_code: '+973',
  },
  BD: {
    name: 'Bangladesh',
    phone_code: '+880',
  },
  BB: {
    name: 'Barbados',
    phone_code: '+1 246',
  },
  BY: {
    name: 'Belarus',
    phone_code: '+375',
  },
  BE: {
    name: 'Belgium',
    phone_code: '+32',
  },
  BZ: {
    name: 'Belize',
    phone_code: '+501',
  },
  BJ: {
    name: 'Benin',
    phone_code: '+229',
  },
  BM: {
    name: 'Bermuda',
    phone_code: '+1 441',
  },
  BT: {
    name: 'Bhutan',
    phone_code: '+975',
  },
  BO: {
    name: 'Bolivia, Plurinational State of',
    phone_code: '+591',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    phone_code: '+387',
  },
  BW: {
    name: 'Botswana',
    phone_code: '+267',
  },
  BR: {
    name: 'Brazil',
    phone_code: '+55',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    phone_code: '+246',
  },
  BN: {
    name: 'Brunei Darussalam',
    phone_code: '+673',
  },
  BG: {
    name: 'Bulgaria',
    phone_code: '+359',
  },
  BF: {
    name: 'Burkina Faso',
    phone_code: '+226',
  },
  BI: {
    name: 'Burundi',
    phone_code: '+257',
  },
  KH: {
    name: 'Cambodia',
    phone_code: '+855',
  },
  CM: {
    name: 'Cameroon',
    phone_code: '+237',
  },
  CA: {
    name: 'Canada',
    phone_code: '+1',
  },
  CV: {
    name: 'Cape Verde',
    phone_code: '+238',
  },
  KY: {
    name: 'Cayman Islands',
    phone_code: '+ 345',
  },
  CF: {
    name: 'Central African Republic',
    phone_code: '+236',
  },
  TD: {
    name: 'Chad',
    phone_code: '+235',
  },
  CL: {
    name: 'Chile',
    phone_code: '+56',
  },
  CN: {
    name: 'China',
    phone_code: '+86',
  },
  CX: {
    name: 'Christmas Island',
    phone_code: '+61',
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    phone_code: '+61',
  },
  CO: {
    name: 'Colombia',
    phone_code: '+57',
  },
  KM: {
    name: 'Comoros',
    phone_code: '+269',
  },
  CG: {
    name: 'Congo',
    phone_code: '+242',
  },
  CD: {
    name: 'Congo, The Democratic Republic of the Congo',
    phone_code: '+243',
  },
  CK: {
    name: 'Cook Islands',
    phone_code: '+682',
  },
  CR: {
    name: 'Costa Rica',
    phone_code: '+506',
  },
  CI: {
    name: "Cote d'Ivoire",
    phone_code: '+225',
  },
  HR: {
    name: 'Croatia',
    phone_code: '+385',
  },
  CU: {
    name: 'Cuba',
    phone_code: '+53',
  },
  CY: {
    name: 'Cyprus',
    phone_code: '+357',
  },
  CZ: {
    name: 'Czech Republic',
    phone_code: '+420',
  },
  DK: {
    name: 'Denmark',
    phone_code: '+45',
  },
  DJ: {
    name: 'Djibouti',
    phone_code: '+253',
  },
  DM: {
    name: 'Dominica',
    phone_code: '+1 767',
  },
  DO: {
    name: 'Dominican Republic',
    phone_code: '+1 849',
  },
  EC: {
    name: 'Ecuador',
    phone_code: '+593',
  },
  EG: {
    name: 'Egypt',
    phone_code: '+20',
  },
  SV: {
    name: 'El Salvador',
    phone_code: '+503',
  },
  GQ: {
    name: 'Equatorial Guinea',
    phone_code: '+240',
  },
  ER: {
    name: 'Eritrea',
    phone_code: '+291',
  },
  EE: {
    name: 'Estonia',
    phone_code: '+372',
  },
  ET: {
    name: 'Ethiopia',
    phone_code: '+251',
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    phone_code: '+500',
  },
  FO: {
    name: 'Faroe Islands',
    phone_code: '+298',
  },
  FJ: {
    name: 'Fiji',
    phone_code: '+679',
  },
  FI: {
    name: 'Finland',
    phone_code: '+358',
  },
  FR: {
    name: 'France',
    phone_code: '+33',
  },
  GF: {
    name: 'French Guiana',
    phone_code: '+594',
  },
  PF: {
    name: 'French Polynesia',
    phone_code: '+689',
  },
  GA: {
    name: 'Gabon',
    phone_code: '+241',
  },
  GM: {
    name: 'Gambia',
    phone_code: '+220',
  },
  GE: {
    name: 'Georgia',
    phone_code: '+995',
  },
  DE: {
    name: 'Germany',
    phone_code: '+49',
  },
  GH: {
    name: 'Ghana',
    phone_code: '+233',
  },
  GI: {
    name: 'Gibraltar',
    phone_code: '+350',
  },
  GR: {
    name: 'Greece',
    phone_code: '+30',
  },
  GL: {
    name: 'Greenland',
    phone_code: '+299',
  },
  GD: {
    name: 'Grenada',
    phone_code: '+1 473',
  },
  GP: {
    name: 'Guadeloupe',
    phone_code: '+590',
  },
  GU: {
    name: 'Guam',
    phone_code: '+1 671',
  },
  GT: {
    name: 'Guatemala',
    phone_code: '+502',
  },
  GG: {
    name: 'Guernsey',
    phone_code: '+44',
  },
  GN: {
    name: 'Guinea',
    phone_code: '+224',
  },
  GW: {
    name: 'Guinea-Bissau',
    phone_code: '+245',
  },
  GY: {
    name: 'Guyana',
    phone_code: '+595',
  },
  HT: {
    name: 'Haiti',
    phone_code: '+509',
  },
  VA: {
    name: 'Holy See (Vatican City State)',
    phone_code: '+379',
  },
  HN: {
    name: 'Honduras',
    phone_code: '+504',
  },
  HK: {
    name: 'Hong Kong',
    phone_code: '+852',
  },
  HU: {
    name: 'Hungary',
    phone_code: '+36',
  },
  IS: {
    name: 'Iceland',
    phone_code: '+354',
  },
  IN: {
    name: 'India',
    phone_code: '+91',
  },
  ID: {
    name: 'Indonesia',
    phone_code: '+62',
  },
  IR: {
    name: 'Iran, Islamic Republic of Persian Gulf',
    phone_code: '+98',
  },
  IQ: {
    name: 'Iraq',
    phone_code: '+964',
  },
  IE: {
    name: 'Ireland',
    phone_code: '+353',
  },
  IM: {
    name: 'Isle of Man',
    phone_code: '+44',
  },
  IL: {
    name: 'Israel',
    phone_code: '+972',
  },
  IT: {
    name: 'Italy',
    phone_code: '+39',
  },
  JM: {
    name: 'Jamaica',
    phone_code: '+1 876',
  },
  JP: {
    name: 'Japan',
    phone_code: '+81',
  },
  JE: {
    name: 'Jersey',
    phone_code: '+44',
  },
  JO: {
    name: 'Jordan',
    phone_code: '+962',
  },
  KZ: {
    name: 'Kazakhstan',
    phone_code: '+7 7',
  },
  KE: {
    name: 'Kenya',
    phone_code: '+254',
  },
  KI: {
    name: 'Kiribati',
    phone_code: '+686',
  },
  KP: {
    name: "Korea, Democratic People's Republic of Korea",
    phone_code: '+850',
  },
  KR: {
    name: 'Korea, Republic of South Korea',
    phone_code: '+82',
  },
  KW: {
    name: 'Kuwait',
    phone_code: '+965',
  },
  KG: {
    name: 'Kyrgyzstan',
    phone_code: '+996',
  },
  LA: {
    name: 'Laos',
    phone_code: '+856',
  },
  LV: {
    name: 'Latvia',
    phone_code: '+371',
  },
  LB: {
    name: 'Lebanon',
    phone_code: '+961',
  },
  LS: {
    name: 'Lesotho',
    phone_code: '+266',
  },
  LR: {
    name: 'Liberia',
    phone_code: '+231',
  },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    phone_code: '+218',
  },
  LI: {
    name: 'Liechtenstein',
    phone_code: '+423',
  },
  LT: {
    name: 'Lithuania',
    phone_code: '+370',
  },
  LU: {
    name: 'Luxembourg',
    phone_code: '+352',
  },
  MO: {
    name: 'Macao',
    phone_code: '+853',
  },
  MK: {
    name: 'Macedonia',
    phone_code: '+389',
  },
  MG: {
    name: 'Madagascar',
    phone_code: '+261',
  },
  MW: {
    name: 'Malawi',
    phone_code: '+265',
  },
  MY: {
    name: 'Malaysia',
    phone_code: '+60',
  },
  MV: {
    name: 'Maldives',
    phone_code: '+960',
  },
  ML: {
    name: 'Mali',
    phone_code: '+223',
  },
  MT: {
    name: 'Malta',
    phone_code: '+356',
  },
  MH: {
    name: 'Marshall Islands',
    phone_code: '+692',
  },
  MQ: {
    name: 'Martinique',
    phone_code: '+596',
  },
  MR: {
    name: 'Mauritania',
    phone_code: '+222',
  },
  MU: {
    name: 'Mauritius',
    phone_code: '+230',
  },
  YT: {
    name: 'Mayotte',
    phone_code: '+262',
  },
  MX: {
    name: 'Mexico',
    phone_code: '+52',
  },
  FM: {
    name: 'Micronesia, Federated States of Micronesia',
    phone_code: '+691',
  },
  MD: {
    name: 'Moldova',
    phone_code: '+373',
  },
  MC: {
    name: 'Monaco',
    phone_code: '+377',
  },
  MN: {
    name: 'Mongolia',
    phone_code: '+976',
  },
  ME: {
    name: 'Montenegro',
    phone_code: '+382',
  },
  MS: {
    name: 'Montserrat',
    phone_code: '+1664',
  },
  MA: {
    name: 'Morocco',
    phone_code: '+212',
  },
  MZ: {
    name: 'Mozambique',
    phone_code: '+258',
  },
  MM: {
    name: 'Myanmar',
    phone_code: '+95',
  },
  NA: {
    name: 'Namibia',
    phone_code: '+264',
  },
  NR: {
    name: 'Nauru',
    phone_code: '+674',
  },
  NP: {
    name: 'Nepal',
    phone_code: '+977',
  },
  NL: {
    name: 'Netherlands',
    phone_code: '+31',
  },
  AN: {
    name: 'Netherlands Antilles',
    phone_code: '+599',
  },
  NC: {
    name: 'New Caledonia',
    phone_code: '+687',
  },
  NZ: {
    name: 'New Zealand',
    phone_code: '+64',
  },
  NI: {
    name: 'Nicaragua',
    phone_code: '+505',
  },
  NE: {
    name: 'Niger',
    phone_code: '+227',
  },
  NG: {
    name: 'Nigeria',
    phone_code: '+234',
  },
  NU: {
    name: 'Niue',
    phone_code: '+683',
  },
  NF: {
    name: 'Norfolk Island',
    phone_code: '+672',
  },
  MP: {
    name: 'Northern Mariana Islands',
    phone_code: '+1 670',
  },
  NO: {
    name: 'Norway',
    phone_code: '+47',
  },
  OM: {
    name: 'Oman',
    phone_code: '+968',
  },
  PK: {
    name: 'Pakistan',
    phone_code: '+92',
  },
  PW: {
    name: 'Palau',
    phone_code: '+680',
  },
  PS: {
    name: 'Palestinian Territory, Occupied',
    phone_code: '+970',
  },
  PA: {
    name: 'Panama',
    phone_code: '+507',
  },
  PG: {
    name: 'Papua New Guinea',
    phone_code: '+675',
  },
  PY: {
    name: 'Paraguay',
    phone_code: '+595',
  },
  PE: {
    name: 'Peru',
    phone_code: '+51',
  },
  PH: {
    name: 'Philippines',
    phone_code: '+63',
  },
  PN: {
    name: 'Pitcairn',
    phone_code: '+872',
  },
  PL: {
    name: 'Poland',
    phone_code: '+48',
  },
  PT: {
    name: 'Portugal',
    phone_code: '+351',
  },
  PR: {
    name: 'Puerto Rico',
    phone_code: '+1 939',
  },
  QA: {
    name: 'Qatar',
    phone_code: '+974',
  },
  RO: {
    name: 'Romania',
    phone_code: '+40',
  },
  RU: {
    name: 'Russia',
    phone_code: '+7',
  },
  RW: {
    name: 'Rwanda',
    phone_code: '+250',
  },
  RE: {
    name: 'Reunion',
    phone_code: '+262',
  },
  BL: {
    name: 'Saint Barthelemy',
    phone_code: '+590',
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    phone_code: '+290',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    phone_code: '+1 869',
  },
  LC: {
    name: 'Saint Lucia',
    phone_code: '+1 758',
  },
  MF: {
    name: 'Saint Martin',
    phone_code: '+590',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    phone_code: '+508',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    phone_code: '+1 784',
  },
  WS: {
    name: 'Samoa',
    phone_code: '+685',
  },
  SM: {
    name: 'San Marino',
    phone_code: '+378',
  },
  ST: {
    name: 'Sao Tome and Principe',
    phone_code: '+239',
  },
  SA: {
    name: 'Saudi Arabia',
    phone_code: '+966',
  },
  SN: {
    name: 'Senegal',
    phone_code: '+221',
  },
  RS: {
    name: 'Serbia',
    phone_code: '+381',
  },
  SC: {
    name: 'Seychelles',
    phone_code: '+248',
  },
  SL: {
    name: 'Sierra Leone',
    phone_code: '+232',
  },
  SG: {
    name: 'Singapore',
    phone_code: '+65',
  },
  SK: {
    name: 'Slovakia',
    phone_code: '+421',
  },
  SI: {
    name: 'Slovenia',
    phone_code: '+386',
  },
  SB: {
    name: 'Solomon Islands',
    phone_code: '+677',
  },
  SO: {
    name: 'Somalia',
    phone_code: '+252',
  },
  ZA: {
    name: 'South Africa',
    phone_code: '+27',
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    phone_code: '+500',
  },
  ES: {
    name: 'Spain',
    phone_code: '+34',
  },
  LK: {
    name: 'Sri Lanka',
    phone_code: '+94',
  },
  SD: {
    name: 'Sudan',
    phone_code: '+249',
  },
  SR: {
    name: 'Suriname',
    phone_code: '+597',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    phone_code: '+47',
  },
  SZ: {
    name: 'Swaziland',
    phone_code: '+268',
  },
  SE: {
    name: 'Sweden',
    phone_code: '+46',
  },
  CH: {
    name: 'Switzerland',
    phone_code: '+41',
  },
  SY: {
    name: 'Syrian Arab Republic',
    phone_code: '+963',
  },
  TW: {
    name: 'Taiwan',
    phone_code: '+886',
  },
  TJ: {
    name: 'Tajikistan',
    phone_code: '+992',
  },
  TZ: {
    name: 'Tanzania, United Republic of Tanzania',
    phone_code: '+255',
  },
  TH: {
    name: 'Thailand',
    phone_code: '+66',
  },
  TL: {
    name: 'Timor-Leste',
    phone_code: '+670',
  },
  TG: {
    name: 'Togo',
    phone_code: '+228',
  },
  TK: {
    name: 'Tokelau',
    phone_code: '+690',
  },
  TO: {
    name: 'Tonga',
    phone_code: '+676',
  },
  TT: {
    name: 'Trinidad and Tobago',
    phone_code: '+1 868',
  },
  TN: {
    name: 'Tunisia',
    phone_code: '+216',
  },
  TR: {
    name: 'Turkey',
    phone_code: '+90',
  },
  TM: {
    name: 'Turkmenistan',
    phone_code: '+993',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    phone_code: '+1 649',
  },
  TV: {
    name: 'Tuvalu',
    phone_code: '+688',
  },
  UG: {
    name: 'Uganda',
    phone_code: '+256',
  },
  UA: {
    name: 'Ukraine',
    phone_code: '+380',
  },
  AE: {
    name: 'United Arab Emirates',
    phone_code: '+971',
  },
  GB: {
    name: 'United Kingdom',
    phone_code: '+44',
  },
  US: {
    name: 'United States',
    phone_code: '+1',
  },
  UY: {
    name: 'Uruguay',
    phone_code: '+598',
  },
  UZ: {
    name: 'Uzbekistan',
    phone_code: '+998',
  },
  VU: {
    name: 'Vanuatu',
    phone_code: '+678',
  },
  VE: {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    phone_code: '+58',
  },
  VN: {
    name: 'Vietnam',
    phone_code: '+84',
  },
  VG: {
    name: 'Virgin Islands, British',
    phone_code: '+1 284',
  },
  VI: {
    name: 'Virgin Islands, U.S.',
    phone_code: '+1 340',
  },
  WF: {
    name: 'Wallis and Futuna',
    phone_code: '+681',
  },
  YE: {
    name: 'Yemen',
    phone_code: '+967',
  },
  ZM: {
    name: 'Zambia',
    phone_code: '+260',
  },
  ZW: {
    name: 'Zimbabwe',
    phone_code: '+263',
  },
};
