import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {TELLUSMORE_PAGE, CLUB_TOUR_NEWS_PAGE, CLUB_TOUR_WELCOME_PAGE, CLUB_TOUR_CLASSES_SCHEDULE_PAGE, WELCOME_PAGE} from '../../App/constants';

import './style.scss';

class LeftSidebar extends Component {
  checkOpen(show) {
    if (show) return 'leftSidebar leftSidebar--active';
    return 'leftSidebar';
  }

  render() {
    return (
      <div className={this.checkOpen(this.props.show)}>
        {this.props.app !== 'LT' ? (
          <ul>
            <li className="myInfo" onClick={() => browserHistory.push(TELLUSMORE_PAGE)}>
              {this.props.lang('my info')}
            </li>
            <li className="clubTour" onClick={() => browserHistory.push(CLUB_TOUR_WELCOME_PAGE)}>
              {this.props.lang('club tour')}
            </li>
            <li className="classes" onClick={() => browserHistory.push(CLUB_TOUR_CLASSES_SCHEDULE_PAGE)}>
              {this.props.lang('classes')}
            </li>
            <li className="news" onClick={() => browserHistory.push(CLUB_TOUR_NEWS_PAGE)}>
              {this.props.lang('blog & events')}
            </li>
          </ul>
        ) : (
          <ul className="lt__menu">
            <li onClick={() => browserHistory.push(WELCOME_PAGE)} className={'ltHome'}>
              {this.props.lang('Home')}
            </li>
            <li onClick={() => this.props.startNewLead()} className={'newLead'}>
              {this.props.lang('start new lead')}
            </li>
            <div className="button--small button--small--gold" onClick={() => browserHistory.push(WELCOME_PAGE)}>
              {this.props.lang('dsp')}
            </div>
          </ul>
        )}
      </div>
    );
  }
}

export default LeftSidebar;
