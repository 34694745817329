import React, {Component} from 'react';
import Page from '../../containers/Page/index';
import './style.scss';

import lang from '../../utils/translator';

class EnjoyTheJourney extends Component {
  render() {
    return (
      <Page nav navBarType={'nav--gold'}>
        <div className="picturediv">
          <div className="contentenjoy">
            <button className=" customStyle contentenjoy__botton" onClick={() => this.props.goToWelcome()}>
              {lang('ENJOY THE JOURNEY')}
            </button>
          </div>
        </div>
      </Page>
    );
  }
}

export default EnjoyTheJourney;
