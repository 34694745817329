import {connect} from 'react-redux';
import {connectLanguage} from '../../utils/connectLanguage';

import * as selectors from '../../App/selectors';
import {personChangeField, showError} from '../../Actions';
import {selectActivePromo} from './selectors';
import {saveSignature, confirmContract, tryToSavePhoto} from './actions.js';
import {saveCheckoutData} from '../../api/actions';
import ContractComponent from './Contract';

/**
 * Connect to Redux state
 */
const mapStateToProps = state => {
  return {
    person: selectors.selectPerson(state),
    region: selectors.selectRegion(state),
    clubInfo: selectors.selectClub(state),
    selectedPackage: selectors.selectSelectedStarterpack(state),
    selectedMembership: selectors.selectSelectedMembership(state),
    contract: selectors.selectContract(state),
    contractInfo: selectors.selectContractInfo(state),
    loading: selectors.selectLoader(state),
    currency: selectors.selectCurrency(state),
    promo: selectActivePromo(state),
    photo: selectors.selectPhoto(state),
    signature: selectors.selectSignature(state),
    signatureSalesperson: selectors.selectSignatureSalesperson(state),
    checkoutData: selectors.selectCheckoutData(state),
    payWithCard: selectors.selectPaymentMethod(state),
  };
};

/**
 * Connect ot Redux dispatch
 */
const mapDispatchToProps = dispatch => {
  return {
    change: id => value => dispatch(personChangeField(id, value)),
    saveSignature: name => salesPerson => canvas => dispatch(saveSignature(name, salesPerson, canvas.toDataURL())),
    confirmContract: () => dispatch(confirmContract()),
    tryToSavePhoto: e => dispatch(tryToSavePhoto(e)),
    setCheckoutData: data => dispatch(saveCheckoutData(data)),
    showError: error => dispatch(showError(error)),
  };
};

const Contract = connect(mapStateToProps, mapDispatchToProps)(ContractComponent);
export default connectLanguage(Contract, connect);
