import {connect} from 'react-redux';
import {connectLanguage} from '../../utils/connectLanguage';

import {chooseMembership} from './actions';
import {selectCurrency, selectMemberships, selectPerson, selectStarterpacks, selectClub} from '../../App/selectors';
import {selectSelectedMembershipId} from './selectors';
import MembershipsComponent from './Memberships';

function mapStateToProps(state) {
  return {
    memberships: selectMemberships(state),
    selectedMemberships: selectSelectedMembershipId(state),
    person: selectPerson(state),
    starterpacks: selectStarterpacks(state),
    clubInfo: selectClub(state),
    currency: selectCurrency(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    chooseMembership: membershipId => () => dispatch(chooseMembership(membershipId)),
  };
}

const Memberships = connect(mapStateToProps, mapDispatchToProps)(MembershipsComponent);
export default connectLanguage(Memberships, connect);
