import sha256 from 'js-sha256';
import {browserHistory} from 'react-router';
import {API_CHECK_NIF, API_FETCH_MISSING_DATA, PERSON_CHANGE_FIELD} from '../Actions/constants';
import {changePromo, createPerson, loader, personChangeField, setMembership, showError, showSuccess, tellusmoreShowModal} from '../Actions/index';
import {CONTACT_INFORMATION_PAGE, CONTRACT_PAGE, THANKYOU_PAGE, TWO_WEEKS_PLAN_PAGE, WELCOME_PAGE} from '../App/constants.js';
import * as errors from '../App/errorConstatns';
import {
  clubRegionExternalId,
  getSalespersonExternalId,
  selectClub,
  selectClubExternalId,
  selectClubInfo,
  selectContract,
  selectContractInfo,
  selectCurrency,
  selectDuplicatePerson,
  selectLangObj,
  selectPaymentMethod,
  selectPerson,
  selectPromo,
  selectRegion,
  selectSalespersonExternalId,
  selectSelectedMembership,
  selectSelectedStarterpack,
  selectSignature,
  selectVerifyInfo,
} from '../App/selectors';
import {API_GET_CALENDAR} from '../containers/CalendarView/constants';
import {API_GET_CLUBS, API_LOGIN} from '../containers/Login/constants.js';
import {closeConfirmModal, openModal, toggleRightMenu, verifyPersonForm, verifyPin} from '../containers/Page/actions';
import {PRICE_COUNT} from '../containers/Summary/constants';
import {API_PERSON_DUPLICATE, TELLUSMORE_LOADER} from '../containers/TellUsMore/constants.js';
import {getDataForProspect} from '../utils/cloudDataSource';
import translator from '../utils/translator';
import {validateEmail} from '../utils/validators';
import {
  callCheckoutRequest,
  clubInfoSuccess,
  clubUpdateInfo,
  confirmLoader,
  getClubsSuccess,
  loginRemoveData,
  loginSuccess,
  personDuplicateSuccess,
  saveCheckoutData,
} from './actions';
import * as calendarCloud from './calendarCloud';
import * as Cloud from './Cloud';
import {
  API_CONFIRM_AS_MEMBER,
  API_CONFIRM_AS_PROSPECT,
  API_NOTIFY,
  API_PRICE_COUNT,
  API_REQUEST_CHECKOUT_DATA,
  API_SALESPERSONS_NAME,
  API_TWOWEEKSPLAN_INSERT,
  API_VERIFY_PERSON,
  API_VERIFY_PERSON_SUCCESS,
  CALENDAR_DATA_SUCCESS,
  CHANGE_CURRENCY,
  SALESPERSON_NAME_SUCCESS,
  VALIDATE_NIF,
} from './constants.js';

export const apiMiddleware = store => next => action => {
  switch (action.type) {
    case API_LOGIN:
      login(store, action);
      break;
    case API_FETCH_MISSING_DATA:
      fetchMissingData(store, action);
      break;
    case API_GET_CLUBS:
      apiGetClubs(store, action);
      break;
    case API_PERSON_DUPLICATE:
      apiPersonDuplicate(store, action);
      break;
    case API_VERIFY_PERSON:
      apiVerifyPerson(store, action);
      break;
    case API_PRICE_COUNT:
      apiPriceCount(store, action);
      break;
    case API_CONFIRM_AS_MEMBER:
      apiConfirmAsMember(store, action);
      break;
    case API_CONFIRM_AS_PROSPECT:
      apiConfirmAsProspect(store, action);
      break;
    case API_GET_CALENDAR:
      apiGetCalendar(store, action);
      break;
    case API_CHECK_NIF:
      apiCheckNif(store, action);
      break;
    case API_TWOWEEKSPLAN_INSERT:
      apiTwoWeeksPLanInsert(store, action);
      break;
    case API_SALESPERSONS_NAME:
      getSalespersonsName(store, action);
      break;
    case API_REQUEST_CHECKOUT_DATA:
      apiCheckoutRequest(store, action);
      break;
    case API_NOTIFY:
      notifyNewMember(store, action);
      break;
    default:
      next(action);
  }
};

let checkNif;

const apiCheckNif = (store, action) => {
  const clubExternalId = selectClubExternalId(store.getState());

  clearTimeout(checkNif);
  checkNif = setTimeout(() => {
    Cloud.checkNif(clubExternalId, action.value)
      .then(() => {
        store.dispatch({
          type: VALIDATE_NIF,
          valid: true,
        });
        store.dispatch({
          type: PERSON_CHANGE_FIELD,
          id: action.id,
          value: action.value,
        });
      })
      .catch(error => {
        store.dispatch({
          type: PERSON_CHANGE_FIELD,
          id: action.id,
          value: action.value,
        });
        store.dispatch({type: VALIDATE_NIF, valid: false});
        if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

        if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

        store.dispatch(showError(errors.SERVER_ERROR));
      });
  }, 1000);
};

function apiPriceCount(store, action) {
  store.dispatch(loader(true));
  store.dispatch(changePromo(action.promo));
  let startDate = new Date(action.startDate);
  startDate.setMinutes(-startDate.getTimezoneOffset());

  Cloud.countPrice(action.clubExternalId, action.membershipExternalId, action.starterPackExternalId, action.promo, startDate)
    .then(res => res.data)
    .then(data => {
      const price = data;
      price.type = PRICE_COUNT;
      price.isPromoValid = true;
      if (Object.keys(data).length > 0) store.dispatch(price);
      store.dispatch(loader(false));
    })
    .catch(error => {
      const prevPrice = selectContractInfo(store.getState());
      prevPrice.type = PRICE_COUNT;
      prevPrice.isPromoValid = false;
      store.dispatch(prevPrice);
      store.dispatch(loader(false));

      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function apiConfirmAsProspect(store, action) {
  store.dispatch(confirmLoader(true));

  const person = selectPerson(store.getState());
  const club = selectClubInfo(store.getState());

  Cloud.confirmProspect(getDataForProspect(club, person))
    .then(res => {
      browserHistory.push(THANKYOU_PAGE);
      store.dispatch(closeConfirmModal());
      store.dispatch(toggleRightMenu(false));
      store.dispatch(confirmLoader(false));
    })
    .catch(error => {
      store.dispatch(confirmLoader(false));
      store.dispatch(closeConfirmModal());

      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function getInObj(obj, path, empty = undefined) {
  if (obj) {
    if (path[0] !== undefined) return getInObj(obj[path.splice(0, 1)], path, empty);
    else return obj;
  } else return empty;
}

function createContractData(state) {
  const person = selectPerson(state);
  const contract = selectContract(state);

  const currentClub = selectClubInfo(state);
  currentClub.region = selectRegion(state);
  const currentMembership = selectSelectedMembership(state);
  const currentStarterpack = selectSelectedStarterpack(state);
  const salespersonExternalId = selectSalespersonExternalId(state);
  const priceInfo = selectContractInfo(state);
  const promo = priceInfo.isPromoValid && selectPromo(state);

  return {
    regionId: currentClub.regionId,
    websiteId: getInObj(currentClub.region, ['cmp_websites', 0, 'id'], 1),
    languageId: selectLangObj(state).id,
    salespersonExternalId,
    order: {
      currentClub,
      currentMembership,
      currentStarterpack,
      source: 'dsp',
      contract: {
        price: selectContractInfo(state),
        promoCode: promo,
        startDate: new Date(person.startDate),
        specialOffer: null, // TODO: Add special offer
        photo: contract.photo,
        signature: contract.signature,
        signatureSalesperson: contract.signatureSalesperson,
        payWithCard: selectPaymentMethod(state) || false,
        marketo: {
          lastDigitalSource: 'DSP',
          preferredLanguage: selectLangObj(state).code,
        },
      },
      person: {
        id: person.id || null,
        prospectId: person.prospects && Array.isArray(person.prospects) && person.prospects.length ? person.prospects[0].prospectId : null,
        name: person.name,
        surname: person.surname,
        nationalId: person.nif,
        passport: person.passport,
        mobilePrefix: person.phoneCode,
        mobile: person.mobile,
        email: person.email,
        dateOfBirth: person.birthDate,
        gender: person.gender,
        street: person.street,
        district: person.district,
        city: person.city,
        postalCode: person.postalCode,
        country: person.country,
        isCitizen: person.isCitizen || true,
        gdprOptin: person.gdpr || false,
        notes: person.notes,
        accountOwner: person.accountOwner,
        iban: person.iban || '',
        bic: person.bic || '',
      },
      salesProcessQueryParamsObject: {
        club: currentClub.externalId,
        step: '1',
        promocode: promo,
        membership: currentMembership.externalId,
        starterpack: currentStarterpack.externalId,
        utm_campaign: `${currentClub.regionId === 8 ? 'pt' : 'es'}-${new Date().getFullYear()}-dsp`,
        utm_source: currentClub.externalId,
        utm_medium: 'dsp',
        utm_content: salespersonExternalId,
      },
    },
  };
}

/**
 * Push data to gtm about successful order to gtm
 * @param {*} order
 * @param {*} reference
 */
function pushPaymentToGTM({contract, currentClub, currentMembership, currentStarterpack, merchantTransactionId, person}, reference, salesPersonId, payedWithCard) {
  const membershipPirce = Number(currentMembership.price);
  const starterpackPrice = Number(currentStarterpack.price);
  const gtmObject = {
    event: 'transaction',
    ecommerce: {
      guestId: sha256(`${person.email}${person.name}`),
      salesId: salesPersonId,
      paymentType: payedWithCard ? 'payWithCard' : 'payOnReceptionDesk',
      utms: {
        utm_campaign: `${currentClub.regionId === 8 ? 'pt' : 'es'}-${new Date().getFullYear()}-dsp`,
        utm_source: currentClub.externalId,
        utm_medium: 'dsp',
        utm_content: salesPersonId,
      },
      purchase: {
        actionField: {
          id: reference,
          merchantId: merchantTransactionId,
          affiliation: 'DSP',
          revenue: contract.price && contract.price.totalAmount,
          startDate: contract.startDate,
          coupon: contract.promoCode,
        },
        products: [
          {
            name: `mp-${currentMembership.title}`,
            id: currentMembership.externalId,
            price: membershipPirce,
            category: currentClub.name,
            quantity: 1,
          },
          {
            name: `sp-${currentStarterpack.title}`,
            id: currentStarterpack.externalId,
            price: starterpackPrice,
            category: currentClub.name,
            quantity: 1,
          },
        ],
      },
    },
  };
  if (window && window.dataLayer) {
    window.dataLayer.push(gtmObject);
  } else {
    console.log('DataLayer is unavailable!');
  }
}

function apiConfirmAsMember(store) {
  const state = store.getState();
  if (!selectSignature(state)) return store.dispatch(showError(errors.MISSING_SIGNATURE));

  store.dispatch(loader(true));

  const requestData = createContractData(state);
  // Creates onlines sales process order
  Cloud.confirmMemberDSP(requestData)
    .then(response => response.data)
    .then(result => {
      const state = store.getState();
      store.dispatch(showSuccess(translator(errors.MEMBERSHIP_CREATED)));
      store.dispatch(setMembership(result));
      const salesId = getSalespersonExternalId(state);
      pushPaymentToGTM(result.order, result.reference, salesId, selectPaymentMethod(state));

      // prevent creation of a new prospect whan payment fail
      // re-using of standard deduplication process
      store.dispatch(
        personChangeField('prospects', [
          {
            clubExternalId: result.order.prospect.clubExternalId,
            prospectId: result.order.prospect.prospectId,
            taskSalesPersonName: null,
            taskSalesPersonId: null,
            club: {
              name: result.order.currentClub.name,
            },
          },
        ])
      );

      // confirms member when payment is cash
      if (selectPerson(store.getState()).payWithCard === false) {
        return Cloud.newMemberNotify({regionId: result.regionId, reference: result.reference})
          .then(() => {
            store.dispatch(loader(false));
            browserHistory.push(TWO_WEEKS_PLAN_PAGE);
          })
          .catch(e => {
            store.dispatch(loader(false));
          });
      }
      if (selectRegion(state).id === 8) {
        store.dispatch(callCheckoutRequest(result)); //! Calling checkout request
      } else {
        const payload = {
          regionId: result.regionId,
          reference: result.reference,
          amount: result.order.contract.price.totalAmount * 100,
          currency: selectCurrency(state),
          languageCode: result.order.currentLanguage.code,
          returnURL: `${window.location.origin}/twoweeksplan`,
          cancelURL: window.location.href,
          iban: result.order.person.iban,
          bic: result.order.person.bic,
        };
        Cloud.getRedirectUrl(payload)
          .then(response => response.data)
          .then(data => {
            store.dispatch(loader(false));
            if (data.redirectURL) {
              window.location.href = data.redirectURL;
            } else if (data.type === 'form' && data.url && data.inputs) {
              const form = document.createElement('form');
              form.method = 'post';
              form.action = data.url;
              Object.keys(data.inputs).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = data.inputs[key];
                form.appendChild(input);
              });
              document.body.appendChild(form);
              form.submit();
            }
          })
          .catch(error => {
            store.dispatch(loader(false));
            store.dispatch(showError(errors.SERVER_ERROR));
          });
      }
    })
    .catch(error => {
      console.log(error);
      const message = error.response && error.response.data && error.response.data.errors;
      store.dispatch(loader(false));

      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (message) {
        browserHistory.push(CONTACT_INFORMATION_PAGE);
        return store.dispatch(showError(message));
      }

      store.dispatch(showError(message || errors.SERVER_ERROR));
    });
}

function apiCheckoutRequest(store, action) {
  const state = store.getState();
  const requestData = {
    regionId: selectRegion(state).id,
    person: selectPerson(state),
    amount: selectContractInfo(state).totalAmount * 100,
    currency: selectCurrency(state),
    reference: action.data.reference,
  };

  Cloud.prepareCheckout(requestData)
    .then(response => response.data)
    .then(result => {
      result.reference = action.data.reference;
      store.dispatch(saveCheckoutData(result));
      store.dispatch(loader(false));
    })
    .catch(error => {
      console.error(error.name, error.message);
      store.dispatch(saveCheckoutData(null));
      store.dispatch(loader(false));
      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function apiGetCalendar(store, action) {
  const clubId = selectClubInfo(store.getState()).id;
  store.dispatch(loader(true));
  calendarCloud
    .getCalendar(clubId, 5)
    .then(res => res.data)
    .then(data => {
      store.dispatch(loader(false));
      return store.dispatch({
        type: CALENDAR_DATA_SUCCESS,
        data,
      });
    })
    .catch(error => {
      store.dispatch(loader(false));

      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function apiPersonDuplicate(store, action) {
  if (!validateEmail(action.data.email)) return store.dispatch(showError(errors.INVALID_EMAIL));

  store.dispatch({type: TELLUSMORE_LOADER, value: true});
  const clubExternalId = selectClubExternalId(store.getState());
  const regionId = clubRegionExternalId(store.getState());
  Cloud.checkPerson(action.data.email.toLowerCase(), clubExternalId)
    .then(res => {
      const duplicates = res.data
        .filter(person => !person.regionExternalId || person.regionExternalId === regionId)
        .sort(({memberships: mA, prospects: pA, nif: nifA}, {memberships: mB, prospects: pB}) => {
          // True if person - prospect/member created in current club;
          const isCurrentClubA = (mA[0] || pA[0] || {}).clubExternalId === clubExternalId;
          const isCurrentClubB = (mB[0] || pB[0] || {}).clubExternalId === clubExternalId;
          return (isCurrentClubA && nifA )|| (isCurrentClubA && !nifA && !isCurrentClubB) ? -1 : 1;
        });
      return store.dispatch(personDuplicateSuccess(duplicates));
    })
    .then(() => {
      return store.dispatch({type: TELLUSMORE_LOADER, value: false});
    })
    .then(() => {
      if (selectDuplicatePerson(store.getState()).duplicates.length > 0) {
        const filteredDuplicates = selectDuplicatePerson(store.getState()).duplicates.filter(
          person => !person.regionExternalId || person.regionExternalId === clubRegionExternalId(store.getState())
        );
        filteredDuplicates.length > 0 ? store.dispatch(tellusmoreShowModal(true)) : store.dispatch(createPerson(action.data));
      } else store.dispatch(createPerson(action.data));
    })
    .catch(error => {
      store.dispatch({type: TELLUSMORE_LOADER, value: false});

      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function fetchMissingData(store) {
  const state = store.getState();
  const club = selectClub(state);
  const clubInfo = selectClubInfo(state);
  const lang = selectLangObj(state);
  const region = selectRegion(state);
  const website = region.cmp_websites[0];

  if (!club.loaded) {
    return Promise.all([
      Cloud.clubSchedule(club.club.id, lang.id),
      Cloud.clubMemberships(club.club.id, lang.id),
      Cloud.clubStarterpacks(club.club.id, lang.id),
      Cloud.clubPages(club.club.id, lang.id),
      Cloud.clubPagesByType(region.id, lang.id, website.id),
      Cloud.clubNews(region.id, lang.id, region.cmp_websites[0] ? region.cmp_websites[0].id : null),
      Cloud.clubEvents(region.id, lang.id, region.cmp_websites[0] ? region.cmp_websites[0].id : null),
    ]).then(([schedule, memberships, starterpacks, pages, clubPages, news, events]) => {
      club.classes = schedule;
      club.memberships = memberships;
      club.starterpacks = starterpacks;
      club.services = pages.services;
      club.events = events.items || pages.events;
      club.news = news.items || pages.blogPosts;
      club.loaded = true;

      for (let page of clubPages.items) {
        // Prevence chyby v případě nekonzistentních dat - don't judge me, judge cloud...
        if (
          getInObj(
            getInObj(page, ['content'], []).find(content => content.component === 'Location'),
            ['data', 'clubId']
          ) === clubInfo.id
        ) {
          club.url = page.url;
          break;
        }
      }

      const resultClubInfo = {
        club,
        lang: lang.code,
      };

      store.dispatch(clubUpdateInfo(resultClubInfo));
    });
  }
  return Promise.resolve();
}

// function initGTM(region) {
// 	const tagManagerArgs = { gtmId:  region === 8 ? 'GTM-NSKMXH6' : 'GTM-WWKWD4T' };
//   TagManager.initialize(tagManagerArgs);
// }

function login(store, action) {
  store.dispatch(loader(true));
  Cloud.login(action.username, action.password, action.clubId, action.pin)
    .then(res => store.dispatch(loginSuccess(res.data)))
    .then(() => localStorage.setItem('salespersonPin', action.pin))
    .then(() => Promise.all([Cloud.clubInfo(action.clubId)]))
    .then(([clubInfo]) => {
      if (Object.keys(clubInfo).length <= 0) {
        throw new Error('Club information is not found');
      }

      /** GTM init */
      // initGTM(clubInfo.region.id)

      const languages = {
        default: {
          id: clubInfo.region.defaultLanguageId || clubInfo.languages[0].id || 1,
          code: clubInfo.region.defaultLanguage || clubInfo.languages[0].code || 'en',
        },
        list: clubInfo.languages,
      };

      /**
       * TODO: Add missing fields:
       * benefits
       */
      const defaultClub = languages.list.reduce((result, lang) => {
        result[lang.code] = {
          club: clubInfo.club,
          region: clubInfo.region,
          classes: [],
          services: [],
          events: [],
          news: [],
          memberships: [],
          starterpacks: [],
          url: null,
          loaded: false,
        };
        return result;
      }, {});

      const resultClubInfo = {
        languages,
        defaultClub,
      };

      store.dispatch({type: CHANGE_CURRENCY, currency: 'EUR'});

      store.dispatch(clubInfoSuccess(resultClubInfo));
      fetchMissingData(store).then(() => {
        store.dispatch(verifyPersonForm('', 'password'));
        store.dispatch(verifyPersonForm('', 'pinCode'));
        store.dispatch(loader(false));
        browserHistory.push(WELCOME_PAGE);
      });
    })
    .catch(error => {
      console.error(error);
      store.dispatch(loader(false));
      store.dispatch(loginRemoveData());
      store.dispatch(verifyPersonForm('', 'password'));
      store.dispatch(verifyPersonForm('', 'pinCode'));

      if (error.name === 'Unauthorized') return store.dispatch(showError(errors.INVALID_CREDENTIALS));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
}

function apiGetClubs(store, action) {
  Cloud.getClubs()
    .then(res => {
      store.dispatch(verifyPersonForm(false, 'modal'));
      store.dispatch(getClubsSuccess(res.data));
      store.dispatch(loader(false));
    })
    .catch(error => {
      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
      store.dispatch(loader(false));
    });
}

function apiVerifyPerson(store, action) {
  const inputPin = action.data.pinCode;
  const requiredPin = localStorage.getItem('salespersonPin');

  if (inputPin === requiredPin) {
    store.dispatch({type: API_VERIFY_PERSON_SUCCESS});
    if (selectVerifyInfo(store.getState()).action === 'rightmenu') {
      store.dispatch(toggleRightMenu(true));
    } else if (selectVerifyInfo(store.getState()).action === 'calendar') {
      apiGetCalendar(store, action);
    }
    store.dispatch(verifyPin(''));
  } else {
    store.dispatch(showError(translator(errors.INVALID_PIN_PASSWORD)));
  }
}

const apiTwoWeeksPLanInsert = (store, action) => {
  Cloud.insertTwoWeeksPlan(action.plan, action.person, String(action.monday), action.langId)
    .then(res => {
      browserHistory.push(THANKYOU_PAGE);
    })
    .catch(error => {
      if (error.name === 'Unauthorized') return store.dispatch(openModal(true));

      if (error.type === 'Custom Error') return store.dispatch(showError(error.message));

      store.dispatch(showError(errors.SERVER_ERROR));
    });
};

function getSalespersonsName(store) {
  if (window.location.pathname.includes('/login')) return;
  Cloud.getSalespersonsName()
    .then(response => store.dispatch({type: SALESPERSON_NAME_SUCCESS, name: response.data.name}))
    .catch(error => console.error(new Error(error)));
}

function notifyNewMember(store, action) {
  const region = selectRegion(store.getState());
  Cloud.newMemberNotify({regionId: region.id, reference: action.reference}).catch(error => {
    browserHistory.push(CONTRACT_PAGE);
    store.dispatch(showError(errors.PAYMENT_CANNOT_BE_PROCESSED));
  });
}
