import uniqueId from 'lodash/uniqueId';
import React from 'react';
import './style.scss';

const Checkbox = React.memo(({disabled, id: propsId, onClick, checked, children, onDisabledCheckboxClick}) => {
  const id = propsId || uniqueId('checkbox');

  const onChange = e => {
    if (disabled) {
      onDisabledCheckboxClick();
    } else {
      onClick(e);
    }
  };

  return (
    <div>
      <input id={id} className="checkbox" type="checkbox" onChange={onChange} checked={checked || false} />
      <label htmlFor={id} className="checkbox-inline">
        {children}
      </label>
    </div>
  );
});

export default Checkbox;
