import {connect} from 'react-redux';
import WelcomeComponent from './Welcome.js';
import {connectLanguage} from '../../utils/connectLanguage';
import {selectClubName, selectLang} from '../../App/selectors';
import {goToTellUsMore} from './actions.js';

const mapStateToProps = state => {
  return {
    clubName: selectClubName(state),
    lang: selectLang(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToTellUsMore: () => dispatch(goToTellUsMore()),
  };
};

const Welcome = connect(mapStateToProps, mapDispatchToProps)(WelcomeComponent);
export default connectLanguage(Welcome, connect);
