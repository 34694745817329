export default {
  'Unfortunately club is not offering any memberships.': 'Lo sentimos pero no hay cuotas disponibles en este club',
  'Unfortunately club is not offering any starterpacks': 'Desafortunadamente el club no ofrece packs de inicio',
  'Something is wrong with your network please check your internet connection': 'Red no disponible, compruebe su conexión a internet',
  'Server was not able to complete response in time': 'Mala conexión, intentar de nuevo',
  'Undefined Unit4 error': 'Error de CRM indefinido',
  'Unfortunately club has no services': 'Lo sentimos, no hay servicios extra en este club',
  'Unfortunately club has no news': 'Lo sentimos, no hay novedades en este club',
  'Unfortunately club has no events': 'Lo sentimos, no hay eventos programados en este club',
  'Unfortunately club has no classes': 'Lo sentimos, no hay clases disponibles en este club',
  'Can’t create contract': 'Contacto no creado',
  'Prospect created': 'Prospecto creada',
  'Please insert correct NIF': 'Por favor inserte un DNI/NIE válido',

  '2 weeks plan': 'Plan de 2 semanas',
  'Account number - IBAN': 'Número de cuenta - iban',
  Activate: 'activar',
  'Add to plan': 'Añadir al plan',
  ADRESS: 'DIRECCIÓN',
  'advanced payment': 'Pago Adelantado',
  'ADVANCED PAYMENT': 'PAGO ADELANTADO',
  afternoon: 'tarde',
  'All fields are required': 'Todos los campos son obligatorios',
  'Are you ready to start your journey?': '¿Estás listo para iniciar tu aventura?',
  'are you sure you want select prospect, this person has selected membership': '¿Estás seguro de que quieres seleccionar a un prospect? Esta persona ha seleccionado la cuota',
  'are you sure you want to continue': 'Estás seguro de que quieres continuar',
  BACK: 'atrás',
  'BIC SWIFT': 'Bic swift',
  'birth date': 'Fecha de nacimiento',
  BIRTHDATE: 'Fecha de nacimiento',
  Cancel: 'cancelar',
  CANCEL: 'cancelar',
  'change campaign': 'Cambiar campaña',
  Choose: 'escoger',
  city: 'ciudad',
  City: 'ciudad',
  CITY: 'CIUDAD',
  classes: 'Clases',
  'classes schedule': 'Horario de clases',
  close: 'cerrar',
  Close: 'Cerrar',
  Club: 'Club',
  'club tour': 'Club tour',
  Confirm: 'confirmar',
  'confirm as prospect': 'Confirmar como prospect',
  'Contacts in emergencies (name)': 'Contactos en emergencias (nombre)',
  'Contacts in emergencies (phone)': 'Contacto de emergencia (Teléfono)',
  continue: 'continúa',
  contract: 'contrato',
  'Contract information': 'Información del contrato',
  country: 'país',
  COUNTRY: 'PAÍS',
  'CREATE NEW': 'crear nuevo',
  'current month': 'Mes en Curso',
  DATE: 'FECHA',
  'DATE OF BIRTH': 'FECHA DE NACIMIENTO',
  passport: 'pasaporte',
  district: 'provincia',
  'fiscal number': 'DNI',
  phone: 'Telefóno',
  'Duplicate contact found': 'Contacto duplicado',
  'E-mail': 'E-mail',
  email: 'E-mail',
  Email: 'E-mail',
  'Emergency contacts': 'contactos de emergencia',
  Employer: 'empresa',
  'ENJOY THE JOURNEY': 'ENJOY THE JOURNEY',
  'Entry Fee': 'Cuota de entrada',
  evening: 'noche',
  events: 'eventos',
  female: 'Mujer',
  Female: 'Mujer',
  'FIRST FEE': 'MATRÍCULA',
  Friday: 'viernes',
  'future payments': 'Pagos Futuros',
  'Future Payments': 'Pagos Futuros',
  'FUTURE PAYMENTS': 'PAGOS FUTUROS',
  gender: 'género',
  'go to contract': 'Ir al contrato',
  Hello: 'Hola',
  'Holmes Place': 'Holmes Place',
  home: 'inicio',
  Home: 'Inicio',
  'Home phone': 'teléfono de casa',
  'INITIAL PAYMENT': 'PAGO INICIAL',
  'Invalid birth date': 'La fecha de nacimiento no es válida',
  'Invalid email address': 'Dirección de correo electrónico no válida',
  'Invalid phone number (has to start with 00 or + symbol)': 'Número de teléfono no válido (tiene que empezar con 00 o con el símbolo +)',
  'Invite your friends %{break} to join the journey with you!': '¡Invita a tus amigos a unirse al viaje contigo!',
  item: 'item',
  'Job title': 'profesión',
  'joining pack': 'Pack de Bienvenida',
  'journey hub': 'Journey Hub',
  'Lead succesfully created': 'Lead creado con éxito',
  'lead tracker': 'Lead tracker',
  "LET'S START!": 'Empecemos',
  'log in': 'entrar',
  'Log out': 'cerrar sesión',
  'Login failed. Please check your club, username and password': 'Error de inicio de sesion. Por favor revisa tu club, nombre de usuario y contraseña',
  male: 'Hombre',
  Male: 'Hombre',
  MEMBER: 'socio',
  'MEMBER INFORMATION': 'INFORMACIÓN DE SOCIO',
  'MEMBER SIGNATURE': 'FIRMA DEL SOCIO',
  membership: 'Cuota de socio',
  Membership: 'Cuota de socio',
  'MEMBERSHIP CONTRACT': 'CONTRATO DE SOCIO',
  'membership plans': 'Planes de cuota',
  memberships: 'Altas de socios',
  'MEMBERSHIPS AND PAYMENTS': 'CUOTAS Y PAGOS',
  Mobilephone: 'teléfono móvil',
  Monday: 'lunes',
  'monthly fee': 'Cuota Mensual',
  'Monthly Fee': 'Cuota Mensual',
  'MONTHLY FEE': 'MENSUALIDAD',
  'MONTHLY FEE (PRO RATA)': 'MES ACTUAL (PRORRATA)',
  'monthly plan': 'Plan mensual',
  'more events': 'Más eventos',
  'more news': 'más noticias',
  morning: 'Mañana',
  'my info': 'Mi perfil',
  name: 'nombre',
  Name: 'nombre',
  NAME: 'NOMBRE',
  address: 'Dirección postal',
  'vat number': 'número de seguridad social',
  news: 'Blog',
  'blog & events': 'Blog & Eventos',
  Next: 'siguiente',
  NEXT: 'siguiente',
  NIF: 'número de identificación',
  NO: 'NO',
  'No preference': 'Sin preferencias',
  Notes: 'Notas',
  NUTRITION: 'NUTRICIÓN',
  OK: 'De acuerdo',
  password: 'contraseña',
  Password: 'Contraseña',
  'PAY NOW': 'PAGUE AHORA',
  payment: 'pago',
  'payment today': 'Pago hoy',
  Payments: 'Pagos',
  'Personal information': 'informacion personal',
  'Phone number': 'número de teléfono',
  'PHONE NUMBER': 'TELÉFONO',
  pin: 'Pin',
  PIN: 'PIN',
  'Please check your filled data': 'Comprueba los datos rellenados',
  'Please fill all the required fields': 'Por favor rellena todos los campos obligatorios',
  'Please fill all your personal information': 'Por favor rellena toda tu información personal',
  'Please Log in to access your sales dashboard': 'Inicia sesión para acceder a tu panel de ventas',
  'Please select an answer': 'Selecciona una respuesta',
  'Please select membership first': 'Selecciona primero la cuota',
  'Please select starterpack first': 'Por favor, selecciona tu pack de bienvenida',
  'postal code': 'CÓDIGO POSTAL',
  'POSTAL CODE': 'CÓDIGO POSTAL',
  Previous: 'anterior',
  prices: 'precios',
  'promo code': 'código promocional',
  'Promo code': 'código promocional',
  PROVINCE: 'provincia',
  'RE-ENTER YOUR ACCESS INFORMATION': 'Introduce tus credenciales',
  'Remove from plan': 'Eliminar del plan',
  'reset person': 'Restablecer persona',
  'SALESPERSON SIGNATURE': 'FIRMA DEL SALES ADVISOR',
  Saturday: 'sábado',
  SELECT: 'SELECCIONAR',
  'select club': 'Selecciona tu club',
  'Selected campaign': 'Seleccionar campaña',
  'Server is not responding': 'El servidor no está respondiendo',
  services: 'servicios',
  'sign contract': 'firmar contrato',
  'Signature is missing': 'Falta la firma',
  Skip: 'omitir',
  SPAIN_ADDITION:
    'Para finalizar el proceso de alta, en la recepción de tu Club Holmes Place te informarán sobre el funcionamiento\n\t\tde nuestras instalaciones, las condiciones contractuales, las distintas finalidades de tratamiento en materia de\n\t\tprotección de datos y te solicitaremos cumplimentar un cuestionario de salud.',
  'special offer': 'Oferta Especial',
  'SPECIAL OFFER': 'OFERTA ESPECIAL',
  'start date': 'fecha de inicio',
  'START DATE': 'FECHA DE INICIO',
  'start new lead': 'Crear nuevo lead',
  'START OF MEMBERSHIP': 'INICIO DEL CONTRATO',
  starterpacks: 'Pack de Bienvenida',
  street: 'calle',
  Street: 'calle',
  STREET: 'CALLE',
  Submit: 'enviar',
  summary: 'resumen',
  Sunday: 'domingo',
  surname: 'apellidos',
  Surname: 'Apellidos',
  SURNAME: 'Apellidos',
  'Tell us more about you': 'Cuéntanos un poco más de ti',
  'Terms & Conditions': 'Términos y condiciones',
  'THANK YOU.': 'gracias.',
  'The requested event could not be found': 'No se ha podido encontrar el evento solicitado.',
  'The requested news item could not be found': 'No se ha podido encontrar la noticia solicitada',
  Thursday: 'jueves',
  TITLE: 'TÍTULO',
  TOTAL: 'TOTAL',
  'total due today': 'Total hoy',
  Tuesday: 'martes',
  TYPE: 'TIPO',
  'TYPE OF MEMBERSHIP': 'TIPO DE MEMBERSÍA',
  'Unfortunately club has no events.': 'Desafortunadamente club no tiene eventos',
  'Update your personal information': 'Actualizar tu información personal',
  USE: 'Utilizar',
  username: 'Nombre de usuario',
  value: 'valor',
  Wednesday: 'miércoles',
  welcome: 'Bienvenido',
  'WELCOME TO HOLMES PLACE': 'Bienvenido a Holmes Place',
  'Welcome to Holmes Place %{break} Get ready to enjoy the journey!': 'Bienvenido a Holmes Place - ¡Enjoy the journey! ',
  'What types of communications?': '¿Tipos de comunicacion?',
  'Where did you hear about us?': '¿Dónde escuchaste acerca de nosotros?',
  'Work phone': 'Teléfono de trabajo',
  yes: 'si',
  YES: 'SI',
  'YES!': '¡Sí!',

  // GDPR
  'Privacy Opt-In': 'Tratamiento de datos',
  'You can change your preferences at any time.': 'Podrás cambiar tus preferencias en cualquier momento.',
  'By submitting, you agree to Holmes Place Group using your personal data in accordance with our <a target="_blank" href="%{privacyURL}">Privacy Policy</a>. We use your data to personalize and improve your experience on our digital platforms, provide products and services you request from us, and carry out profiling and market research.':
    'Al proceder, acepta que Holmes Place Group use sus datos personales de acuerdo con nuestra <a target="_blank" href="%{privacyURL}">Política de Privacidad</a>. Usamos sus datos para personalizar y mejorar su experiencia en nuestras plataformas digitales, proporcionar productos y servicios que nos solicite y llevar a cabo estudios de mercado y elaboración de perfiles.',
  'To complete the registration process, at the reception of your Holmes Place Club you will be informed about the operation of our facilities, the contractual conditions and the different treatment purposes in terms of data protection. Also, you will be asked to complete a health questionnaire.':
    'Para finalizar el proceso de alta, en la recepción de tu Club Holmes Place te informarán sobre el funcionamiento de nuestras instalaciones, las condiciones contractuales, las distintas finalidades de tratamiento en materia de protección de datos y te solicitaremos cumplimentar un cuestionario de salud.',

  'You will receive your contract and your 2 week plan in your email shortly.': 'Recibirás tu contrato y tu plan de 2 semanas en breve en tu correo electrónico',
  'ZIP (xxxx-xxx)': 'Código postal (xxxx-xxx)',
  'ZIP CODE': 'CÓDIGO',

  'The field is required': 'El campo es obligatorio',

  'Invalid PIN code or PASSWORD': 'PIN o Contraseña inválidos',
  'Direct debit': 'Débito directo',
  'We will only use your bank details if you finalize your inscription. They will be used for your future monthly subscription payments.':
    'Solo utilizaremos tus datos bancarios para domiciliar las próximas mensualidades en caso de finalizar el proceso de inscripción.',
  'Document type is missing': 'ADD ES TRANSLATION.',
  week: 'semana',
  'Invalid Promocode': 'Promocode inválido',
  'Read T&C first': 'Lea los T&C primero',
  'bank account owner': 'Nombre del Titular de la Cuenta',
};
