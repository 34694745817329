import React from 'react';
import {Button, Col, Modal} from 'react-bootstrap';
import Loader from '../../components/LoaderExtraSmall';
import TellUsMoreForm from '../../components/TellUsMoreForm';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import './style.scss';

const TellUsMore = React.memo(
  ({
    person,
    onChange,
    validateField,
    phoneCodes,
    clubInfo,
    change,
    gdprOption,
    checkPerson,
    loader,
    modal,
    hideModal,
    duplicates,
    selected,
    createPerson,
    selectDuplicatePerson,
    clubs
  }) => {
    const selectedDuplicate = duplicates[selected];
    const selecedDuplicateCurrentClub = selectedDuplicate && (selectedDuplicate.memberships[0] || selectedDuplicate.prospects[0] || {}).clubExternalId === clubInfo.externalId;

    /**
     * Returns club name based on prospect or member ID
     * @param {string} id  - Prospect or member ID
     */
    const getClubName = (id) => {
      const clubExternalId = id.slice(0, 3);
      const club = clubs.find(({externalId}) => clubExternalId === externalId);
      return club ? club.name : 'Club not found';
    }

    return (
      <Page nav className="tellUsMore">
        <div className="tellUsMore modal-container">
          <form onSubmit={checkPerson(person)} style={{marginTop: '50px'}}>
            <Col md={12}>
              <h3 className="text-white heading-md">{lang('Tell us more about you')}</h3>
              {person && person.id && <p>{lang('Update your personal information')}</p>}
            </Col>
            <div>
              <TellUsMoreForm
                checked={person.gender}
                onChange={onChange()}
                data={person}
                validate={validateField}
                codes={phoneCodes}
                lang={lang}
                clubInfo={clubInfo}
                change={change}
                gdprOption={gdprOption}
              />
            </div>
            <Col md={12} style={{width: '96%', margin: '2%'}}>
              <Button onClick={checkPerson(person)} className={'button tellUsMoreButton'}>
                {loader ? <Loader /> : lang('NEXT')}
              </Button>
            </Col>
            <Modal show={modal} onHide={hideModal()} container={this} className={'container-modal-dialog'}>
              <Modal.Body>
                <div className="h4duplicatecontact">
                  <h4>{lang('Duplicate contact found')}</h4>
                </div>
                <div className="divscroll">
                  {duplicates.map(({id, name, surname, email, nif, passport, memberships, prospects}, index) => {
                    const className = index === selected ? (selecedDuplicateCurrentClub ? 'selected current-club' : 'selected') : '';
                    return (
                      <div key={`duplicate${id}`} className={`duplicateContact ${className}`} onClick={selectDuplicatePerson(index)}>
                        <div className="persontext">
                          <h4>
                            {name} {surname}
                          </h4>
                        </div>
                        <div className="emailtext">
                          <strong>{'Email: '}</strong>
                          {email}
                        </div>
                        <div className="emailtext">
                          <strong>{nif ? 'NIF: ' : 'Passport: '}</strong>

                          {nif || passport}
                        </div>
                        <div>
                          {memberships &&
                            memberships.map(({membershipId, status, taskSalesPersonName}, i) => (
                              <div key={i}>
                                <div className="duplicatecontact__text">
                                  <strong>{'Membership ID: '}</strong>
                                  {membershipId}
                                </div>
                                <div className="duplicatecontact__text">
                                  <strong>{'Status: '}</strong>
                                  {status}
                                </div>
                                <div className="duplicatecontact__text">
                                  <strong>{'Club: '}</strong>
                                  {getClubName(membershipId)}
                                </div>
                                <div className="duplicatecontact__text">
                                  <strong>{'Sales person: '}</strong>
                                  {taskSalesPersonName}
                                </div>
                              </div>
                            ))}
                        </div>
                        <div>
                          {prospects &&
                            prospects.map(({prospectId, taskSalesPersonName}, i) => (
                              <div key={i}>
                                <div className="duplicatecontact__text">
                                  <strong>{'Prospect ID: '}</strong>
                                  {prospectId}
                                </div>
                                <div className="duplicatecontact__text">
                                  <strong>{'Club: '}</strong>
                                  {getClubName(prospectId)}
                                </div>
                                <div className="duplicatecontact__text">
                                  <strong>{'Sales person: '}</strong>
                                  {taskSalesPersonName}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="customStyle modalCreateNew" onClick={() => createPerson(duplicates, 'new', person)}>
                  {lang('CREATE NEW')}
                </Button>
                <Button
                  disabled={!(selectedDuplicate && selecedDuplicateCurrentClub)}
                  className="customStyle modalUse"
                  onClick={() => createPerson(duplicates, 'use', person, selected)}>
                  {lang('USE')}
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </Page>
    );
  }
);

export default TellUsMore;
