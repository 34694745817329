import React from 'react';
import './style.scss';

const ClubTourHeader = React.memo(({title, subtitle, banner}) => {
  return (
    <header className="club-tour-heading-article">
      <div className="club-tour-heading-article__primary">{title}</div>
      {banner.url && <img className="club-tour-news-cover" itemProp="image" src={banner.url} alt={banner.altText} />}
      {subtitle && <div className="club-tour-heading-article__secondary-top">{subtitle}</div>}
    </header>
  );
});

export default ClubTourHeader;
