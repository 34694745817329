import {fromJS} from 'immutable';
import {initialState} from '../initialstate';
import {RESET_PERSON, ROUTER_ZONE_TRANSITION, CONFIRM_LOADER} from '../../constants';
import {SUBMIT_CONTACT_INFO} from '../../../containers/ContactInformation/constants';
import {LOAD_MEMBERSHIPS} from '../../../containers/Memberships/constants';
import {LOAD_PACKAGES} from '../../../containers/Packages/constants';
import {CHANGE_CLUB, SET_REGION} from '../../../containers/Login/constants.js';
import {TOGGLE_LEFT_MENU} from '../../../containers/Page/constants.js';
import {TOGGLE_RIGHT_MENU, CLOSE_CONFIRM} from '../../../containers/Page/constants';
import {ADD_TO_PLAN, REMOVE_FROM_PLAN} from '../../../containers/CalendarView/constants';
import {
  ERROR,
  LOADER,
  SUCCESS,
  SET_STATE,
  LOG_OUT_SUCCESS,
  CALENDAR_SET_WEEK_NUMBER,
  CONFIRM_AS_PROSPECT,
  CONFIRM_AS_MEMBER,
  CHANGE_LANGUAGE,
  SWITCH_APP,
  ROUTER_URL,
} from '../../../Actions/constants';
import {
  API_LOGIN_SUCCESS,
  API_GET_CLUBS_SUCCESS,
  API_CLUB_INFO_SUCCESS,
  API_UPDATE_CLUB_INFO,
  CALENDAR_DATA_SUCCESS,
  SALESPERSON_NAME_SUCCESS,
  LOGIN_REMOVE_DATA,
  VALIDATE_NIF,
  CHANGE_CURRENCY,
} from '../../../api/constants.js';
import {resetPerson, addToPlan, removeFromPlan} from './helpers';

const mainReducer = (state, action) => {
  switch (action.type) {
    case ROUTER_URL:
      return state.set('URL', action.url);
    case ROUTER_ZONE_TRANSITION:
      return state.set('zone', action.zoneLast);
    case CONFIRM_AS_MEMBER:
      return state.set('confirm', fromJS(action.obj));
    case CONFIRM_AS_PROSPECT:
      return state.set('confirm', fromJS(action.obj));
    case CLOSE_CONFIRM:
      return state.set('confirm', fromJS({open: false, msg: ''}));
    case RESET_PERSON:
      return resetPerson(state);
    case TOGGLE_LEFT_MENU:
      return state.set('leftMenuOpen', action.leftMenuOpen);
    case TOGGLE_RIGHT_MENU:
      return state.set('rightMenuOpen', action.rightMenuOpen);
    case CHANGE_LANGUAGE:
      return state.set(
        'language',
        fromJS(
          state
            .get('languages')
            .toJS()
            .find(l => l.code === action.lang)
        )
      );
    case LOAD_MEMBERSHIPS:
      return state.set('memberships', fromJS(action.memberships));
    case LOAD_PACKAGES:
      return state.set('packages', fromJS(action.packages));
    case ERROR:
      return state.set('error', {error: action.error, date: new Date()}).set('alertType', 'error');
    case SUCCESS:
      return state.set('success', {msg: action.msg, date: new Date()}).set('alertType', 'success');
    case 'INVALID_PROMO':
      return state.set('invalidPromo', action.msg);
    case SET_STATE:
      return state;
    case LOADER:
      return state.set('loader', action.load);
    case CONFIRM_LOADER:
      return state.set('confirmLoader', action.load);
    case SUBMIT_CONTACT_INFO:
      return state.setIn(['formErrors', 'contractInfo'], fromJS(action.errors));
    case ADD_TO_PLAN:
      return addToPlan(state, action);
    case REMOVE_FROM_PLAN:
      return removeFromPlan(state, action);
    case CALENDAR_DATA_SUCCESS:
      return state.set('calendarData', fromJS(action.data));
    case CALENDAR_SET_WEEK_NUMBER:
      return state.set('weekNumber', action.number);
    case CHANGE_CLUB:
      const {id, name} = state
        .getIn(['login', 'allClubs'])
        .toJS()
        .find(({id}) => Number(id) === Number(action.clubId));
      return state.setIn(['login', 'clubId'], id).setIn(['login', 'clubName'], name);
    case LOG_OUT_SUCCESS:
      return fromJS(initialState.get('mainReducer'));
    case LOGIN_REMOVE_DATA:
      return state.set(['login'], fromJS({}));
    case API_LOGIN_SUCCESS:
      return state.set('accessToken', action.data.accessToken).set('salespersonExternalId', action.data.externalId);
    case API_GET_CLUBS_SUCCESS:
      return state.set('login', state.get('login').setIn(['allClubs'], fromJS(action.data)));
    case API_CLUB_INFO_SUCCESS:
      return state
        .set('language', fromJS(action.data.languages.default))
        .set('languages', fromJS(action.data.languages.list))
        .set('club', fromJS(action.data.defaultClub));
    case API_UPDATE_CLUB_INFO:
      return state.setIn(['club', action.data.lang], fromJS(action.data.club));
    case VALIDATE_NIF:
      return state.set('validNif', action.valid);
    case SWITCH_APP:
      return state.set('app', action.app);
    case SET_REGION:
      return state.setIn(['login', 'region'], action.region);
    case SALESPERSON_NAME_SUCCESS:
      return state.set('salespersonsName', action.name);
    case CHANGE_CURRENCY:
      return state.set('currency', action.currency);
    default:
      return state;
  }
};
export {mainReducer};
