import {store} from '../../App/store';
import {selectLang} from '../../App/selectors';
import translations from './translations';

export default (text, values) => {
  if (!store) return text;

  const langCode = selectLang(store.getState());
  if (translations[langCode] && translations[langCode][text]) {
    text = translations[langCode][text] || text;
  } else if (langCode !== 'en') {
    console.warn(`Missing translation [${langCode}] for: ${text}`);
  }
  if (values) {
    Object.keys(values).forEach(key => (text = text.replace(`%{${key}}`, values[key])));
  }
  return text;
};
