/**
 * Function returns value of the last key in your depth object
 *
 * `getInObj({a:{b:{c:10}}},['a','b','c']) // 10`
 *
 * `getInObj({a:{b:{c:10}}},['a','b','c','d']) // undefined`
 *
 * `getInObj({a:{b:{c:10}}},['a','b']) // {c:10}`
 *
 * @param {{}} obj Object where you want to find the value
 * @param {Array<string>} path Path to your value in keys
 * @param {any=} empty Returned value if your value is not found
 */
export const getInObj = (obj, path, empty = undefined) => {
  if (obj) {
    if (path[0]) return getInObj(obj[path.splice(0, 1)], path, empty);
    else return obj;
  } else return empty;
};

/**
 * Returns date object created from parameters in specific format
 * @param {string} date Date in any format that supports `new Date(date)`
 * @param {string} time Time in format `HH:MM`
 */
export const getTime = (date, time = null) => {
  const d = new Date(date);
  if (time) d.setTime(d.getTime() + time.split(':')[0] * 60 * 60 * 1000 + time.split(':')[1] * 60 * 1000);
  return d;
};
