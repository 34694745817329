import React, {Component} from 'react';

import {MEMBERSHIP_PAGE, PACKAGES_PAGE} from '../../App/constants';
import Page from '../../containers/Page';
import Wrapper from '../../components/Wrapper';
import Container from '../../components/Container';
import MembershipsSubMenu from '../../components/MembershipsSubMenu';
import {sortByPriority} from '../../utils/helpers/membershipHelpers';
import './style.scss';

import lang from '../../utils/translator';

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selectedStarterpack,
    };
    this.scrolling = false;
  }

  shouldScroll(to, duration) {
    if (this.scrolling) return false;
    else this.scrollTo(to, duration);
  }

  scrollTo(to, duration) {
    if (duration <= 10) {
      this.scrolling = false;
      return;
    }
    this.scrolling = true;
    var difference = to - this.carusel.scrollLeft;
    var perTick = (difference / duration) * 10;

    setTimeout(() => {
      this.carusel.scrollLeft = this.carusel.scrollLeft + perTick;
      if (this.carusel.scrollLeft === to) return;
      this.scrollTo(to, duration - 10);
    }, 10);
  }

  select(externalId) {
    return () => {
      if (this.state.selected === externalId) return this.setState({selected: null});
      this.setState({selected: externalId});
    };
  }

  isSelected(externalId) {
    if (this.state.selected === externalId) return true;

    return false;
  }

  onJoiningPackClick = joiningPack => () => {
    const gtmObject = {
      event: 'productClick2',
      label: joiningPack.title,
      ecommerce: {
        click: {
          joiningPack: {
            name: joiningPack.title,
            id: joiningPack.externalId,
            price: joiningPack.price,
            position: joiningPack.priority,
          },
        },
      },
    };
    if (window && window.dataLayer) {
      window.dataLayer.push(gtmObject);
    }
    this.props.choosePackage(joiningPack.externalId)();
  };

  render() {
    const starterpacks = this.props.starterpacks.filter(i => i.dspAvailable === true).sort(sortByPriority);

    return (
      <Page nav showLeftSidebar={false} navBarType={'nav--dark'} back={MEMBERSHIP_PAGE}>
        <div className="packages">
          <MembershipsSubMenu current={PACKAGES_PAGE} membershipsExist={this.props.memberships} packagesExist={this.props.starterpacks} lang={lang} />
          <Wrapper>
            {starterpacks.length > 3 && (
              <div
                className="carousel-arrows carousel-arrows--right faa-angle-double-right"
                onClick={() => this.scrollTo(this.carusel.scrollLeft + this.carusel.clientWidth - 100, 500)}></div>
            )}
            {starterpacks.length > 3 && (
              <div
                className="carousel-arrows carousel-arrows--left faa-angle-double-left"
                onClick={() => this.scrollTo(this.carusel.scrollLeft - this.carusel.clientWidth - 100, 500)}></div>
            )}
            <div className="blockSlider" ref={carusel => (this.carusel = carusel)}>
              {starterpacks.map((pack, index) => (
                <Container
                  key={pack.externalId}
                  oneTimePayment={true}
                  price={pack.price}
                  currency={this.props.currency}
                  title={pack.title}
                  description={pack.description}
                  onSelect={this.onJoiningPackClick(pack)}
                  active={this.isSelected(pack.externalId)}
                  onClick={this.select(pack.externalId)}
                  lang={lang}
                />
              ))}
            </div>
          </Wrapper>
        </div>
      </Page>
    );
  }
}

export default Packages;
