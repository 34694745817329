import React, {PureComponent} from 'react';
import {CLUB_TOUR_WELCOME_PAGE} from '../../../App/constants.js';
import ClubTourSubMenu from '../../../components/ClubTourSubMenu';
import Loader from '../../../components/Loader';
import lang from '../../../utils/translator';
import Page from '../../Page';
import WelcomeIframe from '../../WelcomeIframe';
import './style.scss';

class Welcome extends PureComponent {
  componentWillMount() {
    var width = window.innerWidth;
    if (width < 480) this.setState({slidesToShow: 1});
    else this.setState({slidesToShow: 2});
  }

  render() {
    return (
      <Page nav navBarType={'nav--dark'}>
        <div className="club-tour-welcome">
          <ClubTourSubMenu current={CLUB_TOUR_WELCOME_PAGE} aboveContent={false} lang={lang} />
          {(this.props.club.loaded && <WelcomeIframe />) || <Loader />}
        </div>
      </Page>
    );
  }
}

export default Welcome;
