export const ERROR = 'App/Actions::ERROR';
export const SUCCESS = 'App/Actions::SUCCESS';
export const LOADER = 'App/Actions::LOADER';
export const SET_STATE = 'App/Actions::SET_STATE';
export const LOG_OUT_SUCCESS = 'App/Actions::LOG_OUT_SUCCESS';
export const CHANGE_LANGUAGE = 'App/Actions::CHANGE_LANGUAGE';
export const ROUTER_URL = 'App/Actions::ROUTER_URL';

export const PERSON_CHANGE_FIELD = 'App/Actions::PERSON_CHANGE_FIELD';
export const PERSON_VALIDATE_FORM = 'App/Actions::PERSON_VALIDATE_FORM';
export const PERSON_SET_MEMBERSHIP = 'App/Actions::PERSON_SET_MEMBERSHIP';

/* CALENDAR */
export const CALENDAR_SET_WEEK_NUMBER = 'App/Actions::CALENDAR_SET_WEEK_NUMBER';

/* TELL US MORE */
export const TELLUSMORE_SHOW_MODAL = 'App/Actions::SHOW_MODAL';

export const CONFIRM_AS_PROSPECT = 'App/Actions::CONFIRM_AS_PROSPECT';
export const CONFIRM_AS_MEMBER = 'App/Actions::CONFIRM_AS_MEMBER';

export const API_CHECK_NIF = 'App/Actions::API_CHECK_NIF';
export const SWITCH_APP = 'App/Actions::SWITCH_APP';
export const PROMO_CHANGE = 'App/Actions::PROMO_CHANGE';

/* LANGUAGE CHANGE */

export const API_FETCH_MISSING_DATA = 'App/Actions::API_FETCH_MISSING_DATA';
