import React from 'react';
import FloatingLabels from '../../../../../components/FloatingLabels';
import {Col} from 'react-bootstrap';
import phoneCodes from './phoneCodes';

const PhoneInput = ({personalInfo, changeField, field}) => (
  <Col key={'mobile'} md={6} sm={6} xs={12} className={'line-form--gold line-form'}>
    <div className="row no-gutters">
      <div className="col-xs-4" style={{paddingTop: '10px'}}>
        <select
          className="c-salesProcess__main__pInformation__prefix"
          value={personalInfo.phoneCode}
          style={{width: '100%', textAlign: 'center'}}
          onChange={e => changeField('phoneCode')(e.target.value)}>
          {Object.keys(phoneCodes)
            .map(key => phoneCodes[key].phone_code)

            .sort()
            .map((prefix, key) => (
              <option key={key}>{prefix}</option>
            ))}
        </select>
      </div>
      <div className="col-xs-8">
        <FloatingLabels type="text" placeholder={field.placeholder} value={personalInfo.mobile} onChange={e => changeField('mobile')(e.target.value)} style={{color: '#a3a3a3'}} />
      </div>
    </div>
  </Col>
);

export default PhoneInput;
