import {connect} from 'react-redux';
import ContactInformationComponent from './ContactInformation';
import {validateField, selectSelectedPackage, selectClubInfo, selectLoginForm, selectRegion} from '../../App/selectors';
import {connectLanguage} from '../../utils/connectLanguage';
import {codes} from '../../utils/phoneCodes';
import {countries} from '../../utils/countries';

import {selectSelectedMembership, selectPersonalInfo, selectEmergencyContacts, selectPayments, selectNotes, selectGdprOption} from './selectors';
import {personChangeField, personValidateForm} from '../../Actions/index';

const mapStateToProps = state => {
  return {
    membership: selectSelectedMembership(state),
    package: selectSelectedPackage(state),
    personalInfo: selectPersonalInfo(state),
    emergencyContacts: selectEmergencyContacts(state),
    payments: selectPayments(state),
    gdprOption: selectGdprOption(state),
    validateField: name => validateField(state, name),
    codes: codes,
    clubInfo: selectClubInfo(state),
    country: selectLoginForm(state).toJS().region,
    countries,
    region: selectRegion(state),
    notes: selectNotes(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change: id => value => dispatch(personChangeField(id, value)),
    submit: () => dispatch(personValidateForm('contract')),
    onChange: (id, value) => dispatch(personChangeField(id, value)),
  };
};

const ContactInformation = connect(mapStateToProps, mapDispatchToProps)(ContactInformationComponent);
export default connectLanguage(ContactInformation, connect);
