import React from 'react';
import './style.scss';

class ClubTourWrapperInner extends React.PureComponent {
  render() {
    return (
      <div className={'club-tour-wrapper-inner'} style={this.props.style || {}}>
        {this.props.children}
      </div>
    );
  }
}

export default ClubTourWrapperInner;
