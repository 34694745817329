import React, {Component} from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import {clubRegionExternalId} from '../../App/selectors';
import {store} from '../../App/store/store';
import './style.scss';

class PhonePrefix extends Component {
  render() {
    const regionExternalId = clubRegionExternalId(store.getState());
    if (!this.props.info.phoneCode && regionExternalId) {
      this.props.change('phoneCode', this.props.codes[regionExternalId.toUpperCase()].phone_code, this.props.pos);
    }

    return (
      <DropdownButton id="dropdownCodes" className="customStyle" title={this.props.info.phoneCode || 'code'} onSelect={e => this.props.change('phoneCode', e, this.props.pos)}>
        {Object.keys(this.props.codes).map(code => {
          return (
            <MenuItem key={`code${code}`} eventKey={this.props.codes[code].phone_code}>
              {this.props.codes[code].phone_code}
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  }
}

export default PhonePrefix;
