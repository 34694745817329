import React from 'react';
import {Link} from 'react-router';
import {CLUB_TOUR_CLASSES_SCHEDULE_PAGE, CLUB_TOUR_EVENTS_PAGE, CLUB_TOUR_NEWS_PAGE, CLUB_TOUR_WELCOME_PAGE, MEMBERSHIP_PAGE} from '../../App/constants.js';
import DoubleArrow from '../DoubleArrow';
import {RIGHT} from '../DoubleArrow/constants.js';
import SubMenuGrey from '../SubMenuGrey';
import Item from '../SubMenuGrey/Item';
import './style.scss';

/**
 * progression of menu pages (flow)
 */
const progression = [CLUB_TOUR_WELCOME_PAGE, CLUB_TOUR_CLASSES_SCHEDULE_PAGE, CLUB_TOUR_NEWS_PAGE, CLUB_TOUR_EVENTS_PAGE, MEMBERSHIP_PAGE];

/**
 * @props
 * string current current url
 */
const ClubTourSubMenu = props => {
  const renderItem = (itemLink, itemLabel) => {
    if (itemLink === props.current)
      if (props.currentClickable)
        return (
          <Link to={itemLink}>
            <Item isCurrent>{itemLabel}</Item>
          </Link>
        );
      else return <Item isCurrent>{itemLabel}</Item>;

    return (
      <Link to={itemLink}>
        <Item>{itemLabel}</Item>
      </Link>
    );
  };

  const renderNextArrow = () => {
    const current = props.current;
    const next = progression[progression.indexOf(current) + 1];
    return (
      <Link to={next}>
        <Item>
          <DoubleArrow orientation={RIGHT} />
        </Item>
      </Link>
    );
  };

  return (
    <div className={'club-tour-sub-menu__wrapper' + (props.aboveContent === true ? ' above-content' : '')}>
      <SubMenuGrey aboveContent={props.aboveContent}>
        {renderItem(CLUB_TOUR_WELCOME_PAGE, props.lang('welcome'))}
        {renderItem(CLUB_TOUR_CLASSES_SCHEDULE_PAGE, props.lang('classes schedule'))}
        {renderItem(CLUB_TOUR_NEWS_PAGE, props.lang('blog'))}
        {renderItem(CLUB_TOUR_EVENTS_PAGE, props.lang('events'))}
        {renderNextArrow()}
      </SubMenuGrey>
    </div>
  );
};

export default ClubTourSubMenu;
