import {
  TELLUSMORE_SHOW_MODAL,
  PERSON_CHANGE_FIELD,
  PERSON_VALIDATE_FORM,
  ERROR,
  SUCCESS,
  LOADER,
  SET_STATE,
  LOG_OUT_SUCCESS,
  CALENDAR_SET_WEEK_NUMBER,
  CONFIRM_AS_PROSPECT,
  CONFIRM_AS_MEMBER,
  CHANGE_LANGUAGE,
  API_FETCH_MISSING_DATA,
  API_CHECK_NIF,
  SWITCH_APP,
  PROMO_CHANGE,
  ROUTER_URL,
  PERSON_SET_MEMBERSHIP,
} from './constants';

import * as apiActionConstants from '../api/constants';
import * as selectors from '../App/selectors';

import {LOGIN_PAGE, RESET_PERSON} from '../App/constants';
import {browserHistory} from 'react-router';

import translator from '../utils/translator';

export const showError = error => {
  error = translator(error);
  return {type: ERROR, error};
};

export const showSuccess = msg => {
  msg = translator(msg);
  return {type: SUCCESS, msg};
};

export const loader = load => {
  return {type: LOADER, load};
};

export const setState = () => {
  return {type: SET_STATE};
};

export const logoutSuccess = () => {
  return dispatch => {
    dispatch({type: LOG_OUT_SUCCESS});
    window.localStorage.clear();
    browserHistory.push(LOGIN_PAGE);
  };
};

export const changeLanguage = lang => {
  return {type: CHANGE_LANGUAGE, lang};
};

export const fetchMissingData = lang => {
  return {type: API_FETCH_MISSING_DATA, lang};
};

export const personValidateForm = formType => {
  return {type: PERSON_VALIDATE_FORM, formType};
};

export const personChangeField = (id, value) => {
  if (id === 'identificationNumber' && value.length > 0) return {type: API_CHECK_NIF, id, value};
  return {type: PERSON_CHANGE_FIELD, id, value};
};

/* TELL US MORE */
export const tellusmoreShowModal = state => {
  return {type: TELLUSMORE_SHOW_MODAL, state};
};

export const createPerson = (data, actionType, pageType) => {
  return (dispatch, getState) => {
    if (pageType === 'personalpage') {
      return dispatch(personValidateForm('personalpage'));
    } else if (actionType === 'use' && !selectors.selectPerson(getState()).id) {
      dispatch(tellusmoreShowModal(false));
      dispatch(createPersonSuccess(data));
      dispatch(tellusmoreShowModal(false));
    } else {
      dispatch(personValidateForm('tellusmore'));
    }
  };
};

export const createPersonSuccess = data => {
  return {type: apiActionConstants.API_CREATE_PERSON_SUCCESS, data};
};

/* CALENDAR */
export const calendarSetWeekNumber = number => {
  return {type: CALENDAR_SET_WEEK_NUMBER, number};
};

export const confirmAsProspect = obj => {
  return {type: CONFIRM_AS_PROSPECT, obj};
};

export const confirmAsMember = obj => {
  return {type: CONFIRM_AS_MEMBER, obj};
};

export const switchApp = app => {
  return {type: SWITCH_APP, app};
};

export const resetPerson = () => {
  return {type: RESET_PERSON};
};

export const changePromo = promo => {
  return {
    type: PROMO_CHANGE,
    promo,
  };
};

export const saveURLtoStore = url => {
  return {
    type: ROUTER_URL,
    url,
  };
};

export const setMembership = membership => {
  return {
    type: PERSON_SET_MEMBERSHIP,
    membership,
  };
};
