import React from 'react';
import {Button, Col} from 'react-bootstrap';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import './style.scss';

const WelcomeToClubName = React.memo(({clubName, goToTellUsMore}) => (
  <Page nav className="welcomeToClubName" navBarType={'nav--dark'}>
    <div className="welcomeToClubName">
      <div className="vcenter-container">
        <Col md={12}>
          <h2 className="welcome-message">{lang('WELCOME TO HOLMES PLACE')}</h2>
          <h2 className="clubname">
            <ins>{clubName}</ins>
          </h2>
          <h4>{lang('Are you ready to start your journey?')}</h4>
          <Button id="mobileButton" onClick={goToTellUsMore} className="button">
            {lang('YES!')}
          </Button>
        </Col>
      </div>
    </div>
  </Page>
));

export default WelcomeToClubName;
