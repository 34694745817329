import React, {Component} from 'react';
import './style.scss';

/**
 * Sample usage:
 * <SubMenuGrey>
 * 	<Item>Stuff</Item>
 *  <Item>Stuff</Item>
 * </SubMenuGrey>
 */
class SubMenuGrey extends Component {
  render() {
    const {suffix} = this.props;
    return (
      <div className={`sub-menu-grey sub-menu-grey${suffix ? suffix : ''}`}>
        <div className={`sub-menu-grey__no-items sub-menu-grey__no-items--left ${suffix ? `sub-menu-grey${suffix}__no-items sub-menu-grey${suffix}__no-items--left` : ''}`} />
        {this.props.children}
        <div className={`sub-menu-grey__no-items sub-menu-grey__no-items--right ${suffix ? `sub-menu-grey${suffix}__no-items sub-menu-grey${suffix}__no-items--right` : ''}`} />
      </div>
    );
  }
}

export default SubMenuGrey;
