import React, {Component} from 'react';
import {FormControl} from 'react-bootstrap';
/* 
	onChange
	placeholder
	data
	value
*/
export default class Select extends Component {
  render() {
    return (
      <FormControl data-required id={this.props.id} componentClass="select" onChange={this.props.onChange} value={this.props.value || this.props.placeholder}>
        <option value={this.props.placeholder} disabled style={{display: 'none'}}>
          {this.props.placeholder}
        </option>
        {this.props.data.map(item => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </FormControl>
    );
  }
}
