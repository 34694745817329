import axios from 'axios';
import {store} from '../App/store';
import {createCustomError} from '../utils/errors';
import translate from '../utils/translator';

const localUrl = 'http://127.0.0.1:8080/holmesplace/api';
const productionUrl = 'https://memberjourneyhub.com/holmesplace/api';
const timeout = '50000';

const Cloud = axios.create({
  baseURL: productionUrl,
  headers: {'Content-Type': 'application/json'},
  timeout,
});

Cloud.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers.common['Authorization'] = `Bearer ${store.getState().getIn(['mainReducer', 'accessToken'])}`;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Cloud.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.message === `timeout of ${timeout}ms exceeded`) {
      const TimeoutError = createCustomError('Timeout', translate('Server was not able to complete response in time'));

      return Promise.reject(new TimeoutError());
    }

    if (error.message === 'Network Error') {
      const NetworkError = createCustomError('Network Error', translate('Something is wrong with your network, please check your internet connection'));

      return Promise.reject(new NetworkError());
    }

    if (error.response.status === 401) {
      const Unauthorized = createCustomError('Unauthorized');
      return Promise.reject(new Unauthorized());
    }

    if (error.response.data && error.response.data.name === 'Unit4Connector') {
      const errorMsg = error.response.data.unit4 ? error.response.data.unit4.msg : translate('Undefined Unit4 error');

      const Unit4Error = createCustomError('Unit4Error', errorMsg);
      return Promise.reject(new Unit4Error());
    }

    return Promise.reject(error);
  }
);

export default Cloud;
