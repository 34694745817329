import {connect} from 'react-redux';
import PageComponent from './Page.js';
import {connectLanguage} from '../../utils/connectLanguage';
import {ZONE_CUSTOMER} from '../../App/constants';
import * as selectors from './selectors';
import {selectClubId, selectClubExternalId, selectVerifyInfo, selectLanguages, selectLang, selectZone, selectApp} from '../../App/selectors';
import {logoutSuccess, changeLanguage, fetchMissingData} from '../../Actions/index';
import {
  resetPerson,
  verifyPassword,
  verifyPin,
  confirmProspect,
  confirmContinue,
  closeConfirmModal,
  sendForm,
  hideModal,
  onRightMenuToggle,
  onLeftMenuToggle,
  startNewLeadContainer,
  getSalespersonsName,
  toggleRightMenu,
  toggleLeftMenu,
} from './actions';
import {verifyPersonReset} from '../../App/actions';
const mapStateToProps = state => {
  return {
    leftMenuOpen: selectors.selectLeftMenuOpen(state),
    rightMenuOpen: selectors.selectRightMenuOpen(state),
    verify: selectVerifyInfo(state),
    confirm: selectors.selectConfirm(state),
    confirmLoader: selectors.selectConfirmLoader(state),
    salespersonsName: selectors.selectSalespersonsName(state),
    languages: selectLanguages(state),
    lang: selectLang(state),
    zone: selectZone(state),
    app: selectApp(state),
    clubExternalId: selectClubExternalId(state), // TODO: should be deleted
    clubId: selectClubId(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => () => dispatch(logoutSuccess()),
    onRightMenuToggle: (menuOpen, zone, verify, verifyPersonReset) => onRightMenuToggle(dispatch, menuOpen, zone, verify, verifyPersonReset),
    onLeftMenuToggle: (menuOpen, app) => onLeftMenuToggle(dispatch, menuOpen, app),
    sendForm: (type, action, form) => () => sendForm(dispatch, type, action, form),
    hideModal: () => () => hideModal(dispatch, false),
    resetPerson: () => dispatch(resetPerson()),
    verifyPassword: e => dispatch(verifyPassword(e.target.value)),
    verifyPin: e => dispatch(verifyPin(e.target.value)),
    confirmAsProspect: () => dispatch(confirmProspect()),
    confirmContinue: prospect => dispatch(confirmContinue(prospect)),
    closeConfirmModal: () => dispatch(closeConfirmModal()),
    changeLang: lang => {
      dispatch(changeLanguage(lang));
      dispatch(fetchMissingData(lang));
    },
    startNewLead: () => startNewLeadContainer(dispatch),
    verifyPersonReset: () => dispatch(verifyPersonReset()),
    getSalespersonsName: () => dispatch(getSalespersonsName()),
    closeSidebars: (left, right, zone) => {
      if (right) {
        dispatch(toggleRightMenu(false));
        if (zone === ZONE_CUSTOMER) dispatch(verifyPersonReset());
      }
      if (left) dispatch(toggleLeftMenu(false));
    },
  };
};

const Page = connect(mapStateToProps, mapDispatchToProps)(PageComponent);
export default connectLanguage(Page, connect);
