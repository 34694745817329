import React from 'react';
import magnifyingGlassIcon from '../../assets/magnifying_glass.png';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';

import './style.scss';

export default class FloatingLabel extends React.Component {
  state = {
    hasValue: this.props.value ? true : false,
    value: this.props.value,
  };

  debounceOnChange = debounce(e => this.props.onChange(e), 300);

  onChange = event => {
    this.setState({hasValue: Boolean(event.currentTarget.value)});

    if (this.props.onChangeImmediately) this.props.onChangeImmediately(event);

    this.setState({value: event.target.value, id: event.target.id});
    // It is only way you could pass event to debounce
    if (event.target.id !== 'promo') {
      const fakeEvent = {
        target: {value: event.target.value, id: event.target.id},
      };
      this.debounceOnChange(fakeEvent);
    }
  };

  onSearch = (value, id) => () => {
    const fakeEvent = {target: {value, id}};
    this.props.onChange(fakeEvent);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props) || JSON.stringify(nextState) !== JSON.stringify(this.state);
  }

  componentWillUpdate(nextProps) {
    const nextHasValue = nextProps.value ? true : false;
    if (nextHasValue !== this.state.hasValue) this.setState({hasValue: nextHasValue});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.value});
  }

  render() {
    const {autoComplete, errorMsg, id, isDisabled, placeholder, type, search} = this.props;
    const {hasValue} = this.state;

    const inputClasses = classNames('fl-input', {'fl-valid': hasValue && !errorMsg}, {'fl-invalid': hasValue && errorMsg});
    const value = (this.props.onChangeImmediately ? this.props.value : this.state.value) || '';

    return (
      <div>
        <div className="fl-input-container" autoComplete={autoComplete}>
          <input
            autoComplete={autoComplete}
            className={inputClasses}
            disabled={isDisabled}
            id={id}
            onClick={this.props.onClick}
            onChange={this.onChange}
            onBlur={this.onBlur}
            type={type}
            value={value}
          />
          <label className="fl-input-label" htmlFor={id} style={this.props.style}>
            {placeholder}
          </label>
          <span className="fl-input-bar"></span>
          {search && <img alt="search" className="search" src={magnifyingGlassIcon} onClick={this.onSearch(value, id)} />}
        </div>
        {errorMsg && <span className={'fl-error-msg'}>{errorMsg}</span>}
      </div>
    );
  }
}

FloatingLabel.defaultProps = {
  autoComplete: 'off',
  type: 'text',
  isDisabled: false,
  id: uniqueId('floatingLabel'),
  placeholder: 'placeholder',
};
