import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

class Payments extends Component {
  render() {
    return (
      <div className="contract__payment">
        <Row>
          <Col md={12}>
            <h3 className="text-gold contract__subheading">{this.props.lang('INITIAL PAYMENT')}</h3>
          </Col>
        </Row>
        {this.props.children}
      </div>
    );
  }
}

export default Payments;
