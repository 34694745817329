import moment from 'moment';
import React from 'react';
import getDateFormat from '../../utils/helpers/getDateFormat';
import FloatingLabel from '../FloatingLabels';
import ReactMobileDatePicker from './DatePicker';

export default class DatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  disableScroll() {
    document.body.style.overflow = 'hidden';
  }
  enableScroll() {
    document.body.style.overflow = 'unset';
  }

  handleToggle(isOpen) {
    return () => {
      if (isOpen) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
      this.setState({isOpen});
    };
  }

  handleThemeToggle() {
    return () => {
      this.disableScroll();
      this.setState({isOpen: true});
    };
  }

  handleSelect(time) {
    this.enableScroll();
    this.setState({isOpen: false});
    this.props.onChange({time});
  }

  render() {
    return (
      <div>
        <FloatingLabel
          type="text"
          placeholder={this.props.placeholder}
          onClick={this.handleThemeToggle()}
          value={moment(this.props.value).format(getDateFormat(9))}
          onChange={e => {}}
        />
        <ReactMobileDatePicker
          lang={this.props.lang}
          modal={this.props.modal}
          value={new Date(this.props.value)}
          theme={'ios'}
          isOpen={this.state.isOpen}
          dateFormat={['DD', 'MM', 'YYYY']}
          onSelect={this.handleSelect.bind(this)}
          onCancel={this.handleToggle(false)}
          min={this.props.min}
          max={this.props.max}
        />
      </div>
    );
  }
}
