import moment from 'moment';
import {ADD_TO_PLAN, API_GET_CALENDAR, REMOVE_FROM_PLAN, CALENDAR_WEEK_NUMBER} from './constants';

function addToPlan(lesson, number) {
  return {
    type: ADD_TO_PLAN,
    lesson,
    number,
  };
}

function removeFromPlan(lesson, number) {
  return {
    type: REMOVE_FROM_PLAN,
    lesson,
    number,
  };
}

function getCalendarData(number) {
  return {
    type: API_GET_CALENDAR,
    monday: moment()
      .startOf('week')
      .add(number, 'd')
      .format('YYYY-MM-DD'),
  };
}

function setNumber(number) {
  getCalendarData(number);
  return {
    type: CALENDAR_WEEK_NUMBER,
    number,
  };
}

function loadNext() {
  // num = num + 7;
  return getCalendarData();
}

function loadPrevious() {
  // num = num - 7;
  return getCalendarData();
}

export {addToPlan, getCalendarData, loadNext, loadPrevious, removeFromPlan, setNumber};
