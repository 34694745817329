export function getDataForRefferals(club, person, referrals, app, campaign) {
  const memberClubExternalId = person.memberships && person.memberships.length > 0 ? person.memberships[0].clubExternalId : null;
  return {
    club: {
      id: club.id,
      externalId: club.externalId,
    },
    person: {
      id: person.id,
      name: person.name,
      surname: person.surname,
      nationalId: person.nif,
      street: person.street,
      city: person.city,
      province: person.province,
      postalCode: person.postalCode,
      mobile: person.mobile,
      phone: person.phone,
      email: person.email,
      birthDate: person.birthDate,
      sex: mapSex(person.sex),
      passport: person.passport,
      phoneHome: person.phoneHome,
      startDate: person.startDate,
      employer: person.employer,
      jobTitle: person.jobTitle,
      emergencyContactName1: person.emergencyContactName1,
      emergencyContactPhone1: person.emergencyContactPhone1,
      emergencyContactName2: person.emergencyContactName2,
      emergencyContactPhone2: person.emergencyContactPhone2,
      clubExternalId: memberClubExternalId,
      isCitizen: person.isCitizen,
      refererId: person.memberships && person.memberships.length > 0 ? person.memberships[0].membershipId.replace(memberClubExternalId || '', '').replace(/.$/, '') : null,
      campaignId: app === 'LT' ? campaign.id : null,
    },
  };
}

export function getDataForProspect(club, person, answers) {
  return {
    club: {
      id: club.id,
      externalId: club.externalId,
    },
    person: {
      id: person.id,
      name: person.name,
      surname: person.surname,
      nationalId: person.nif,
      street: person.street,
      city: person.city,
      province: person.province,
      postalCode: person.postalCode,
      mobile: person.mobile,
      phone: person.phone,
      email: person.email,
      birthDate: person.birthDate,
      sex: mapSex(person.sex),
      passport: person.passport,
      phoneHome: person.phoneHome,
      startDate: person.startDate,
      employer: person.employer,
      jobTitle: person.jobTitle,
      emergencyContactName1: person.emergencyContactName1,
      emergencyContactPhone1: person.emergencyContactPhone1,
      emergencyContactName2: person.emergencyContactName2,
      emergencyContactPhone2: person.emergencyContactPhone2,
      prospect: person.prospects && person.prospects[0] ? person.prospects[0] : null,
      membership: person.memberships && person.memberships[0] ? person.memberships[0] : null,
      isCitizen: person.isCitizen,
    },
  };
}

export function getDataForMember(club, person, contract, answers, promoCode) {
  return {
    club: {
      id: club.id,
      externalId: club.externalId,
    },
    person: {
      id: person.id,
      name: person.name,
      surname: person.surname,
      nationalId: person.nif,
      street: person.street,
      city: person.city,
      country: person.country,
      province: person.province,
      postalCode: person.postalCode,
      phoneCode: person.phoneCode,
      mobile: person.mobile,
      phone: person.phone,
      workPhone: person.phoneWork,
      email: person.email,
      birthDate: person.birthDate,
      sex: mapSex(person.sex),
      phoneHome: person.phoneHome,
      employer: person.employer,
      jobTitle: person.jobTitle,
      notes: person.notes,
      accountOwner: person.accountOwner,
      iban: person.memberIban,
      swift: person.memberSwift,
      isCitizen: person.isCitizen,
      emergencyContact: [
        {
          name: person.contactsInEmergencieName,
          phone: person.contactsInEmergenciePhone,
        },
        {
          name: person.contactsInEmergencieName2,
          phone: person.contactsInEmergenciePhone2,
        },
      ],
      communicationType: person.communicationType,
      nutrition: person.nutrition,
      notes: person.notes,
      photo: contract.photo,
      prospect: person.prospects && person.prospects[0] ? person.prospects[0] : null,
      membership: person.memberships && person.memberships[0] ? person.memberships[0] : null,
    },
    contract: {
      membershipExternalId: contract.selectedMembership,
      starterPackExternalId: contract.selectedPackage,
      signature: {
        person: contract.signature,
        salesperson: contract.signatureSalesperson,
      },
      promoCode,
      startDate: person.startDate,
      paidOnSignation: person.paidOnSignation,
    },
  };
}

function mapSex(sex) {
  if (sex === 'male' || sex === 'Male' || sex === 'M') return 'M';
  else return 'F';
}
