export const PERSON_IS_MISSING = 'Please fill all your personal information';
export const FIELDS_ARE_REQUIRED = 'All fields are required';
export const SOMTHING_IS_MISSING = 'Please check your filled data';
export const MEMBERSHIP_NOT_SELECTED = 'Please select membership first';
export const STARTERPACK_NOT_SELECTED = 'Please select starterpack first';
export const CONTRACT_INFO_MISSING = 'Please fill all the required fields';
export const MISSING_SIGNATURE = 'Signature is missing';
export const SERVER_ERROR = 'Server is not responding';
export const INVALID_BIRTHDATE = 'Invalid birth date';
export const INVALID_PHONE_NUMBER = 'Invalid phone number (has to start with 00 or + symbol)';
export const INVALID_EMAIL = 'Invalid email address';
export const DOC_TYPE_MISSING = 'Document type is missing';
export const INVALID_PIN_PASSWORD = 'Invalid PIN code or PASSWORD';
export const INVALID_PIN_LENGTH = 'Pin must be four digits long';
export const INVALID_CREDENTIALS = 'Login failed. Please check your club, username and password';
export const REQUESTED_NEWS_NOT_FOUND = 'The requested blog is not available in current language';
export const REQUESTED_EVENT_NOT_FOUND = 'The requested event is not available in current language';
export const NO_MEMBERSHIPS_FOUND = 'Unfortunately club is not offering any memberships';
export const NO_STARTERPACKS_FOUND = 'Unfortunately club is not offering any starterpacks';
export const NO_NEWS_FOUND = 'Unfortunately club has no news';
export const NO_SERVICES_FOUND = 'Unfortunately club has no services';
export const NO_EVENTS_FOUND = 'Unfortunately club has no events';
export const NO_CLASSES_FOUND = 'Unfortunately club has no classes';
export const NO_CONTRACT = "Can't create contract";
export const PAYMENT_CANNOT_BE_PROCESSED = 'Payment cannot be processed';
export const CAMPAIGN_SELECT = 'Please select campaign';
export const LEAD_TRACKED = 'Lead succesfully created';
export const MEMBERSHIP_CREATED = 'Prospect created';
export const OFFERS_NOT_FOUND = 'No offers found';
export const INVALID_NIF = 'Please insert correct NIF';
export const WRONG_FILE_TYPE = 'Cant process this file as image, only "jpeg" allowed';
export const UNKNOWN_FILE_ERROR = 'System is not able to work with this file';
export const MEMBERSHIP_REQUIRED = 'Allowed only for members';
