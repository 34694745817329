import React, {Component} from 'react';
import _ from 'lodash';
import {Col} from 'react-bootstrap';
import FloatingLabels from '../../../../../components/FloatingLabels';

class ZipInput extends Component {
  formatZip(value) {
    value = value || '';
    const {changeField, field, personalInfo} = this.props;
    var num = value.replace(/\D/g, '');
    switch (personalInfo.country) {
      case 'Portugal':
      case 'PT':
        if (num.length > 4) {
          return changeField(field.name)(num.substr(0, 4) + '-' + num.substr(4, 3));
        } else return changeField(field.name)(num);
      default:
        return changeField(field.name)(num);
    }
  }

  componentDidUpdate(prevProps) {
    const {field, personalInfo} = this.props;
    if (personalInfo.country !== prevProps.personalInfo.country) this.formatZip(personalInfo[field.name]);
  }

  render() {
    const {personalInfo, error, field} = this.props;
    return (
      <Col key={field.name} md={6} sm={6} xs={12} className={'line-form--gold line-form'}>
        <FloatingLabels
          type="text"
          placeholder={field.placeholder}
          value={personalInfo[field.name] || ''}
          onChangeImmediately={e => this.formatZip(e.target.value)}
          onChange={e => this.formatZip(e.target.value)}
          style={{color: '#a3a3a3'}}
        />

        {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
      </Col>
    );
  }
}

export default ZipInput;
