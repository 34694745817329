import {API_LOGIN, CHANGE_CLUB, API_GET_CLUBS, API_CLUB_INFO, SET_REGION} from './constants.js';
import {INVALID_PIN_LENGTH} from '../../App/errorConstatns';
import {showError} from '../../Actions';

const tryToLogin = (clubId, username, password, pin) => {
  return dispatch => {
    if (pin.length < 4) dispatch(showError(INVALID_PIN_LENGTH));
    else dispatch(logIn(clubId, username, password, pin));
  };
};

const logIn = (clubId, username, password, pin) => {
  return {
    type: API_LOGIN,
    username,
    password,
    clubId,
    pin,
  };
};

const getClubs = () => {
  return {
    type: API_GET_CLUBS,
  };
};

const changeClub = clubId => {
  return {
    type: CHANGE_CLUB,
    clubId,
  };
};

const clubInfo = () => {
  return {
    type: API_CLUB_INFO,
  };
};

const changeRegion = region => {
  return {
    type: SET_REGION,
    region,
  };
};

export {getClubs, changeClub, clubInfo, changeRegion, tryToLogin};
