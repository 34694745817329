import React from 'react';
import Loader from '../../components/Loader';
import LoginForm from '../../components/LoginForm';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import './style.scss';

const Login = React.memo(props => {
  return (
    <Page sideMenu={false}>
      <div className="Login section" style={{textAlign: 'center'}}>
        {props.loader ? (
          <Loader />
        ) : (
          <div className="Login__wrapper">
            <img src={require('../../assets/logo_inline_w.svg')} alt={'logoInline'} />
            <h2>{lang('journey hub')}</h2>
            <LoginForm
              onClick={() => props.logIn(props.clubId, props.username, props.password, props.pinCode)}
              changeClub={props.changeClub}
              club={props.selectedClub}
              allClubs={props.allClubs}
              regions={props.regions.filter(r => {
                // Filter only Portugal & Spain
                return ['Portugal', 'Spain'].includes(r);
              })}
              selectedRegion={props.selectedRegion}
              onChange={props.onChange}
              onSelectRegion={props.onSelectRegion}
              username={props.username}
              password={props.password}
              ifPin={props.ifPin}
              pinCode={props.pinCode}
              lang={lang}
            />
          </div>
        )}
      </div>
    </Page>
  );
});

export default Login;
