import React from 'react';
import Page from '../../containers/Page';
import {Button} from 'react-bootstrap';
import sha256 from 'js-sha256';

import './style.scss';

import lang from '../../utils/translator';

class HelloFirstName extends React.PureComponent {
  /**
   * Push data to gtm about successful order to gtm
   * @param {*} order
   * @param {*} reference
   */
  pushGuestToGTM = () => {
    const {
      person,
      salesPersonId,
      club: {club},
    } = this.props;
    const gtmObject = {
      event: 'dsp-guest',
      info: {
        guestId: sha256(`${person.get('email')}${person.get('name')}`),
        salesId: salesPersonId,
        club: club.name,
      },
    };
    if (window && window.dataLayer) {
      window.dataLayer.push(gtmObject);
    } else {
      console.log('DataLayer is unavailable!');
    }
  };

  goToClubTour = () => {
    this.pushGuestToGTM();
    this.props.goToClubTour();
  };

  render() {
    return (
      <Page nav navBarType={'nav--dark'}>
        <div className="HelloFirstName">
          <div className="vcenter-container">
            <p className="hello-text">{lang('Hello')}</p>
            <p className="hello-firstName">{this.props.firstName}</p>
            <div className="text-firstName">
              <br />
              <br />
            </div>
            <div className="hello-button">
              <Button onClick={this.goToClubTour} className="button">
                {lang("LET'S START!")}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default HelloFirstName;
