import React from 'react';
import _ from 'lodash';
import {Col} from 'react-bootstrap';
import Select from '../../../../../components/Select';
import SelectWithValue from '../../../../../components/SelectWithValue';

const CustomSelect = ({personalInfo, field, changeField, error, name, country}) => {
  if (['district', 'gender', 'country'].includes(field.name)) {
    const data = Object.keys(field.options)
      .map(item => ({value: item, name: field.options[item]}))
      .sort(({name: a}, {name: b}) => new Intl.Collator(country.toLowerCase()).compare(a, b));
    return (
      <Col style={{paddingTop: '10px'}} key={field.name} md={6} sm={6} xs={12} className={'line-form--gold__select line-form'}>
        <SelectWithValue
          name={name}
          value={personalInfo[field.name]}
          onChange={e => changeField(field.name)(e.target.value)}
          data={data}
          placeholder={field.placeholder || field.name}
        />
        {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
      </Col>
    );
  }

  const options = Array.isArray(field.options)
    ? (arr => {
        const obj = {};
        arr.forEach(value => {
          obj[value] = value;
        });
        return obj;
      })(field.options)
    : field.options;

  const data = [];
  Object.keys(options).forEach(key => {
    data.push(options[key]);
  });

  return (
    <Col key={field.name} md={6} sm={6} xs={12} className={'line-form--gold__select line-form'}>
      <Select name={name} value={personalInfo[field.name]} onChange={e => changeField(field.name)(e.target.value)} data={data} placeholder={field.placeholder} />
      {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
    </Col>
  );
};

export default CustomSelect;
