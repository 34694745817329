import moment from 'moment';
import React, {Component} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {NO_CLASSES_FOUND} from '../../App/errorConstatns';
import Loader from '../../components/Loader';
import {filterAccordingToCurrentWeek, calendarFormatData} from './helpers';
import {getAfternoonLessons, getCategories, getEveningLessons, getLessonsByHallId, getMorningLessons, getPrograms} from './Lessons';
import './style.scss';

/*
 * !IMPORTANT!
 * @TODO: Remove all code contained in this container (and imported modules) from the Calendar container!
 * We have two containers with the same functionality at the moment!
 * The Calendar container should be just a page which includes the CalendarView container
 * */

class Calendar extends Component {
  state = {
    selected: this.props.selected || [],
    selectedCategory: [],
    selectedHall: -1,
    timePeriods: [],
    selectedTimePeriod: 'all',
    showModal: false,
    showOptionsModal: false,
    detail: null,
  };

  componentDidMount() {
    const {calendarData, getCalendarData, weekNumber} = this.props;
    if (calendarData.length === 0) {
      getCalendarData(weekNumber);
    }
  }

  render() {
    const {calendarData, loader, lang, renderNavButtons} = this.props;
    return (
      (loader && <Loader />) || (
        <div className="schedule__holder">
          <div className="schedule">
            {(calendarData.length > 0 && (
              <div>
                {this.renderCalendar()}
                {this.renderLessonModal()}
                {this.renderOptionsModal()}
                {renderNavButtons && renderNavButtons()}
              </div>
            )) || (
              <div className="memberships">
                <h1 className="warning">{lang(NO_CLASSES_FOUND)}</h1>
              </div>
            )}
          </div>
        </div>
      )
    );
  }

  renderLessonModal = () => {
    const {detail, showModal} = this.state;
    const {language, monday, calendarType, addToPlan, removeFromPlan, lang, weekNumber, plan} = this.props;
    return (
      detail &&
      showModal && (
        <Modal show={showModal} onHide={this.closeModal} className="lessonDetailModal">
          <Modal.Header>
            <Modal.Title>{language === 'en' ? detail.name : detail.name}</Modal.Title>
            {detail.startTime} {'|'} {this.parseTime(detail.endTime) - this.parseTime(detail.startTime)} {'|'}{' '}
            {moment(monday)
              .add(detail.isoWeekday - 1, 'days')
              .format('MMM DD, YYYY')
              .toLocaleUpperCase()}
          </Modal.Header>
          <Modal.Body>
            <img src={detail.image} alt="detail" />
            <div>{detail.description}</div>
          </Modal.Body>
          <Modal.Footer className="lessonDetailModal__footer">
            <Button
              className="lessonDetailModal__footer__button"
              onClick={() => {
                this.closeModal();
                if (calendarType === 'twoweeksplan') this.findLessonFromPlan(plan, detail) < 0 ? addToPlan(detail, weekNumber) : removeFromPlan(detail, weekNumber);
              }}>
              {calendarType === 'twoweeksplan' ? (this.findLessonFromPlan(plan, detail) < 0 ? lang('Add to plan') : lang('Remove from plan')) : lang('Close')}
            </Button>
          </Modal.Footer>
        </Modal>
      )
    );
  };

  renderOptionsModal = () => {
    const {calendarData, monday, lang} = this.props;
    const {showOptionsModal} = this.state;
    const currentWeekCalendarData = filterAccordingToCurrentWeek(calendarData, monday);
    return (
      <Modal show={showOptionsModal} onHide={this.closeOptionModal}>
        <Modal.Header>
          <Modal.Title>{lang('title')}</Modal.Title>
          <Button onClick={this.resetSelected}>{lang('Reset selected')}</Button>
        </Modal.Header>
        <Modal.Body>
          <h3>{lang('Choose activity')}</h3>
          {getCategories(currentWeekCalendarData).map((category, index) => (
            <h4 key={index} onClick={this.selectCategoy(category.categoryId)} style={{color: `#${category.color}`}}>
              {category.categoryName}
            </h4>
          ))}
          <h3>{lang('Choose lection')}</h3>
          {getPrograms(currentWeekCalendarData).map((program, index) => (
            <h4 key={index} onClick={this.selectProgram(program.classStructureId)} style={{color: `#${program.color}`}}>
              {program.name}
            </h4>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeOptionModal}>{lang('OK')}</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderHeader = () => {
    const {monday, lang} = this.props;
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
      <div>
        <Row>
          <Col md={12}>
            <table className="scheduleHeader">
              <tbody>
                <tr>
                  <td style={{width: '50px'}} />
                  {days.map((day, i) => (
                    <td key={i}>
                      <p className={'scheduleHeader__date'}>
                        {moment(monday)
                          .add(i, 'days')
                          .format('DD MMM')}
                      </p>
                      <p className={'scheduleHeader__day'}>{lang(day)}</p>
                    </td>
                  ))}
                  <td style={{width: '50px'}} />
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  };

  renderByPeriod = (period, calendarData) => {
    const data = this.isInHall(calendarData);
    if (period === 'morning') return getMorningLessons(data);
    if (period === 'afternoon') return getAfternoonLessons(data);
    return getEveningLessons(data);
  };

  isInHall = data => {
    const {selectedHall} = this.state;
    if (selectedHall !== -1) return getLessonsByHallId(selectedHall)(data);
    return data;
  };

  renderCalendar = () => {
    const {timePeriods} = this.props;

    return (
      <Row>
        <Col md={12} className="calendar__main">
          {this.renderHeader()}
          {timePeriods.map((period, i) => (
            <table className="schedule" key={`table-${period}-${i}`}>
              <tbody>{this.renderTableRows(period, i)}</tbody>
              <tfoot>
                <tr>
                  {i !== 2 && (
                    <td colSpan="9" style={{padding: '0 50px'}}>
                      <hr />
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          ))}
        </Col>
      </Row>
    );
  };

  renderTableRows = (period, dayTime) => {
    const {calendarData, monday, timePeriods, lang} = this.props;
    const currentWeekCalendarData = filterAccordingToCurrentWeek(calendarData, monday);
    const rows = calendarFormatData(this.renderByPeriod(period, currentWeekCalendarData));

    return rows.map((row, i) => (
      <tr key={`row-${i}-${dayTime}`}>
        {i === 0 && (
          <td rowSpan={rows.length} className="schedule__sidebar">
            <p className={timePeriods[dayTime]}>{lang(timePeriods[dayTime])}</p>
          </td>
        )}
        {this.renderTableData(row, dayTime)}
        {<td rowSpan={rows.length} className="schedule__sidebar" />}
      </tr>
    ));
  };

  renderTableData = (lessons, dayTime) => {
    const {calendarType, language} = this.props;
    const {selected} = this.state;

    return lessons.map((lesson, i) => {
      if (!lesson) {
        return <td key={`column-${i}-${dayTime}`}></td>;
      }
      return (
        <td
          key={`column-${lesson.id}`}
          style={{
            opacity: calendarType === 'twoweeksplan' ? this.isVisiblePlan(lesson) : this.isVisible(lesson.classStructureId, lesson.categoryId),
          }}>
          <div
            className="calendar__block"
            onClick={e => {
              if (calendarType === 'twoweeksplan' || ((!calendarType || calendarType !== 'twoweeksplan') && selected.indexOf(lesson.classStructureId) < 0))
                this.showDetail(lesson)(e);
              if (!calendarType || calendarType !== 'twoweeksplan') this.selectLesson(lesson)(e);
            }}>
            <div
              className="calendar__block__colortag"
              style={{
                background: `${String(lesson.color).includes('#') ? lesson.color : '#' + lesson.color}`,
              }}
            />
            <p className="calendar__block__time">{lesson.startTime}</p>
            <p className="calendar__block__name">{language === 'en' ? lesson.name : lesson.name}</p>
          </div>
        </td>
      );
    });
  };

  closeModal = () => this.setState({showModal: false});

  closeOptionModal = () => this.setState({showOptionsModal: false});

  showDetail = lesson => () => this.setState({showModal: true, detail: lesson});

  isVisible = (id, category) => {
    const {selected, selectedCategory} = this.state;
    if ((selected.length === 0 && selectedCategory.length === 0) || selected.find(x => x === id) || selectedCategory.find(x => x === category)) return 1;

    return 0.3;
  };

  selectLesson = lesson => () => {
    const {selected} = this.state;
    if (selected.length > 0)
      if (selected.find(x => x === lesson.classStructureId)) {
        selected.splice(selected.indexOf(lesson.classStructureId), 1);
        return this.setState({selected});
      }
    const selectedSliced = selected.slice();
    selectedSliced.push(lesson.classStructureId);
    this.setState({selected: selectedSliced});
  };

  isVisiblePlan = lesson => {
    const {plan} = this.props;
    return this.findLessonFromPlan(plan, lesson) < 0 ? 0.3 : 1;
  };

  findLessonFromPlan = (arr, lesson) => {
    const {weekNumber} = this.props;
    for (let i = 0; i < arr.length; i++)
      if (
        arr[i].classStructureId === lesson.classStructureId &&
        arr[i].weekNumber === weekNumber &&
        arr[i].isoWeekDay === lesson.isoWeekDay &&
        arr[i].startTime === lesson.startTime
      )
        return i;
    return -1;
  };

  selectCategoy = id => () => {
    const {selectedCategory} = this.state;
    if (selectedCategory.length > 0)
      if (selectedCategory.find(x => x === id)) {
        selectedCategory.splice(selectedCategory.indexOf(id), 1);
        return this.setState({
          selectedCategory: selectedCategory,
        });
      }

    const selected = selectedCategory.slice();
    selected.push(id);
    this.setState({selectedCategory: selected});
  };

  selectProgram = id => () => {
    const {selected} = this.props;
    if (selected.length > 0)
      if (selected.find(x => x === id)) {
        selected.splice(selected.indexOf(id), 1);
        return this.setState({selected: selected});
      }

    const selectedSliced = selected.slice();
    selectedSliced.push(id);
    this.setState({selected: selectedSliced});
  };

  resetSelected = () => this.setState({selected: [], selectedCategory: []});

  parseTime = s => {
    const c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  };
}

export default Calendar;
