import React from 'react';
import _ from 'lodash';
import {Col} from 'react-bootstrap';
import FloatingLabels from '../../../../../components/FloatingLabels';

const NifPassportInput = ({personalInfo, field, changeField, error, name}) => (
  <Col key={field.name} md={6} sm={6} xs={12} className={'line-form--gold line-form'}>
    {personalInfo.isCitizen && (
      <span>
        <FloatingLabels
          type="text"
          name={name}
          placeholder={field.placeholder}
          value={personalInfo[field.name]}
          onChange={e => {
            const toSetEmpty = field.name === 'passport' ? 'nif' : 'passport';
            changeField(toSetEmpty)('');
            changeField(field.name)(e.target.value);
          }}
          style={{color: '#a3a3a3'}}
        />
        <span
          style={{position: 'absolute', top: 10, right: 20, color: '#b3a26c', textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            changeField('passport')('');
            changeField('isCitizen')(false);
          }}>
          {field.alternative}
        </span>
      </span>
    )}
    {!personalInfo.isCitizen && (
      <span>
        <FloatingLabels
          type="text"
          name={name}
          placeholder={field.placeholder}
          value={personalInfo[field.name]}
          onChange={e => {
            const toSetEmpty = field.name === 'passport' ? 'nif' : 'passport';
            changeField(toSetEmpty)('');
            changeField(field.name)(e.target.value);
          }}
          style={{color: '#a3a3a3'}}
        />
        <span
          style={{position: 'absolute', top: 10, right: 20, color: '#b3a26c', textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            changeField('nif')('');
            changeField('isCitizen')(true);
          }}>
          {field.alternative}
        </span>
      </span>
    )}
    {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
  </Col>
);

export default NifPassportInput;
