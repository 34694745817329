import React, {PureComponent} from 'react';
import Hamburger from '../../containers/Hamburger';
import Languages from '../Languages/index';
import './style.scss';

class Nav extends PureComponent {
  state = {hamburguerActive: false};

  menuToggle() {
    this.props.rightMenuToggle(!this.props.rightMenuOpen, this.props.zone, this.props.verify, this.props.verifyPersonReset);
  }

  menuToggleLeft() {
    this.props.leftMenuToggle(!this.props.leftMenuOpen, this.props.app);
    let hamburguerActive = this.state.hamburguerActive;
    this.setState({hamburguerActive: !hamburguerActive});
  }

  render() {
    const navBarType = this.props.app === 'DSP' ? 'nav--dark' : 'nav--gold';
    if (this.props.app === 'DSP')
      return (
        <div>
          <div className={'nav ' + navBarType}>
            <Hamburger menuOpen={this.menuToggleLeft.bind(this)} active={this.state.hamburguerActive} />
            {this.props.children}

            <Languages languages={this.props.languages} language={this.props.language} changeLang={this.props.changeLang} className="languages--light" />

            <div className={`nav__logo nav__logo-right nav__logo--${this.props.rightMenuOpen}`} onClick={this.menuToggle.bind(this)} />
          </div>
        </div>
      );
    else
      return (
        <div>
          <div className={`nav ${navBarType} nav--special`}>
            <div className={`nav-logo-left nav-logo-left--${this.props.leftMenuOpen}`} onClick={this.menuToggleLeft.bind(this)} />
            <div className="nav-logo-mobile" onClick={this.menuToggleLeft.bind(this)} />
            <div className="nav__journeyHub">
              <div>{this.props.lang('Journey Hub')}</div>
            </div>

            <Languages languages={this.props.languages} language={this.props.language} changeLang={this.props.changeLang} className="languages--light" />

            <div className="nav__person">
              <div className="photo" />
              <div>
                <div>
                  <h4>{this.props.salespersonsName || 'No name found'}</h4>
                </div>

                <div onClick={this.props.logout()}>
                  <h5 className="nav__logOut">{this.props.lang('Log out')}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default Nav;
