import React from 'react';
import _ from 'lodash';
import {Col} from 'react-bootstrap';
import FloatingLabels from '../../../../../components/FloatingLabels';

const TextInput = ({personalInfo, field, changeField, error, name, fullSize}) => (
  <Col key={field.name} md={fullSize ? 12 : 6} sm={fullSize ? 12 : 6} xs={12} className={'line-form--gold line-form'}>
    <FloatingLabels
      type="text"
      name={name}
      placeholder={field.placeholder}
      value={personalInfo[field.name]}
      onChange={e => changeField(field.name)(e.target.value)}
      style={{color: '#a3a3a3'}}
    />

    {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
  </Col>
);

export default TextInput;
