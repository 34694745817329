import React from 'react';
import {Col} from 'react-bootstrap';
import './styles.scss';

const Textarea = ({personalInfo, field, changeField, name, fullSize}) => {
  return (
    <Col key={field.name} md={fullSize ? 12 : 6} sm={fullSize ? 12 : 6} xs={12} className={'line-form--gold line-form'}>
      <textarea
        className={'textarea-field'}
        type="text"
        name={name}
        placeholder={field.placeholder}
        value={personalInfo[field.name]}
        onChange={e => changeField(field.name)(e.target.value)}
      />
    </Col>
  );
};

export default Textarea;
