import {connect} from 'react-redux';
import WelcomeIframeComponent from './WelcomeIframe';
import {connectLanguage} from '../../utils/connectLanguage';
import {getClubUrl, selectRegion} from '../../App/selectors';

import {goToTellUsMore} from './actions.js';

const mapStateToProps = state => {
  return {
    clubUrl: getClubUrl(state),
    region: selectRegion(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToTellUsMore: () => dispatch(goToTellUsMore()),
  };
};

const Welcome = connect(mapStateToProps, mapDispatchToProps)(WelcomeIframeComponent);
export default connectLanguage(Welcome, connect);
