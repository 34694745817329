import {createSelector} from 'reselect';
import {getPerson, getLoginForm, getNotes, getClub} from '../../App/selectors';
import {personChangeField} from '../../Actions/index';
import {store} from '../../App/store';

export const selectSelectedMembership = createSelector([state => state.getIn(['contract', 'selectedMembership'])], selectedMembership => selectedMembership);

export const selectPersonalInfo = createSelector([getPerson, getLoginForm, getClub], (person, login, club) => personalInfo(person.toJS(), login.toJS(), club.toJS()));

function personalInfo(person, login, club) {
  if (!person.country) store.dispatch(personChangeField('country', login.region));

  person.club = club;
  return person;
}

export const selectEmergencyContacts = createSelector([getPerson], person => emergencyContacts(person.toJS()));

function emergencyContacts(person) {
  return [
    {name: 'contactsInEmergencieName', text: 'Contacts in emergencies (name)', type: 'text', value: person.contactsInEmergencieName, required: false},
    {name: 'contactsInEmergenciePhone', text: 'Contacts in emergencies (phone)', type: 'text', value: person.contactsInEmergenciePhone, required: false},
    {name: 'contactsInEmergencieName2', text: 'Contacts in emergencies (name)', type: 'text', value: person.contactsInEmergencieName2, required: false},
    {name: 'contactsInEmergenciePhone2', text: 'Contacts in emergencies (phone)', type: 'text', value: person.contactsInEmergenciePhone2, required: false},
  ];
}

export const selectGdprOption = createSelector([getPerson], person => person.toJS().gdpr);

export const selectPayments = createSelector([getPerson], person => payments(person.toJS()));

function payments(person) {
  return [
    {name: 'memberIban', text: 'Account number - IBAN', placeholder: 'Account number - IBAN', type: 'input', value: person.memberIban, required: false},
    {name: 'memberSwift', text: 'BIC SWIFT', placeholder: 'BIC SWIFT', type: 'input', value: person.memberSwift, required: false},
  ];
}

export const selectNotes = createSelector([getNotes], notes => notes);
