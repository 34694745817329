import {browserHistory} from 'react-router';
import {CHOOSE_MEMBERSHIP} from './constants';
import {PACKAGES_PAGE} from '../../App/constants';

function chooseMembership(membershipId) {
  return dispatch => {
    dispatch({type: CHOOSE_MEMBERSHIP, membershipId});
    browserHistory.push(PACKAGES_PAGE);
  };
}

export {chooseMembership};
