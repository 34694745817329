import {connect} from 'react-redux';
import TwoWeeksPlanComponent from './TwoWeeksPlan';
import {connectLanguage} from '../../utils/connectLanguage';

import {componentNextPage} from './actions';
import {selectPlan, selectWeekNumber} from './selectors';
import {selectLoader, selectApp, selectPerson, getLang} from '../../App/selectors';
import {setNumber} from '../CalendarView/actions';
import {notify} from '../../api/actions';

const mapStateToProps = state => {
  return {
    plan: selectPlan(state),
    loader: selectLoader(state),
    weekNumber: selectWeekNumber(state),
    app: selectApp(state),
    language: getLang(state),
    person: selectPerson(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNext: number => dispatch(setNumber(number + 7)),
    loadPrevious: number => dispatch(setNumber(number - 7)),
    nextPage: (app, type, plan, person, monday, langId) => componentNextPage(dispatch, app, type, plan, person, monday, langId),
    notifyNewMember: reference => dispatch(notify(reference)),
  };
};

const TwoWeeksPlan = connect(mapStateToProps, mapDispatchToProps)(TwoWeeksPlanComponent);
export default connectLanguage(TwoWeeksPlan, connect);
