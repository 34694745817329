/* eslint-disable react/jsx-no-literals */
import moment from 'moment';
import React from 'react';
import {CLUB_TOUR_EVENTS_PAGE} from '../../../../App/constants.js';
import {REQUESTED_EVENT_NOT_FOUND} from '../../../../App/errorConstatns';
import ClubTourHeader from '../../../../components/ClubTourHeader';
import ClubTourNormalizedHtml from '../../../../components/ClubTourNormalizedHtml';
import ClubTourSubMenu from '../../../../components/ClubTourSubMenu';
import ClubTourWrapper from '../../../../components/ClubTourWrapper';
import ClubTourWrapperInner from '../../../../components/ClubTourWrapperInner';
import lang from '../../../../utils/translator';
import Page from '../../../Page';
import './style.scss';

const Detail = React.memo(({event: currentEvent}) => {
  const event = currentEvent.content.find(c => c.component === 'EventDetail');

  const {title, subtitle, image, startDate, endDate, startTime, endTime, text} = event.data;

  return (
    <Page nav navBarType={'nav--dark'}>
      <div className="club-tour-news-detail">
        <ClubTourSubMenu aboveContent={false} current={CLUB_TOUR_EVENTS_PAGE} currentClickable lang={lang} />
        {event ? (
          <ClubTourWrapper>
            <ClubTourWrapperInner>
              <ClubTourHeader title={title} subtitle={subtitle} banner={image} />
            </ClubTourWrapperInner>
            <ClubTourWrapperInner>
              <ClubTourNormalizedHtml html={text} />
              <div className="evet-detail-date">
                <strong>{'Date'}:</strong> {moment(startDate).format('MMM DD, YYYY')} {startTime} - {moment(endDate).format('MMM DD, YYYY')} {endTime}
              </div>
            </ClubTourWrapperInner>
          </ClubTourWrapper>
        ) : (
          <div>{lang(REQUESTED_EVENT_NOT_FOUND)}</div>
        )}
      </div>
    </Page>
  );
});

export default Detail;
