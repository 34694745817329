import {store} from '../../App/store';
import * as selectors from '../../App/selectors';

export const getInObj = (obj, path, empty = undefined) => {
  if (obj) {
    if (path[0] !== undefined) return getInObj(obj[path.splice(0, 1)], path, empty);
    else return obj;
  } else return empty;
};

export const transformData = (data, type) => {
  switch (type) {
    case 'gender':
      switch (selectors.selectLang(store.getState())) {
        case 'pt':
        case 'es':
          return {M: 'Masculino', F: 'Feminino'}[data] || data;
        case 'ca':
          return {M: 'Home', F: 'Dona'}[data] || data;
        case 'de':
          return {M: 'Männlich', F: 'Weiblich'}[data] || data;
        case 'fr':
          return {M: 'Mâle', F: 'Femelle'}[data] || data;
        case 'gr':
          return {M: 'αρσενικός', F: 'θηλυκός'}[data] || data;
        case 'en':
        case 'pl':
        default:
          return {M: 'Male', F: 'Female'}[data] || data;
      }
    case 'birthDate':
      return data ? new Date(data).toLocaleDateString() : data;
    case 'period':
      switch (selectors.selectLang(store.getState())) {
        case 'pt':
        case 'es':
          return `${data} meses`;
        case 'ca':
          return `${data} mesos`;
        case 'pl':
          return `${data} miesięcy`;
        case 'de':
          return `${data} Monate`;
        case 'fr':
          return `${data} mois`;
        case 'gr':
          return `${data} μήνες`;
        case 'en':
        default:
          return `${data} months`;
      }
    case 'district':
      switch (selectors.selectRegion(store.getState()).id) {
        case 8:
          return (
            {
              '0': 'DESCONHECIDO',
              '02': 'BRAGA',
              '03': 'PORTO',
              '04': 'VILA REAL',
              '05': 'BRAGANÇA',
              '06': 'AVEIRO',
              '07': 'VISEU',
              '08': 'GUARDA',
              '09': 'COIMBRA',
              '1': 'PORTUGAL',
              '10': 'CASTELO BRANCO',
              '11': 'LEIRIA',
              '12': 'LISBOA',
              '13': 'SANTARÉM',
              '14': 'PORTALEGRE',
              '15': 'ÉVORA',
              '16': 'SETÚBAL',
              '17': 'BEJA',
              '18': 'FARO',
            }[data] || data
          );
        case 9:
          return (
            {
              '0': 'DESCONOCIDO',
              '01': 'ÁLAVA',
              '02': 'ALBACETE',
              '03': 'ALICANTE',
              '04': 'ALMERÍA',
              '05': 'ÁVILA',
              '06': 'BADAJOZ',
              '07': 'BALEARES',
              '08': 'BARCELONA',
              '09': 'BURGOS',
              '100': 'NUEVA',
              '11': 'CÁDIZ',
              '12': 'CASTELLÓN',
              '13': 'CIUDAD REAL',
              '14': 'CÓRDOBA',
              '15': 'LA CORUÑA',
              '16': 'CUENCA',
              '17': 'GIRONA',
              '18': 'GRANADA',
              '19': 'GUADALAJARA',
              '20': 'GUIPUZCOA',
              '21': 'HUELVA',
              '22': 'HUESCA',
              '23': 'JAÉN',
              '24': 'LEÓN',
              '25': 'LLEIDA',
              '26': 'LOGROÑO',
              '27': 'LUGO',
              '28': 'MADRID',
              '29': 'MÁLAGA',
              '30': 'MURCIA',
              '31': 'NAVARRA',
              '32': 'ORENSE',
              '33': 'ASTURIAS',
              '34': 'PALENCIA',
              '35': 'LAS PALMAS',
              '36': 'PONTEVEDRA',
              '37': 'SALAMANCA',
              '38': 'STA. CRUZ DE TENERIFE',
              '39': 'SANTANDER (CANTABRIA)',
              '40': 'SEGOVIA',
              '41': 'SEVILLA',
              '42': 'SORIA',
              '43': 'TARRAGONA',
              '44': 'TERUEL',
              '45': 'TOLEDO',
              '46': 'VALENCIA',
              '47': 'VALLADOLID',
              '48': 'VIZCAYA',
              '49': 'ZAMORA',
              '50': 'ZARAGOZA',
              '51': 'CEUTA',
              '52': 'MELILLA',
              '99': 'DESCONOCIDA',
            }[data] || data
          );
        default:
          console.log('Region is undefined');
          return;
      }
    default:
      return data;
  }
};
