import React from 'react';
import _ from 'lodash';
import {Col} from 'react-bootstrap';
import DP from '../../../../../components/DatePicker';

const DatePicker = ({personalInfo, field, changeField, error, name, lang}) => (
  <Col key={name} md={6} sm={6} xs={12} className={'line-form--gold__select line-form'}>
    <DP
      lang={lang}
      placeholder={field.placeholder || name}
      id={name}
      value={personalInfo[name] ? new Date(personalInfo[name]) : new Date()}
      onChange={e => changeField(name)(e.time)}
    />
    {!_.isEmpty(error) ? <span className="c-salesProcess__field-error">{error[0]}</span> : ''}
  </Col>
);

export default DatePicker;
