export const HOME_PAGE = '/';
export const MEMBERSHIP_PAGE = '/memberships';
export const PACKAGES_PAGE = '/packages';
export const SUMMARY_PAGE = '/summary';
export const CONTACT_INFORMATION_PAGE = '/contactInformation';
export const OPTIONS_PAGE = '/options';
export const CONTRACT_PAGE = '/contract';
export const LOGIN_PAGE = '/login';
export const HELLO_PAGE = '/hello';
export const TELLUSMORE_PAGE = '/tellusmore';
export const WELCOME_PAGE = '/welcome';
export const THANKYOU_PAGE = '/thankyou';
export const TEST_PAGE = '/test';
export const CLUB_TOUR_WELCOME_PAGE = '/club-tour/welcome';
export const CLUB_TOUR_NEWS_PAGE = '/club-tour/blog';
export const CLUB_TOUR_NEWS_DETAIL_PAGE = '/club-tour/blog/:newsId';
export const CLUB_TOUR_SERVICES_DETAIL_PAGE = '/club-tour/services/:serviceId';
export const CLUB_TOUR_EVENTS_PAGE = '/club-tour/events';
export const CLUB_TOUR_EVENTS_DETAIL_PAGE = '/club-tour/events/:eventId';
export const CLUB_TOUR_CLASSES_SCHEDULE_PAGE = '/club-tour/classes-schedule';
export const TWO_WEEKS_PLAN_PAGE = '/twoweeksplan';

export const ENJOY_PAGE = '/enjoy';
export const PERSONAL_INFO_PAGE = '/personalinfo';
export const CONTACTS_PAGE = '/contacts';

export const RESET_PERSON = 'App/Common::RESET_PERSON';
export const VERIFY_PERSON_RESET = 'App/Common/VERIFY_PERSON_RESET';
export const CONFIRM_LOADER = 'App/Common::CONFIRM_LOADER';
/**
 * Action constant.
 * Called when transitioning router zone (customer/salesperson and vice versa)
 */
export const ROUTER_ZONE_TRANSITION = 'App/Common/ROUTER_ZONE_TRANSITION';
export const ZONE_CUSTOMER = 'customer';
export const ZONE_SALESPERSON = 'salesperson';
