export const contractReducer = {
  contract: {
    selectedMembership: null,
    selectedPackage: null,
    selectedCampaign: null,
    selectedOffer: null,
    photo: null,
    signature: null,
    signatureSalesperson: null,
    promo: '',
  },
  contractInfo: {
    membershipPrice: null,
    monthlyPrice: null,
    starterPackPrice: null,
    totalPrice: null,
  },
  checkout: null,
};
