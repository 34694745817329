import axios from 'axios';
import {store} from '../../App/store';
import {createCustomError} from '../../utils/errors';
import translator from '../../utils/translator';

const url = 'https://sandbox.memberjourneyhub.com/holmesplace/api/backoffice/schedule';
const timeout = '50000';

const CalendarCloud = axios.create({
  baseURL: url,
  headers: {'Content-Type': 'application/json'},
  timeout,
});

CalendarCloud.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers.common['Authorization'] = `Bearer ${store.getState().getIn(['mainReducer', 'accessToken'])}`;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

CalendarCloud.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.message === `timeout of ${timeout}ms exceeded`) {
      const TimeoutError = createCustomError('Timeout', translator('Server was not able to complete response in time'));

      return Promise.reject(new TimeoutError());
    }

    if (error.message === 'Network Error') {
      const NetworkError = createCustomError('Network Error', translator('Something is wrong with your network, please check your internet connection'));

      return Promise.reject(new NetworkError());
    }

    if (error.response.status === 401) {
      const Unauthorized = createCustomError('Unauthorized');
      return Promise.reject(new Unauthorized());
    }

    if (error.response.data && error.response.data.name === 'Unit4Connector') {
      const errorMsg = error.response.data.unit4 ? error.response.data.unit4.msg : translator('Undefined Unit4 error');

      const Unit4Error = createCustomError('Unit4Error', errorMsg);
      return Promise.reject(new Unit4Error());
    }

    return Promise.reject(error);
  }
);

export default CalendarCloud;
