import React from 'react';
import './style.scss';

/**
 * A container that transforms layout and style of imported html into format usable in ClubTour
 * Formats the html to standards expected by ClubTour pages
 */
const ClubTourNormalizedHtml = React.memo(({html}) => <div className="club-tour-normalized-html" dangerouslySetInnerHTML={{__html: html}} />);

export default ClubTourNormalizedHtml;
