import {createSelector} from 'reselect';
import {getClubInfo} from '../../../App/selectors';

export const selectWelcomeText = createSelector([state => getClubInfo(state).get('welcomText')], welcomText => (welcomText ? welcomText.replace(/\n/g, '<br>') : null));

export const selectVideoDefault = createSelector([state => getClubInfo(state).get('clubTour')], video => video);

export const selectPhotos = createSelector([state => getClubInfo(state).get('gallery')], gallery =>
  gallery ? gallery.map(photoId => ({src: 'http://holmesplace.pt/file.php?id=' + photoId, title: ''})).toJS() : null
);

export const selectPhotoCover = createSelector([state => getClubInfo(state).get('homepageImage')], cover => ({src: cover, title: ''}));

/**
 * @TODO: use some central selector for this
 */
export const selectClubName = createSelector([state => getClubInfo(state).get('name')], name => name);

export const selectServices = state => console.warn('Services are not implemented and probably will be removed from the page');
