import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import EnjoyComponent from './EnjoyTheJourney';
import {connectLanguage} from '../../utils/connectLanguage';

const mapDispatchToProps = dispatch => {
  return {
    goToWelcome: () => {
      browserHistory.push('/welcome');
    },
  };
};

const EnjoyTheJourney = connect(mapDispatchToProps)(EnjoyComponent);
export default connectLanguage(EnjoyTheJourney, connect);
