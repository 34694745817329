import {connect} from 'react-redux';
import {connectLanguage} from '../../../../utils/connectLanguage';
import {selectEventDetail} from '../selectors.js';
import DetailComponent from './Detail.js';

const mapStateToProps = (state, ownProps) => {
  return {
    event: selectEventDetail(state, ownProps),
  };
};

const Detail = connect(mapStateToProps, null)(DetailComponent);
export default connectLanguage(Detail, connect);
