import {browserHistory} from 'react-router';
import {showError} from '../../Actions/index';
import {API_TWOWEEKSPLAN_INSERT} from '../../api/constants';
import {PERSONAL_INFO_PAGE, TELLUSMORE_PAGE, THANKYOU_PAGE} from '../../App/constants';

export const componentNextPage = (dispatch, app, type, plan, person, monday, langId) => {
  if (type === 'skip' || (type !== 'skip' && plan.length <= 0)) app === 'DSP' ? browserHistory.push(THANKYOU_PAGE) : browserHistory.push(PERSONAL_INFO_PAGE);
  else {
    if (!person.name || !person.surname || !person.birthDate || !person.mobile || !person.email) {
      browserHistory.push(TELLUSMORE_PAGE);
      return dispatch(showError('All fields are required'));
    }
    dispatch(twoWeeksPlanInsert(plan, person, monday, langId));
  }
};

export const twoWeeksPlanInsert = (plan, person, monday, langId) => {
  return {type: API_TWOWEEKSPLAN_INSERT, plan, person, monday, langId};
};
