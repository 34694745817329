import React, {Component} from 'react';

import SubMenuGrey from '../../components/SubMenuGrey';
import Item from '../../components/SubMenuGrey/Item';
import {Link} from 'react-router';
import {MEMBERSHIP_PAGE, PACKAGES_PAGE} from '../../App/constants.js';

/**
 * @props
 * string current current url
 */
class MembershipsSubMenu extends Component {
  drawItem(itemLink, itemLabel) {
    if (itemLink === this.props.current)
      return (
        <Item suffix={'-memberships'} isCurrent>
          {itemLabel}
        </Item>
      );

    return (
      <Link to={itemLink}>
        <Item suffix={'-memberships'}>{itemLabel}</Item>
      </Link>
    );
  }
  render() {
    return (
      <SubMenuGrey suffix={'-memberships'}>
        {this.props.membershipsExist && this.drawItem(MEMBERSHIP_PAGE, this.props.lang('memberships'))}
        {this.props.packagesExist && this.drawItem(PACKAGES_PAGE, this.props.lang('starterpacks'))}
      </SubMenuGrey>
    );
  }
}

export default MembershipsSubMenu;
