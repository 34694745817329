import {VERIFY_PERSON_RESET, ROUTER_ZONE_TRANSITION} from './constants.js';

/**
 *
 */
const verifyPersonReset = () => {
  return {
    type: VERIFY_PERSON_RESET,
  };
};

/**
 * Transition router zone.
 */
const routerZoneTransition = zoneLast => {
  return {
    type: ROUTER_ZONE_TRANSITION,
    zoneLast,
  };
};

export {verifyPersonReset, routerZoneTransition};
