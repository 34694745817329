import {List} from 'immutable';

export const personReducer = {
  person: {
    id: null,
    name: null,
    surname: null,
    email: null,
    birthDate: null,
    gender: null,
    city: null,
    street: null,
    postalCode: null,
    mobile: null,
    memberships: List(),
    phoneCode: null,
    isCitizen: true,
    payWithCard: null,
    privacyPolicy: null,
    gdpr: null,
  },
  verifyPerson: {
    verify: false,
    modal: false,
    action: '',
    username: '',
    password: '',
    pinCode: '',
    ifPin: false,
  },
  duplicatePerson: {
    showModal: false,
    duplicate: false,
    selected: null,
    duplicates: List(),
    loader: false,
  },
};
