import {fromJS} from 'immutable';
import {contractReducer} from './contractreducer';
import {mainReducer} from './mainreducer';
import {personReducer} from './personreducer';
const initialState = fromJS({
  mainReducer,
  contractReducer,
  personReducer,
});

export default initialState;
