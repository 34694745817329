import {createSelector} from 'reselect';
import {getServices} from '../../App/selectors';
/**
 * Using mock data now.
 * use state.get('user_info') to switch to real store
 */
const getInfoRoot = state => state.get('user_info');

const getLoader = state => state.get('loader');

export const selectBenefits = state =>
  getInfoRoot(state)
    .get('benefits')
    .toJS();
export const selectServices = state =>
  getInfoRoot(state)
    .get('services')
    .toJS();
export const selectWelcomeText = state => getInfoRoot(state).getIn(['welcomePage', 'text']);
export const selectServicesText = state => getInfoRoot(state).getIn(['servicesPage', 'text']);
export const selectVideos = state =>
  getInfoRoot(state)
    .get('video')
    .toJS();
export const selectVideoDefault = state => getInfoRoot(state).getIn(['video', 'default']);
export const selectPhotos = state =>
  getInfoRoot(state)
    .get('photos')
    .toJS();
export const selectServiceBox = createSelector([getServices], selectServiceBox => (selectServiceBox ? selectServiceBox.toJS() : null));

export const selectLoader = createSelector([getLoader], loader => loader);
