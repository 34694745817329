import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

class MembershipInfo extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col md={12}>
            <h3 className="text-gold contract__subheading">{this.props.lang('PERSONAL INFORMATION')}</h3>
          </Col>
        </Row>
        {this.props.children}
      </div>
    );
  }
}

export default MembershipInfo;
