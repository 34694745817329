export default {
  '2 week plan': 'Plano de 2 semanas',
  '2 weeks plan': 'Plano de 2 semanas',
  'Account number - IBAN': 'número de conta - iban',
  Activate: 'Ativar',
  'Add to plan': 'Adicionar ao plano',
  ADRESS: 'MORADA',
  afternoon: 'Tarde',
  'All fields are required': 'Todos os campos são obrigatórios',
  'Are you ready to start your journey?': 'Está preparado/a para começar?',
  'are you sure you want select prospect, this person has selected membership': 'de certeza que quer selecionar prospect? Esta pessoa selecionou uma adesão',
  'are you sure you want to continue': 'tem a certeza que quer continuar?',
  BACK: 'Voltar',
  'BIC SWIFT': 'swift',
  'birth date': 'data de nascimento',
  BIRTHDATE: 'DATA DE NASCIMENTO',
  Cancel: 'Cancelar',
  CANCEL: 'Cancelar',
  CATEGORY: 'SERVIÇO CONTRATADO',
  Choose: 'Escolher',
  city: 'cidade',
  City: 'cidade',
  CITY: 'LOCALIDADE',
  classes: 'Aulas',
  'classes schedule': 'Mapa de aulas',
  close: 'fechar',
  Close: 'Fechar',
  Club: 'Clube',
  CLUB: 'CLUBE',
  'club tour': 'club tour',
  Confirm: 'confirmar',
  'confirm as prospect': 'Confirmar como prospect',
  'Contacts in emergencies (name)': 'contactos de emergência (nome)',
  'Contacts in emergencies (phone)': 'contactos de emergência (telefone)',
  continue: 'continuar',
  contract: 'Contrato',
  'Contract information': 'informação contrato',
  country: 'país',
  COUNTRY: 'PAÍS',
  'CREATE NEW': 'Criar novo',
  'current month': 'Este Mês',
  DATE: 'DATA',
  'DATE OF BIRTH': 'DATA DE NASCIMENTO',
  days: 'días',
  district: 'distrito',
  DISTRICT: 'DISTRITO',
  dsp: 'dsp',
  'Duplicate contact found': 'Contacto duplicado',
  'E-mail': 'E-mail',
  'E-MAIL': 'E-MAIL',
  email: 'E-mail',
  Email: 'E-mail',
  'Emergency contacts': 'contactos de emergência',
  Employer: 'empresa',
  'Entry Fee': 'Valor starterpack',
  evening: 'Noite',
  events: 'Eventos',
  female: 'feminino',
  Female: 'Feminino',
  'JOINING PACK': 'TAXA DE ADESÃO',
  'fiscal number': 'número fiscal',
  'FISCAL NUMBER': 'NIF',
  Friday: 'Sexta-feira',
  'future payments': 'pagamentos futuros',
  'FUTURE PAYMENTS': 'PAGAMENTOS FUTUROS',
  GDPR_BODY1: 'Aceita que o Holmes Place use os seus dados pessoais de acordo com a nossa',
  GDPR_BODY2:
    'Usamos os seus dados para personalizar e melhorar a sua experiência nas nossas plataformas digitais, \n\t\tfornecer produtos e serviços solicitados por si e realizar perfis e estudos de mercado. Pode alterar as suas preferências a qualquer momento.',
  gender: 'gênero',
  GENDER: 'GÉNERO',
  'go to contract': 'ir para contrato',
  Hello: 'Olá',
  'Holmes Place': 'Holmes Place',
  home: 'home',
  Home: 'Home',
  'Home phone': 'telefone fixo',
  'I agree with the': 'Concordo com o',
  'INITIAL PAYMENT': 'PAGAMENTO INICIAL',
  'CURRENT MONTH': 'ESTE MÊS',
  'Invalid birth date': 'Data de nascimento inválida',
  'invalid email': 'e-mail inválido',
  'Invalid email address': 'E-mail inválido',
  'Invalid phone number (has to start with 00 or + symbol)': 'Número de telefone inválido (tem de começar com 00 ou o símbolo +)',
  'Invite your friends %{break} to join the journey with you!': 'convide os seus amigos para iniciarem um caminho de uma vida saudável consigo!',
  item: 'Item',
  'Job title': 'função',
  'joining pack': 'Taxa de adesão',
  'journey hub': 'Journey Hub',
  'lead tracker': 'rastreador principal',
  "LET'S START!": 'Começar!',
  'log in': 'Iniciar sessão',
  'Log out': 'Sair',
  'Login failed. Please check your club, username and password': 'Falhou o início de sessão. Por favor verifique o seu clube, utilizador e password',
  male: 'Masculino',
  Male: 'Masculino',
  MEMBER: 'SÓCIO',
  'MEMBER INFORMATION': 'INFORMAÇÃO DO SÓCIO',
  'MEMBER NUMBER': 'NÚMERO DE SÓCIO',
  'MEMBER SIGNATURE': 'ASSINATURA DO SÓCIO',
  membership: 'Mensalidade',
  Membership: 'adesão',
  'MEMBERSHIP CONTRACT': 'CONTRATO DE ADESÃO',
  'membership plans': 'Mensalidades',
  memberships: 'Adesões',
  'MEMBERSHIPS AND PAYMENTS': 'MENSALIDADE E PAGAMENTOS',
  men: 'masculino',
  Mobilephone: 'telemóvel',
  Monday: 'Segunda-feira',
  'monthly fee': 'Taxa Mensal',
  'FOLLOWING MONTHS(PER MONTH)': 'MESES SEGUINTES (POR MÊS)',
  'NEXT MONTH': 'PRÓXIMO MÊS',
  'MONTHLY FEE': 'MENSALIDADE',
  'MONTHLY FEES': 'MENSALIDADES SEGUINTES',
  'Monthly payments': 'Adesões e pagamento',
  'monthly plan': 'Plano Mensal',
  'more events': 'Mais eventos',
  'more news': 'Mais notícias',
  morning: 'Manhã',
  'my info': 'Os meus dados',
  name: 'Nome',
  Name: 'Nome',
  NAME: 'NOME',
  'Name surname': 'Nome Apelido',
  news: 'Blog',
  'blog & events': 'Blog & Eventos',
  Next: 'Próximo',
  NEXT: 'Próximo',
  'NEXT FEE': 'PRÓXIMA MENSALIDADE',
  NIF: 'NIF',
  no: 'não',
  NO: 'NÃO',
  Notes: 'notas',
  NUTRITION: 'NUTRIÇÃO',
  OK: 'OK',
  'Paid on signation': 'pago na assinatura?',
  passport: 'passaporte',
  PASSPORT: 'PASSAPORTE',
  password: 'Password',
  Password: 'Password',
  'PAY NOW': 'PAGUE AGORA',
  payment: 'pagamento',
  Payment: 'Pago',
  'payment today': 'pagamento hoje',
  Payments: 'pagamentos',
  'Personal info': 'informação pessoal',
  'Personal information': 'informação pessoal',
  'PERSONAL INFORMATION': 'INFORMAÇÃO PESSOAL',
  phone: 'telefone',
  'Phone number': 'Número de telefone',
  'PHONE NUMBER': 'NÚMERO DE TELEFONE',
  pin: 'pin',
  PIN: 'PIN',
  'Please check your filled data': 'Verifique por favor os dados preenchidos ',
  'Please fill all the required fields': 'Por favor preencha todos os campos obrigatórios',
  'Please fill all your personal information': 'Por favor preencha a sua informação pessoal',
  'Please Log in to access your sales dashboard': 'Inicie sessão para aceder às suas vendas',
  'Please select an answer': 'Selecione por favor a resposta',
  'Please select membership first': 'Por favor selecione primeiro a sua adesão',
  'Please select starterpack first': 'Por favor selecione primeiro o starter pack',
  'postal code': 'CÓDIGO POSTAL',
  'POSTAL CODE': 'CÓDIGO POSTAL',
  Previous: 'Anterior',
  prices: 'Preços',
  'Privacy Opt-In': 'Tratamento de Dados Pessoais',
  'Privacy Policy': 'Política de Privacidade',
  'promo code': 'promocode',
  'Promo code': 'Promocode',
  PROVINCE: 'DISTRITO',
  'No preference': 'Sem preferência',
  'RE-ENTER YOUR ACCESS INFORMATION': 'Introduza novamente os seus dados de acesso',
  'Remove from plan': 'Remover do plano',
  'reset person': 'Eliminar lead',
  'SALESPERSON SIGNATURE': 'ASSINATURA DO WELLNESS ADVISOR',
  Saturday: 'Sábado',
  SELECT: 'SELECIONAR',
  'select club': 'Selecionar clube',
  'Server is not responding': 'O servidor não está a responder',
  services: 'Serviços',
  SEX: 'SEXO',
  'sign contract': 'assinar contrato',
  'Signature is missing': 'A assinatura está em falta',
  Skip: 'avançar',
  'special offer': 'Oferta Especial',
  'start date': 'Data de início',
  'START DATE': 'DATA DE INÍCIO',
  startDate: 'data de início',
  'Starter pack': 'Starter pack',
  starterpacks: 'starterpacks',
  STARTING: 'A INICIAR EM',
  'STARTING DATE': 'INICIO DO CONTRATO',
  street: 'rua',
  Street: 'rua',
  Submit: 'submeter',
  summary: 'Sumário',
  Sunday: 'Domingo',
  surname: 'Apelido',
  Surname: 'Apelido',
  SURNAME: 'APELIDO',
  'Tell us more about you': 'Conte-nos mais sobre si',
  'Terms and Conditions': 'Termos e Condições',
  'THANK YOU.': 'obrigado',
  'The requested event could not be found': 'O evento pesquisado não foi encontrado',
  'The requested news item could not be found': 'A notícia pesquisada não foi encontrada',
  'This month payment': 'Pago mensal',
  Thursday: 'Quinta-feira',
  'To be done': 'Para ser feito',
  total: 'total',
  TOTAL: 'TOTAL',
  'total due today': 'Total a pagar',
  Tuesday: 'Terça-feira',
  TYPE: 'TIPO',
  'Unfortunately club has no events': 'Infelizmente, de momento, o clube não tem eventos disponíveis.',
  'Update your personal information': 'Atualize a sua informação pessoal',
  USE: 'Utilizar',
  username: 'Utilizador',
  value: 'Valor',
  Wednesday: 'Quarta-feira',
  welcome: 'Bem-Vindo',
  'Welcome to': 'Bem-Vindo ao',
  'The field is required': 'Campo obrigatório',
  'WELCOME TO HOLMES PLACE': 'Bem-Vindo ao Holmes Place',
  'Welcome to Holmes Place %{break} Get ready to enjoy the journey!': 'Bem-Vindo ao Holmes Place. Prepare-se para começar!',
  'What types of communications?': 'quais os tipos de comunicação?',
  'Where did you hear about us?': 'como obteve conhecimento acerca de nós?',
  'Work phone': 'Telefone de trabalho',
  yes: 'sim',
  YES: 'SIM',
  'YES!': 'SIM!',
  'You can change your preferences at any time.': 'Pode alterar as suas preferências a qualquer momento.',
  'By submitting, you agree to Holmes Place Group using your personal data in accordance with our <a target="_blank" href="%{privacyURL}">Privacy Policy</a>. We use your data to personalize and improve your experience on our digital platforms, provide products and services you request from us, and carry out profiling and market research.':
    'Aceita que o Holmes Place use os seus dados pessoais de acordo com a nossa <a target="_blank" href="%{privacyURL}">Política de Privacidade</a>. Usamos os seus dados para personalizar e melhorar a sua experiência nas nossas plataformas digitais, fornecer produtos e serviços solicitados por si e realizar perfis e estudos de mercado. Pode alterar as suas preferências a qualquer momento.',
  'You will receive your contract and your 2 week plan in your email shortly.': 'Irá receber em breve o seu contrato e o seu plano de 2 semanas no seu e-mail.',
  'ZIP (xxxx-xxx)': 'código postal (xxxx-xxx)',
  'Direct debit': 'Débito Direto',
  'We will only use your bank details if you finalize your inscription. They will be used for your future monthly subscription payments.':
    'Iremos utilizar os seus dados bancários apenas se finalizar a sua inscrição. Os dados bancários serão utilizados para processar o pagamento da sua mensalidade.',
  'Document type is missing': 'ADD PT TRANSLATION.',
  week: 'semana',
  'Invalid Promocode': 'Promocode inválido',
  'Read T&C first': 'Leia os T&C primeiro',
  'bank account owner': 'Nome do titular da conta',
};
