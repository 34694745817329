import {List} from 'immutable';
export const mainReducer = {
  URL: '/',
  salespersonsName: null,
  salespersonExternalId: null,
  currency: 'EUR',
  language: {id: 1, code: 'en'},
  languages: [],
  leftMenuOpen: false,
  rightMenuOpen: false,
  loader: false,
  confirmLoader: false,
  confirm: {
    open: false,
    msg: '',
  },
  zone: 'salesperson',
  club: {
    en: {
      club: {},
      benefits: null,
      classes: null,
      news: null,
      services: null,
      events: null,
      memberships: null,
      starterpacks: null,
      offers: null,
      region: null,
      loaded: false,
    },
  },
  calendarData: List(),
  weekNumber: 1,
  plan: List(),
  login: {
    allClubs: List(),
    clubName: null,
    region: null,
  },
  accessToken: null,
  validNif: false,
  app: null, // DSP or LT
  error: null,
  success: null,
  alertType: null,
  contacts: [],
};
