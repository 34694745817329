import React, {Component} from 'react';

import './style.scss';

class Loader extends Component {
  render() {
    return (
      <div>
        <div className="loader" />
        <div className="loader__filter" />
      </div>
    );
  }
}

export default Loader;
