export const API_LOGIN_SUCCESS = 'App/Login::API_LOGIN_SUCCESS';
export const API_CLUB_INFO_SUCCESS = 'App/Api::API_CLUB_INFO_SUCCESS';
export const API_GET_CLUBS_SUCCESS = 'App/Api::API_GET_CLUBS_SUCCESS';
export const API_PERSON_DUPLICATE_SUCCESS = 'App/Api::API_PERSON_DUPLICATE_SUCCESS';
export const API_CREATE_PERSON_SUCCESS = 'App/Api::API_CREATE_PERSON_SUCCESS';
export const CALENDAR_DATA_SUCCESS = 'App/Api::CALENDAR_DATA_SUCCESS';
export const API_VERIFY_PERSON = 'App/Api::API_VERIFY_PERSON';
export const API_VERIFY_PERSON_SUCCESS = 'App/Api::API_VERIFY_PERSON_SUCCESS';
export const API_CONFIRM_AS_PROSPECT = 'App/Api::CONFIRM_AS_PROSPECT';
export const API_CONFIRM_AS_MEMBER = 'App/Api::CONFIRM_AS_MEMBER';
export const API_PRICE_COUNT = 'App/Api::API_PRICE_COUNT';
export const API_SALESPERSONS_NAME = 'App/Api::API_SALESPERSONS_NAME';
export const SALESPERSON_NAME_SUCCESS = 'App/Api::SALESPERSON_NAME_SUCCESS';
export const LOGIN_REMOVE_DATA = 'App/Api::LOGIN_REMOVE_DATA';
export const VALIDATE_NIF = 'App/Api::VALIDATE_NIF';
export const API_TWOWEEKSPLAN_INSERT = 'App/Api::API_TWOWEEKSPLAN_INSERT';
export const API_UPDATE_CLUB_INFO = 'App/Api::API_UPDATE_CLUB_INFO';
export const API_REQUEST_CHECKOUT_DATA = 'App/Api::API_REQUEST_CHECKOUT_DATA';
export const API_CHECKOUT_DATA_SUCCESS = 'App/Api::API_CHECKOUT_DATA_SUCCESS';
export const CHANGE_CURRENCY = 'App/Api::CHANGE_CURRENCY';
export const API_NOTIFY = 'App/Api::API_NOTIFY';
