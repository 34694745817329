import React from 'react';
import {Col} from 'react-bootstrap';
import Page from '../../containers/Page';
import './ThankYou.scss';

import lang from '../../utils/translator';

const ThankYou = () => {
  return (
    <Page nav navBarType={'nav--dark'}>
      <div className="ThankYou">
        <div className="vcenter-container">
          <Col md={12}>
            <h1>{lang('THANK YOU.')}</h1>
            <h3
              dangerouslySetInnerHTML={{
                __html: lang('Welcome to Holmes Place %{break} Get ready to enjoy the journey!', {break: '<br/>'}),
              }}
            />
          </Col>
        </div>
        <div>
          <h4>{lang('You will receive your contract and your 2 week plan in your email shortly.')}</h4>
        </div>
      </div>
    </Page>
  );
};

export default ThankYou;
