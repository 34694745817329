import {connect} from 'react-redux';
import {connectLanguage} from '../../utils/connectLanguage';

import {selectCurrency, selectStarterpacks, selectMemberships} from '../../App/selectors';
import {selectSelectedStarterpackId} from './selectors';
import {choosePackage} from './actions';
import PackagesComponent from './Packages';

function mapStateToProps(state) {
  return {
    memberships: selectMemberships(state),
    starterpacks: selectStarterpacks(state),
    selectedStarterpack: selectSelectedStarterpackId(state),
    currency: selectCurrency(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    choosePackage: packageId => () => dispatch(choosePackage(packageId)),
  };
}

const Packages = connect(mapStateToProps, mapDispatchToProps)(PackagesComponent);
export default connectLanguage(Packages, connect);
