import React, {Component} from 'react';

import './style.scss';

class LoaderExtraSmall extends Component {
  render() {
    return <div className="LoaderExtraSmall"></div>;
  }
}

export default LoaderExtraSmall;
