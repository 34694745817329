import React from 'react';
import {Link} from 'react-router';
import {CLUB_TOUR_NEWS_PAGE} from '../../../App/constants.js';
import {NO_NEWS_FOUND} from '../../../App/errorConstatns';
import ClubTourSubMenu from '../../../components/ClubTourSubMenu';
import Heading from '../../../components/Heading';
import HeadingBlock from '../../../components/HeadingBlock';
import TileSquare from '../../../components/TileSquare';
import TileSquareContentBlock from '../../../components/TileSquare/ContentBlock';
import TileSquareMainText from '../../../components/TileSquare/MainText';
import TileSquareSubText from '../../../components/TileSquare/SubText';
import lang from '../../../utils/translator';
import Page from '../../Page';
import {getInObj} from '../helpers';
import './style.scss';

const News = ({news: allNews}) => {
  const news = allNews
    .map(item => {
      const data = item.content.find(c => c.component === 'BlogPost');
      if (!data) return null;
      const result = data;
      result.id = item.structureId;
      result.url = item.url;
      result.published = item.publishedFrom;
      return result;
    })
    .filter(Boolean);

  return (
    <Page nav navBarType={'nav--dark'}>
      <div className="club-tour-news">
        <ClubTourSubMenu current={CLUB_TOUR_NEWS_PAGE} lang={lang} />
        <HeadingBlock>
          <Heading>{lang('blog')}</Heading>
        </HeadingBlock>
        <div className="club-tour-news__tiles-block tile-animated">
          {news && news.length > 0 ? (
            news
              .map(item => {
                const date = new Date(item.published);
                const imageUrl = getInObj(item, ['data', 'image', 'url'], '')
                  .split(' ')
                  .join('%20');

                return (
                  <Link key={item.id} to={item.url}>
                    <TileSquare hasFilter backgroundImage={{src: imageUrl, title: ''}}>
                      <TileSquareContentBlock>
                        <TileSquareMainText>{getInObj(item, ['data', 'title'], '')}</TileSquareMainText>
                        <TileSquareSubText>{date.toLocaleDateString()}</TileSquareSubText>
                      </TileSquareContentBlock>
                    </TileSquare>
                  </Link>
                );
              })
              .filter(_ => _)
          ) : (
            <div className="memberships">
              <h1 className="warning">{lang(NO_NEWS_FOUND)}</h1>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default News;
