import {fromJS} from 'immutable';
import {initialState} from '../initialstate';
import {CHOOSE_MEMBERSHIP} from '../../../containers/Memberships/constants';
import {CHOOSE_PACKAGE} from '../../../containers/Packages/constants';
import {PRICE_COUNT} from '../../../containers/Summary/constants';
import {SAVE_PHOTO, SAVE_SIGNATURE} from '../../../containers/Contract/constants';
import {PROMO_CHANGE, LOG_OUT_SUCCESS} from '../../../Actions/constants';
import {API_CHECKOUT_DATA_SUCCESS} from '../../../api/constants.js';
import {RESET_PERSON} from '../../constants';

export const contractReducer = (state, action) => {
  switch (action.type) {
    case CHOOSE_MEMBERSHIP:
      return state.setIn(['contract', 'selectedMembership'], action.membershipId);
    case CHOOSE_PACKAGE:
      return state.setIn(['contract', 'selectedPackage'], action.selectedPackage);
    case SAVE_SIGNATURE:
      if (action.salesPerson) return state.setIn(['contract', 'signatureSalesperson'], fromJS(action.signature));
      return state.setIn(['contract', 'signature'], fromJS(action.signature));
    case PROMO_CHANGE:
      return state.setIn(['contract', 'promo'], action.promo);
    case PRICE_COUNT:
      const payload = JSON.parse(JSON.stringify(action));
      delete payload.type;
      return state.setIn(['contractInfo'], fromJS(payload));
    case SAVE_PHOTO:
      return state.setIn(['contract', 'photo'], action.photo);
    case LOG_OUT_SUCCESS:
      return fromJS(initialState.get('contractReducer'));
    case API_CHECKOUT_DATA_SUCCESS:
      return state.setIn(['checkout'], fromJS(action.data));
    case RESET_PERSON:
      const initialContractState = initialState.get('contractReducer');
      return state
        .set('contract', initialContractState.get('contract'))
        .set('contractInfo', initialContractState.get('contractInfo'))
        .set('checkout', initialContractState.get('checkout'));
    default:
      return state;
  }
};
