import React from 'react';
import './style.scss';

const Hamburger = React.memo(({active, menuOpen}) => (
  <div className={`hamburger hamburger--${active}`} onClick={menuOpen}>
    <div className="bar"></div>
  </div>
));

export default Hamburger;
