import {connect} from 'react-redux';
import {selectClubName, selectPhotos, selectPhotoCover, selectWelcomeText, selectVideoDefault} from './selectors.js';
import {selectClub} from '../../../App/selectors';
import {connectLanguage} from '../../../utils/connectLanguage';
import WelcomeComponent from './Welcome.js';

const mapStateToProps = state => {
  return {
    photos: selectPhotos(state),
    photoCover: selectPhotoCover(state),
    //services: selectServices(state),
    video: selectVideoDefault(state),
    mainText: selectWelcomeText(state),
    videoDefault: selectVideoDefault(state),
    clubName: selectClubName(state),
    club: selectClub(state),
  };
};

const Welcome = connect(mapStateToProps, null)(WelcomeComponent);
export default connectLanguage(Welcome, connect);
