import React from 'react';
import './style.scss';

class ClubTourWrapper extends React.PureComponent {
  render() {
    return <div className="club-tour-wrapper">{this.props.children}</div>;
  }
}

export default ClubTourWrapper;
