import React from 'react';

const WelcomeIframe = React.memo(({region, language, clubUrl}) => {
  const getLocation = () => {
    if (region.id === 8) return 'pt';
    if (region.id === 9) return 'es';
    return 'en';
  };

  return <iframe title="iframeWelcome" id="iframeWelcome" src={`https://www.holmesplace.com/${getLocation()}/${language}${clubUrl}?iframe`} width="100%" height="800" />;
});

export default WelcomeIframe;
