import React, {Component} from 'react';
import {ToastContainer} from 'react-toastr';

import './style.scss';

import lang from '../../utils/translator';

class ErrorBox extends Component {
  render() {
    return (
      <div>
        <ToastContainer ref="container" className="toast-top-right" />
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.alertType === 'error') this.addError();
    else if (this.props.alertType === 'success') this.addSuccess();
  }

  addError() {
    this.refs.container.error(<span>{lang(`${this.props.error.error}`)}</span>, null, {
      timeOut: 10000,
      extendedTimeOut: 0,
      preventDuplicates: false,
    });
  }

  addSuccess() {
    this.refs.container.success(<span>{lang(`${this.props.success.msg}`)}</span>, null, {
      timeOut: 10000,
      extendedTimeOut: 15000,
    });
  }
}

export default ErrorBox;
