import {connect} from 'react-redux';
import LoginComponent from './Login.js';
import {tryToLogin, getClubs, changeClub, changeRegion} from './actions.js';
import {verifyPersonForm} from '../Page/actions';
import {selectRegions} from '../../App/selectors';
import * as selectors from './selectors.js';
import {connectLanguage} from '../../utils/connectLanguage';

const mapStateToProps = state => {
  return {
    clubId: selectors.selectClubId(state),
    selectedClub: selectors.selectClubName(state),
    allClubs: selectors.selectAllCubs(state).sort((first, second) => (first.name < second.name ? -1 : first.name > second.name ? 1 : 0)),
    regions: selectRegions(state),
    selectedRegion: selectors.selectSelectedRegion(state),
    username: selectors.selectUsername(state),
    password: selectors.selectPassword(state),
    ifPin: selectors.selectIfPin(state),
    pinCode: selectors.selectPinCode(state),
    loader: selectors.selectLoader(state),
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(getClubs());
  return {
    logIn: (clubId, username, password, pin) => dispatch(tryToLogin(clubId, username, password, pin)),
    changeClub: clubId => dispatch(changeClub(clubId)),
    onChange: e => {
      if (e.target.id === 'pinCode') dispatch(verifyPersonForm(e.target.value.length > 0, 'ifPin'));
      dispatch(verifyPersonForm(e.target.value, e.target.id));
    },
    onSelectRegion: region => dispatch(changeRegion(region)),
  };
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
export default connectLanguage(Login, connect);
