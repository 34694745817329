import React, {Component} from 'react';
import './style.scss';

//import Tile from '../Tile';
//import TileMainText from '../Tile/MainText.js';
//import TileSubText from '../Tile/SubText.js';

/**
 *
 * usage:
 * <TileSquare>
 * 	<ContentBlock>
 * 		<MainText>
 * 		</MainText>
 * 		<SubText>
 * 		</SubText>
 * 	</ContentBlock>
 * </TileSquare>
 */

class TileSquare extends Component {
  replaceImgWidth(src) {
    if (src) {
      var newWidth = src.replace('1920x', '480x');
      return newWidth;
    }
  }

  render() {
    let style = this.props.style || {};
    if (this.props.backgroundImage) style.backgroundImage = 'url("' + this.replaceImgWidth(this.props.backgroundImage.src) + '")';

    return (
      <div className="tile-square__outer">
        <div style={style} className="tile-square">
          {this.props.hasFilter && <div className="tile-square__filter" />}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default TileSquare;
