import {connect} from 'react-redux';
import ErrorBoxComponent from './ErrorBox';
import {errorSelector, selectAlertType, successSelector} from './selectors.js';

const mapStateToProps = state => {
  return {
    error: errorSelector(state),
    success: successSelector(state),
    alertType: selectAlertType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ErrorBox = connect(mapStateToProps, mapDispatchToProps)(ErrorBoxComponent);
export default ErrorBox;
