/* eslint-disable no-useless-escape */
import React from 'react';
import CustomSelect from './components/CustomSelect';
import TextInput from './components/TextInput';
import NifPassportInput from './components/NifPassportInput';
import DatePicker from './components/DatePicker';
import PhoneInput from './components/PhoneInput';
import TextArea from './components/TextArea';
import ZipInput from './components/ZipInput';
import lang from '../../../utils/translator';

// function validateNif(nif, regionId) {
// 	if (regionId === 8) return validatePortugalNif(nif);
// 	return validateSpainNif(nif);
// }

// function validatePortugalNif(nif) {
// 	if (nif.length === 9) {
// 		const added = nif[7] * 2 + nif[6] * 3 + nif[5] * 4 + nif[4] * 5 + nif[3] * 6 + nif[2] * 7 + nif[1] * 8 + nif[0] * 9;
// 		let mod = added % 11;
// 		let control = 0;

// 		if (mod === 0 || mod === 1) {
// 			control = 0;
// 		} else {
// 			control = 11 - mod;
// 		}

// 		if (nif[8] === control) {
// 			return true;
// 		} else {
// 			return false;
// 		}
// 	} else {
// 		return false;
// 	}
// }

// function validateSpainNif(value) {
// 	const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
// 	const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
// 	const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
// 	const str = value.toString().toUpperCase();

// 	if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

// 	const nie = str
// 		.replace(/^[X]/, '0')
// 		.replace(/^[Y]/, '1')
// 		.replace(/^[Z]/, '2');

// 	const letter = str.substr(-1);
// 	const charIndex = parseInt(nie.substr(0, 8)) % 23;

// 	if (validChars.charAt(charIndex) === letter) return true;

// 	return false;
// }

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const checkForErrors = (fields, formData) => {
  return fields.reduce((carry, field) => {
    carry[field.name] = [];

    if (field.required && (!formData[field.name] || formData[field.name] === '' || formData[field.name] === null)) {
      carry[field.name].push(lang('The field is required'));
    } else if (field.regex) {
      if (!field.regex.test(formData[field.name])) carry[field.name].push(field.errorMsg || 'error');
    } else if (field.validateFunction) {
      if (!field.validateFunction(formData[field.name])) carry[field.name].push(field.errorMsg || 'error');
    } else if (field.validateErrorFunction) {
      const error = field.validateErrorFunction(formData[field.name]);
      if (error) carry[field.name].push(error);
    }

    return carry;
  }, {});
};

const hasErrors = (fields, formData) => {
  let checkErrors = checkForErrors(fields, formData);
  const err = Object.values(checkErrors).filter(e => e.length !== 0);
  return err.length > 0;
};

/**
 * Returns districts based on clubs
 * @param {int} regionId
 */
const _getDistricts = regionId => {
  return regionId === 8
    ? {
        '0': 'DESCONHECIDO',
        '02': 'BRAGA',
        '03': 'PORTO',
        '04': 'VILA REAL',
        '05': 'BRAGANÇA',
        '06': 'AVEIRO',
        '07': 'VISEU',
        '08': 'GUARDA',
        '09': 'COIMBRA',
        '1': 'PORTUGAL',
        '10': 'CASTELO BRANCO',
        '11': 'LEIRIA',
        '12': 'LISBOA',
        '13': 'SANTARÉM',
        '14': 'PORTALEGRE',
        '15': 'ÉVORA',
        '16': 'SETÚBAL',
        '17': 'BEJA',
        '18': 'FARO',
      }
    : {
        '0': 'DESCONOCIDO',
        '01': 'ÁLAVA',
        '02': 'ALBACETE',
        '03': 'ALICANTE',
        '04': 'ALMERÍA',
        '05': 'ÁVILA',
        '06': 'BADAJOZ',
        '07': 'BALEARES',
        '08': 'BARCELONA',
        '09': 'BURGOS',
        '100': 'NUEVA',
        '11': 'CÁDIZ',
        '12': 'CASTELLÓN',
        '13': 'CIUDAD REAL',
        '14': 'CÓRDOBA',
        '15': 'LA CORUÑA',
        '16': 'CUENCA',
        '17': 'GIRONA',
        '18': 'GRANADA',
        '19': 'GUADALAJARA',
        '20': 'GUIPUZCOA',
        '21': 'HUELVA',
        '22': 'HUESCA',
        '23': 'JAÉN',
        '24': 'LEÓN',
        '25': 'LLEIDA',
        '26': 'LOGROÑO',
        '27': 'LUGO',
        '28': 'MADRID',
        '29': 'MÁLAGA',
        '30': 'MURCIA',
        '31': 'NAVARRA',
        '32': 'ORENSE',
        '33': 'ASTURIAS',
        '34': 'PALENCIA',
        '35': 'LAS PALMAS',
        '36': 'PONTEVEDRA',
        '37': 'SALAMANCA',
        '38': 'STA. CRUZ DE TENERIFE',
        '39': 'SANTANDER (CANTABRIA)',
        '40': 'SEGOVIA',
        '41': 'SEVILLA',
        '42': 'SORIA',
        '43': 'TARRAGONA',
        '44': 'TERUEL',
        '45': 'TOLEDO',
        '46': 'VALENCIA',
        '47': 'VALLADOLID',
        '48': 'VIZCAYA',
        '49': 'ZAMORA',
        '50': 'ZARAGOZA',
        '51': 'CEUTA',
        '52': 'MELILLA',
        '99': 'DESCONOCIDA',
      };
};

const _getCountries = regionId => ({ES: 'Spain', PT: 'Portugal'});

/**
 * Renders contrac information in list
 *
 * @param {object} personalInformation
 * @return {string} React element
 */
const mapFieldsToRender = (currentClub, personalInfo) => {
  const avaiableContractFields = getContractFormFields(currentClub, personalInfo);
  const contractFieldsKeys = avaiableContractFields.map(value => value.name);

  const fields = [];

  /**
   * Personal information
   */
  fields.push(`${personalInfo.name} ${personalInfo.surname}`);
  fields.push(personalInfo.nif || personalInfo.passport);
  fields.push(`${personalInfo.birthDate} / ${personalInfo.gender}`);

  /**
   * Bank information
   */
  if (contractFieldsKeys.indexOf('iban') > -1 && contractFieldsKeys.indexOf('bic') > -1) {
    fields.push(`IBAN: ${personalInfo.iban || '–'}`);
    fields.push(`SWIFT/BIC: ${personalInfo.bic || '–'}`);
  }

  /**
   * Contact information + Address
   */
  fields.push(`${personalInfo.phoneCode} ${personalInfo.mobile}`);
  fields.push(personalInfo.street);

  fields.push(`${personalInfo.postalCode} ${personalInfo.city}`);

  return fields;
};

const getDirectDebitFormFields = () => {
  return [
    {name: 'accountOwner', type: 'input', placeholder: lang('bank account owner'), required: true, fullSize: true},
    {name: 'iban', type: 'input', placeholder: 'IBAN', required: true},
    {
      name: 'bic',
      type: 'input',
      placeholder: 'BIC / SWIFT code',
      required: true,
    },
  ];
};

const getNotesField = () => ({name: 'notes', type: 'textArea', required: false, fullSize: true});

/**
 * Returns form fields based on club/ country
 *
 * @param {Club} club
 */
const getContractFormFields = (club, personalInfo, lang) => {
  let fields = [];

  /**
   * TODO: Improve
   */
  const regionId = club && club.regionId;
  switch (regionId) {
    case 8: // portugal
    case 9: // spain
      fields = [
        {
          name: 'name',
          type: 'input',
          placeholder: lang('name'),
          required: true,
        },
        {
          name: 'surname',
          type: 'input',
          placeholder: lang('surname'),
          required: true,
        },
        {
          name: 'gender',
          type: 'select',
          placeholder: lang('gender'),
          options: {M: lang('male'), F: lang('female')},
          required: true,
        },
        {
          name: 'birthDate',
          type: 'date',
          placeholder: lang('birth date'),
          required: true,
        },
        {
          name: 'street',
          type: 'input',
          placeholder: lang('street'),
          required: true,
        },
        {
          name: 'postalCode',
          type: 'zip',
          placeholder: lang('postal code'),
          required: true,
        },
        {
          name: 'country',
          type: 'select',
          placeholder: lang('country'),
          options: _getCountries(regionId),
          required: true,
        },
        {
          name: 'city',
          type: 'input',
          placeholder: lang('city'),
          required: true,
        },
        {
          name: 'district',
          type: 'select',
          placeholder: lang('district'),
          options: _getDistricts(regionId),
          required: true,
        },
      ];

      fields.push(
				personalInfo.isCitizen
					? {
							name: 'nif',
							type: 'nif',
							placeholder: lang('fiscal number'),
							required: true,
							alternative: !personalInfo.docTypeCannotBeChanged && lang('passport'),
					  }
					: {
							name: 'passport',
							type: 'nif',
							placeholder: lang('passport'),
							required: true,
							alternative: !personalInfo.docTypeCannotBeChanged && lang('fiscal number')
					  }
			)

      return [
        ...fields,
        {
          name: 'email',
          type: 'input',
          placeholder: lang('email'),
          required: true,
          validateFunction: email => validateEmail(email),
          errorMsg: lang('invalid email'),
        },
        {name: 'phone', type: 'phone', placeholder: lang('phone')},
      ];
    default:
      throw new Error(lang('Region is not ready to sell.'));
  }
};

const renderField = (field, personalInfo, changeField, formErrors, country) => {
  switch (field.type) {
    case 'nif':
      return <NifPassportInput key={field.name} name={field.name} field={field} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />;
    case 'input':
      return (
        <TextInput
          key={field.name}
          name={field.name}
          field={field}
          fullSize={field.fullSize}
          personalInfo={personalInfo}
          changeField={changeField}
          error={formErrors[field.name]}
          inputStyle={{color: '#a3a3a3'}}
        />
      );
    case 'select':
      return (
        <CustomSelect key={field.name} name={field.name} field={field} country={country} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />
      );
    case 'date':
      return <DatePicker lang={lang} key={field.name} name={field.name} field={field} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />;
    case 'phone':
      return <PhoneInput key={field.name} field={field} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />;
    case 'zip':
      return <ZipInput key={field.name} name={field.name} field={field} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />;
    case 'textArea':
      return (
        <TextArea fullSize={field.fullSize} key={field.name} name={field.name} field={field} personalInfo={personalInfo} changeField={changeField} error={formErrors[field.name]} />
      );

    default:
      return <noscript />;
  }
};

export {mapFieldsToRender, getContractFormFields, getDirectDebitFormFields, checkForErrors, hasErrors, renderField, getNotesField};
