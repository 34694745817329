import React, {PureComponent} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import DoubleArrow from '../../components/DoubleArrow';
import {LEFT, RIGHT} from '../../components/DoubleArrow/constants';
import lang from '../../utils/translator';
import CalendarView from '../CalendarView';
import Page from '../Page/index';
import moment from 'moment';
import './style.scss';

class TwoWeeksPlan extends PureComponent {
  state = {
    timePeriods: ['morning', 'afternoon', 'evening'],
    selectedTimePeriod: 'all',
  };

  componentDidMount() {
    const {notifyNewMember} = this.props;
    const checkoutParam = window.location.search
      .replace('?', '')
      .split('&')
      .find(str => str.includes('checkout'));
    if (checkoutParam) {
      notifyNewMember(checkoutParam.replace('checkout=', ''));
      window.history.replaceState(null, null, 'twoweeksplan');
    }
  }

  changePeriod = period => {
    this.setState({selectedTimePeriod: period});
    const {timePeriods} = this.state;

    if (timePeriods === period) this.setState({timePeriods: ['morning', 'afternoon', 'evening']});
    else
      switch (period) {
        case 'morning':
          this.setState({timePeriods: ['morning']});
          break;
        case 'afternoon':
          this.setState({timePeriods: ['afternoon']});
          break;
        case 'evening':
          this.setState({timePeriods: ['evening']});
          break;
        default:
          this.setState({timePeriods: ['morning', 'afternoon', 'evening']});
      }
  };

  renderNavButtons = () => {
    const {nextPage, app, plan, person, language} = this.props;
    const langId = language.toJS().id;

    const onClick = type => () =>
      nextPage(
        app,
        type,
        plan,
        person,
        moment()
          .startOf('w')
          .add(1, 'd')
          .format(),
        langId
      );

    return (
      <div className="divContinue">
        <div>
          <Button className="customStyle btnSkip" onClick={onClick('skip')}>
            {lang('Skip')}
          </Button>
          {person.memberships && person.memberships.length > 0 && (
            <Button className="customStyle btnContinue" onClick={onClick('continue')}>
              {lang('Submit')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {weekNumber, loadPrevious, loadNext} = this.props;
    const {timePeriods} = this.state;
    return (
      <Page nav navBarType="nav--dark">
        <div className="club-tour-classes-schedule__inner">
          <Row className="calendar__arrows">
            <Col md={1} xs={2}>
              {weekNumber > 1 && (
                <div>
                  <h4>{lang('Previous')}</h4>
                  <a className="arrow__link" onClick={() => loadPrevious(weekNumber)}>
                    <DoubleArrow orientation={LEFT} />
                  </a>
                </div>
              )}
            </Col>
            <Col md={10} xs={8}>
              <h2 className="text-gold heading-md text-center">
                {lang('2 weeks plan')}
                {' - '}
                {lang('week')}
                {weekNumber === 1 ? ' 1' : ' 2'}
              </h2>
            </Col>
            <Col md={1} xs={2}>
              {weekNumber < 8 && (
                <div>
                  <h4>{lang('Next')}</h4>
                  <a className="arrow__link" onClick={() => loadNext(weekNumber)}>
                    <DoubleArrow orientation={RIGHT} />
                  </a>
                </div>
              )}
            </Col>
          </Row>

          <Row>{this.renderNavButtons()}</Row>

          <Row className="schedule__filterButton--holder">
            <Col md={6} mdOffset={3} xs={12}>
              <Col md={4} sm={4} xs={4}>
                <div
                  className={'schedule__filterButton button--small button--small--gold ' + (timePeriods === 'morning' ? 'schedule__filterButton--active' : '')}
                  onClick={this.changePeriod.bind(this, 'morning')}>
                  {lang('morning')}
                </div>
              </Col>

              <Col md={4} sm={4} xs={4}>
                <div
                  className={'schedule__filterButton button--small button--small--gold ' + (timePeriods === 'afternoon' ? 'schedule__filterButton--active' : '')}
                  onClick={this.changePeriod.bind(this, 'afternoon')}>
                  {lang('afternoon')}
                </div>
              </Col>

              <Col md={4} sm={4} xs={4}>
                <div
                  className={'schedule__filterButton button--small button--small--gold ' + (timePeriods === 'evening' ? 'schedule__filterButton--active' : '')}
                  onClick={this.changePeriod.bind(this, 'evening')}>
                  {lang('evening')}
                </div>
              </Col>
            </Col>
          </Row>

          <CalendarView
            timePeriods={timePeriods}
            showModal
            loader={this.props.loader}
            plan={this.props.plan}
            calendarType="twoweeksplan"
            // Translate function
            lang={lang}
            app={this.props.app}
            nextPage={this.props.nextPage}
            person={this.props.person}
            renderNavButtons={this.renderNavButtons}
          />
        </div>
      </Page>
    );
  }
}

export default TwoWeeksPlan;
