import {TOGGLE_RIGHT_MENU, TOGGLE_LEFT_MENU, OPEN_VERIFY_MODAL, VERIFY_PERSON_FORM, CLOSE_CONFIRM} from './constants';
import {CONFIRM_PROSPECT} from '../../App/middleware/constants';
import {RESET_PERSON, TELLUSMORE_PAGE, PERSONAL_INFO_PAGE, ZONE_CUSTOMER} from '../../App/constants';
import {API_VERIFY_PERSON, API_CONFIRM_AS_PROSPECT, API_CONFIRM_AS_MEMBER, API_SALESPERSONS_NAME} from '../../api/constants';
import {browserHistory} from 'react-router';

const toggleRightMenu = isOpen => {
  return {
    type: TOGGLE_RIGHT_MENU,
    rightMenuOpen: isOpen,
  };
};

const toggleLeftMenu = isOpen => {
  return {
    type: TOGGLE_LEFT_MENU,
    leftMenuOpen: isOpen,
  };
};

const verifyPerson = data => {
  return {
    type: API_VERIFY_PERSON,
    data,
  };
};

const resetPerson = () => {
  browserHistory.push(TELLUSMORE_PAGE);
  return {
    type: RESET_PERSON,
  };
};

const verifyPersonForm = (value, id) => {
  return {
    type: VERIFY_PERSON_FORM,
    value,
    id,
  };
};

const startNewLead = () => {
  return {type: RESET_PERSON};
};

const verifyPassword = value => verifyPersonForm(value, 'password');
const verifyPin = value => verifyPersonForm(value, 'pinCode');

const openModal = (value, action) => {
  return {
    type: OPEN_VERIFY_MODAL,
    value,
    action,
  };
};

const confirmProspect = () => {
  return {
    type: CONFIRM_PROSPECT,
  };
};

const confirmContinue = prospect => {
  if (prospect)
    return {
      type: API_CONFIRM_AS_PROSPECT,
    };
  else
    return {
      type: API_CONFIRM_AS_MEMBER,
    };
};

const closeConfirmModal = () => {
  return {
    type: CLOSE_CONFIRM,
  };
};

const sendForm = (dispatch, type, action, form) => {
  if (type === 'ok') {
    dispatch(verifyPerson(form));
  }
  if (type === 'cancel') {
    dispatch(verifyPersonForm('', 'pinCode'));
    dispatch(verifyPersonForm('', 'password'));
    if (action === 'redirect') browserHistory.goBack();
    return dispatch(openModal(false));
  }
};

const hideModal = (dispatch, value) => {
  dispatch(verifyPersonForm('', 'pinCode'));
  dispatch(verifyPersonForm('', 'password'));
  return dispatch(openModal(value));
};

const onRightMenuToggle = (dispatch, menuOpen, zone, verify, verifyPersonReset) => {
  if (zone === ZONE_CUSTOMER && !verify.verify) return dispatch(openModal(true, 'rightmenu'));
  //zone - customer and right menu is open
  if (verify.verify && !menuOpen) verifyPersonReset();
  return dispatch(toggleRightMenu(menuOpen));
};

const onLeftMenuToggle = (dispatch, menuOpen, app) => {
  return dispatch(toggleLeftMenu(menuOpen));
};

const startNewLeadContainer = dispatch => {
  browserHistory.push(PERSONAL_INFO_PAGE);
  dispatch(startNewLead());
};

const getSalespersonsName = () => {
  return {type: API_SALESPERSONS_NAME};
};

export {
  toggleRightMenu,
  toggleLeftMenu,
  verifyPersonForm,
  verifyPerson,
  resetPerson,
  openModal,
  verifyPassword,
  verifyPin,
  confirmProspect,
  confirmContinue,
  closeConfirmModal,
  sendForm,
  hideModal,
  onLeftMenuToggle,
  onRightMenuToggle,
  startNewLeadContainer,
  getSalespersonsName,
};
