/* eslint-disable react/jsx-no-target-blank */
//FIXME: SKU eslint
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {selectClubInfo, selectLangObj, selectRegion, selectSelectedMembership} from '../../../App/selectors';
import {store} from '../../../App/store';
import Checkbox from '../../../components/Checkbox';

const PrivacyPolicy = React.memo(({privacyPolicy, change, lang, hasPolicyBeenOpened, showError}) => {
  const checkTermsAndConditions = () => {
    if (!hasPolicyBeenOpened) {
      change('hasPolicyBeenOpened')(true);
    }
  };

  const onDisabledCheckboxClick = () => {
    showError('Read T&C first');
  };

  const toggle = () => {
    change('privacyPolicy')(!privacyPolicy);
  };

  const getTAC = () => {
    const state = store.getState();

    const regionCode = {8: 'pt', 9: 'es'}[selectRegion(state).id] || 'en';

    const langObj = selectLangObj(state);
    const clubInfo = selectClubInfo(state);
    const selectedMembership = selectSelectedMembership(state);
    const clubAttachment = clubInfo.attachment.length && clubInfo.attachment.find(a => a.languageId === langObj.id);

    if (selectedMembership.termsAndConditions) {
      return selectedMembership.termsAndConditions;
    }
    if (clubAttachment && clubAttachment.file) {
      return `https://memberjourneyhub.com/files/${clubAttachment.file}`;
    }
    return `https://www.holmesplace.com/${regionCode}/${langObj.code}/privacy-center`;
  };

  const fileUrl = getTAC();
  return (
    <Row>
      <Col md={12}>
        <Checkbox onDisabledCheckboxClick={onDisabledCheckboxClick} disabled={!hasPolicyBeenOpened} onClick={toggle} checked={privacyPolicy}>
          <span>
            {`${lang('I agree with the')} `}
            {fileUrl ? (
              <a href={fileUrl} onClick={checkTermsAndConditions} target="_blank">
                {lang('Terms and Conditions')}
              </a>
            ) : (
              lang('Terms and Conditions')
            )}
            {'.'}
          </span>
        </Checkbox>
      </Col>
    </Row>
  );
});

export default PrivacyPolicy;
