import {SELECT_INDEX_DUPLICATE, API_PERSON_DUPLICATE} from './constants.js';
import {tellusmoreShowModal, showError, createPerson} from '../../Actions/index';

const checkPerson = data => {
  return {
    type: API_PERSON_DUPLICATE,
    data,
  };
};

const selectDuplicate = index => {
  return {type: SELECT_INDEX_DUPLICATE, index};
};

const createPersonContainer = (dispatch, duplicate, type, person, index) => {
  if (type === 'new') {
    dispatch(tellusmoreShowModal(false));
    return dispatch(createPerson(person, type));
  }
  if (type === 'use') {
    if (index === undefined || index === null) return dispatch(showError('Select person'));
    dispatch(tellusmoreShowModal(false));
    return dispatch(createPerson(duplicate[index], type));
  }
};

const checkPersonContainer = (dispatch, person) => {
  if (person.id) return dispatch(createPerson(person));
  return dispatch(checkPerson(person));
};

export {selectDuplicate, checkPerson, createPersonContainer, checkPersonContainer};
