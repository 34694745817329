import React from 'react';
import {browserHistory} from 'react-router';
import {CONTACT_INFORMATION_PAGE, SUMMARY_PAGE, TWO_WEEKS_PLAN_PAGE, WELCOME_PAGE, MEMBERSHIP_PAGE} from '../../App/constants';
import './style.scss';

const RightSidebar = React.memo(props => {
  const checkOpen = show => (show ? 'rightSidebar rightSidebar--active' : 'rightSidebar');

  return (
    <div className={checkOpen(props.show)}>
      <div className={'menu-container'}>
        <div className="salesHub menu-item" onClick={() => browserHistory.push(WELCOME_PAGE)}>
          {props.lang('Home')}
        </div>
        <div className="rightSidebar__trials menu-item" onClick={() => browserHistory.push(MEMBERSHIP_PAGE)}>
          {props.lang('membership plans')}
        </div>
        <div className="prices menu-item" onClick={() => browserHistory.push(SUMMARY_PAGE)}>
          {props.lang('summary')}
        </div>

        <div className="contracts menu-item" onClick={() => browserHistory.push(CONTACT_INFORMATION_PAGE)}>
          {props.lang('contract')}
        </div>

        <div className="plan menu-item" onClick={() => browserHistory.push(TWO_WEEKS_PLAN_PAGE)}>
          {props.lang('2 weeks plan')}
        </div>
        <div className="button--small button--small--gray reset" onClick={props.resetPerson}>
          {props.lang('reset person')}
        </div>

        <div className="logout menu-item" onClick={props.logout()}>
          {props.lang('Log out')}
        </div>
      </div>
    </div>
  );
});

export default RightSidebar;
