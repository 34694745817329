import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import './style.scss';

class Languages extends Component {
  render() {
    let className = `languages ${this.props.className}`;
    return (
      <div className={className}>
        {this.props.languages
          .filter(lang => lang !== 'ca')
          .map((lang, index) => {
            const name = `customStyle club${lang === this.props.language ? ' active-lang' : ''}`;
            return (
              <Button key={`lang${index}`} className={name} onClick={() => this.props.changeLang(lang)}>
                {lang}
              </Button>
            );
          })}
      </div>
    );
  }
}

export default Languages;
