import {fromJS} from 'immutable';
import moment from 'moment';

import {initialState} from '../initialstate';
import {RESET_PERSON, VERIFY_PERSON_RESET, ROUTER_ZONE_TRANSITION, ZONE_SALESPERSON} from '../../constants';
import {CHOOSE_MEMBERSHIP} from '../../../containers/Memberships/constants';
import {CHANGE_START_DATE} from '../../../containers/Summary/constants';
import {SELECT_INDEX_DUPLICATE, TELLUSMORE_LOADER} from '../../../containers/TellUsMore/constants.js';
import {OPEN_VERIFY_MODAL, VERIFY_PERSON_FORM} from '../../../containers/Page/constants';
import {TELLUSMORE_SHOW_MODAL, PERSON_CHANGE_FIELD, PERSON_SET_MEMBERSHIP, LOG_OUT_SUCCESS} from '../../../Actions/constants';
import {API_PERSON_DUPLICATE_SUCCESS, API_CREATE_PERSON_SUCCESS, API_VERIFY_PERSON_SUCCESS} from '../../../api/constants.js';
import {createPerson} from './helpers';

export const personReducer = (state, action) => {
  switch (action.type) {
    case ROUTER_ZONE_TRANSITION:
      return state.setIn(['verifyPerson', 'verify'], action.zoneLast === ZONE_SALESPERSON);
    case RESET_PERSON:
      return state.set('person', initialState.getIn(['personReducer', 'person']));
    case CHOOSE_MEMBERSHIP:
      return state.setIn(['person', 'startDate'], new Date());
    case CHANGE_START_DATE:
      return state.setIn(['person', 'startDate'], moment(action.date).format('YYYY-MM-DD'));
    case TELLUSMORE_SHOW_MODAL:
      return state.setIn(['duplicatePerson', 'showModal'], action.state);
    case TELLUSMORE_LOADER:
      return state.setIn(['duplicatePerson', 'loader'], action.value);
    case PERSON_CHANGE_FIELD:
      return state.setIn(['person', action.id], action.value);
    case PERSON_SET_MEMBERSHIP:
      return state.setIn(['person', 'memberships'], fromJS([action.membership]));
    case API_VERIFY_PERSON_SUCCESS:
      return state
        .setIn(['verifyPerson', 'modal'], false)
        .setIn(['verifyPerson', 'verify'], true)
        .setIn(['verifyPerson', 'password'], '');
    case OPEN_VERIFY_MODAL:
      return state.setIn(['verifyPerson', 'modal'], action.value).setIn(['verifyPerson', 'action'], action.action);
    case VERIFY_PERSON_FORM:
      return state.setIn(['verifyPerson', action.id], action.value);
    case VERIFY_PERSON_RESET:
      return state.setIn(['verifyPerson', 'verify'], false);
    case SELECT_INDEX_DUPLICATE:
      return state.setIn(['duplicatePerson', 'duplicate'], true).setIn(['duplicatePerson', 'selected'], action.index);
    case API_CREATE_PERSON_SUCCESS:
      return createPerson(state, action);
    case API_PERSON_DUPLICATE_SUCCESS:
      return state.set('duplicatePerson', fromJS({duplicate: true, selected: null, duplicates: action.data, loader: false}));
    case LOG_OUT_SUCCESS:
      return fromJS(initialState.get('personReducer'));
    default:
      return state;
  }
};
