import React, {Component} from 'react';
import './style.scss';

/**
 * @props
 * string current current url
 */
class Item extends Component {
  render() {
    const {suffix} = this.props;
    return (
      <div
        style={this.props.style || {}}
        className={`sub-menu-grey__item ${suffix ? `sub-menu-grey${suffix}__item` : ''} ` + (this.props.isCurrent ? 'sub-menu-grey__item--current' : '')}>
        {this.props.children}
      </div>
    );
  }
}

export default Item;
