import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';

import {CONTACT_INFORMATION_PAGE} from '../../App/constants';
import Page from '../../containers/Page';
import Wrapper from '../../components/Wrapper';
import DatePicker from '../../components/DatePicker';
import Loader from '../../components/LoaderExtraSmall';
import FloatingLabels from '../../components/FloatingLabels';

import './style.scss';

import lang from '../../utils/translator';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.getPromoDescription.bind(this);
  }

  componentWillMount() {
    let obj = {time: new Date()};
    if (!this.props.startDate) this.props.changeStartDate(obj);

    if (this.props.promo)
      this.props.countTotalPrice(this.props.clubId, this.props.membership.externalId, this.props.starterPack.externalId, this.props.promo, this.props.startDate);
    else this.props.countTotalPrice(this.props.clubId, this.props.membership.externalId, this.props.starterPack.externalId, null, this.props.startDate);
  }

  getPromoDescription(date) {
    const expirationDate = moment(date);
    const todayDate = moment()
      .add(1, 'month')
      .date(1);
    return expirationDate.diff(todayDate, 'months');
  }

  render() {
    const {contract, membership, starterPack, currency, region} = this.props;

    const isPortugal = region.id === 8;

    return (
      <Page nav navBarType={'nav--dark'}>
        <div className="summary">
          <Wrapper>
            <h2 className={'text-gold text-center heading-md summaryTitle'}>{lang('summary')}</h2>

            {membership && (
              <Row className={'summary__item'}>
                <Col md={9} xs={12}>
                  <Row>
                    {membership.title && (
                      <h4 className={'summary__item__title'}>
                        {lang('membership')}
                        {' - '}
                        {membership.title}
                      </h4>
                    )}
                  </Row>
                  <Row>{membership.description && <div className={'summary__item__content'} dangerouslySetInnerHTML={{__html: membership.description}}></div>}</Row>
                </Col>
                <Col md={3} xs={12}>
                  <h4 className="summary__item__price">
                    {membership.price ? (Number(membership.price) || 0).toFixed(2) : '0'} {membership.currency || currency}
                  </h4>
                </Col>
              </Row>
            )}

            {starterPack && (
              <Row className={'summary__item'}>
                <hr />
                <Col md={9} xs={12}>
                  <Row>
                    {starterPack.title && (
                      <h4 className={'summary__item__title'}>
                        {lang('joining pack')}
                        {' - '}
                        {starterPack.title}
                      </h4>
                    )}
                  </Row>
                  <Row>{starterPack.description && <div className={'summary__item__content'} dangerouslySetInnerHTML={{__html: starterPack.description}}></div>}</Row>
                </Col>
                <Col md={3} xs={12}>
                  <h4 className="summary__item__price">
                    {starterPack.price ? (Number(starterPack.price) || 0).toFixed(2) : '0'} {starterPack.currency || membership.currency || currency}
                  </h4>
                </Col>
              </Row>
            )}

            <Row style={{paddingBottom: 40}} />

            <h2 className={'text-gold text-center heading-md summaryTitle'}>{lang('total due today')}</h2>

            <Row className="summary__item">
              <Col md={9} xs={12}>
                <h4 className="summary__item__title">{lang('current month')}</h4>
              </Col>
              <Col md={3} xs={12}>
                <h4 className="summary__item__price">
                  {contract.proRataPrice ? (contract.proRataPrice || 0).toFixed(2) : '0'} {membership.currency || currency}
                </h4>
              </Col>
            </Row>

            <Row className="summary__item">
              <hr />
              <Col md={9} xs={12}>
                <h4 className="summary__item__title">{lang('joining pack')}</h4>
              </Col>
              <Col md={3} xs={12}>
                <h4 className="summary__item__price">
                  {contract.joiningFeePrice ? (contract.joiningFeePrice || 0).toFixed(2) : '0'} {membership.currency || currency}
                </h4>
              </Col>
            </Row>

            {contract.deposit && (
              <Row className="summary__item">
                <hr />
                <Col md={9} xs={12}>
                  <h4 className="summary__item__title">{lang('advanced payment')}</h4>
                </Col>
                <Col md={3} xs={12}>
                  <h4 className="summary__item__price">
                    {contract ? (contract.deposit || 0).toFixed(2) : '0'} {membership.currency || currency}
                  </h4>
                </Col>
              </Row>
            )}

            <Row className="summary__item">
              <hr />
              <Col md={9} xs={12}>
                <h4 className="summary__item__title">{lang('total')}</h4>
              </Col>
              <Col md={3} xs={12}>
                <h4 className="summary__item__price total">
                  {this.props.loading ? <Loader /> : !contract.totalAmount ? '-' : contract.totalAmount.toFixed(2)} {membership.currency || currency}
                </h4>
              </Col>
            </Row>

            <Row style={{m5ginBottom: '50px'}}>
              <hr />
              <Col md={4} mdOffset={1} className="line-form--gold" style={{marginTop: '30px'}}>
                <FloatingLabels
                  style={{color: '#a3a3a3'}}
                  value={this.props.promo}
                  id={'promo'}
                  errorMsg={!contract.isPromoValid && lang('Invalid Promocode')}
                  onChange={this.props.promoChange(
                    this.props.clubId,
                    this.props.membership.externalId,
                    this.props.starterPack.externalId,
                    this.props.startDate ? this.props.startDate : new Date()
                  )}
                  placeholder={lang('promo code')}
                  search
                />
              </Col>
              <Col md={4} mdOffset={2} className="line-form--gold">
                <div style={{marginTop: '30px'}}>
                  <DatePicker
                    lang={lang}
                    placeholder={lang('start date')}
                    max={this.maxDate()}
                    value={this.props.startDate ? this.props.startDate : ''}
                    onChange={this.changeStartDate.bind(this)}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{paddingBottom: 40}} />

            <h2 className={'text-gold text-center heading-md summaryTitle'}>{lang('future payments')}</h2>
            {contract.startupCampaignEndDate && (contract.startupCampaignPrice || contract.startupCampaignPrice === 0) && (
              <Row className={'summary__item'}>
                <Col md={9} xs={12}>
                  <h4 className={'summary__item__title'}>{lang(isPortugal ? 'NEXT MONTH' : 'SPECIAL OFFER')}</h4>
                  <div className={'summary__item__date'}>
                    {`(${moment()
                      .add(1, 'month')
                      .startOf('month')
                      .format('DD/MM')} - 
											${
                        moment(contract.startupCampaignEndDate).isBefore(
                          moment()
                            .add(1, 'month')
                            .startOf('month')
                        )
                          ? moment(
                              moment()
                                .add(1, 'month')
                                .startOf('month')
                            )
                              .endOf('month')
                              .format('DD/MM/YYYY')
                          : moment(contract.startupCampaignEndDate).format('DD/MM/YYYY')
                      })`}
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <h4 className="summary__item__price">
                    {contract.startupCampaignPrice.toFixed(2)} {membership.currency || currency}
                  </h4>
                </Col>
              </Row>
            )}

            <Row className={'summary__item'}>
              <Col md={9} xs={12}>
                <h4 className={'summary__item__title'}>{lang(isPortugal ? 'FOLLOWING MONTHS(PER MONTH)' : 'MONTHLY FEE')}</h4>
              </Col>
              <Col md={3} xs={12}>
                <h4 className="summary__item__price">
                  {contract.normalPeriodPrice ? (contract.normalPeriodPrice || 0).toFixed(2) : '0'} {membership.currency || currency}
                </h4>
              </Col>
            </Row>

            <div style={{marginTop: 50}}>
              <button className="button" onClick={browserHistory.push.bind(null, CONTACT_INFORMATION_PAGE)}>
                {lang('go to contract')}
              </button>
            </div>
          </Wrapper>
        </div>
      </Page>
    );
  }

  changeStartDate(event) {
    this.props.changeStartDate(event);
    if (this.props.promo) this.props.countTotalPrice(this.props.clubId, this.props.membership.externalId, this.props.starterPack.externalId, this.props.promo, event.time);
    else this.props.countTotalPrice(this.props.clubId, this.props.membership.externalId, this.props.starterPack.externalId, null, event.time);
  }

  maxDate() {
    const d = new Date();
    return new Date(d.setFullYear(d.getFullYear() + 30));
  }

  countPrice() {
    return parseFloat(this.props.membership.price) + parseFloat(this.props.starterPack.price);
  }
}

export default Summary;
