import React from 'react';
import {Row} from 'react-bootstrap';
import Loader from '../../components/LoaderExtraSmall';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import PrivacyPolicy from './components/privacyPolicy';
import PortugalContract from './localeContracts/portugal';
import SpainContract from './localeContracts/spain';
import './style.scss';

const Contract = React.memo(props => {
  const getLocaleComponent = content => {
    if (props.region.id === 8) {
      return (
        <PortugalContract
          person={props.person}
          clubInfo={props.clubInfo}
          selectedPackage={props.selectedPackage}
          selectedMembership={props.selectedMembership}
          contract={props.contract}
          contractInfo={props.contractInfo}
          loading={props.loading}
          currency={props.currency}
          promo={props.promo}
          photo={props.photo}
          signature={props.signature}
          signatureSalesperson={props.signatureSalesperson}
          checkoutData={props.checkoutData}
          lang={lang}
          language={props.language}
          saveSignature={props.saveSignature}
          confirmContract={props.confirmContract}
          tryToSavePhoto={props.tryToSavePhoto}
          payWithCard={props.payWithCard}
          setCheckoutData={props.setCheckoutData}>
          {content}
        </PortugalContract>
      );
    }
    if (props.region.id === 9) {
      return (
        <SpainContract
          person={props.person}
          clubInfo={props.clubInfo}
          selectedPackage={props.selectedPackage}
          selectedMembership={props.selectedMembership}
          contract={props.contract}
          contractInfo={props.contractInfo}
          loading={props.loading}
          currency={props.currency}
          promo={props.promo}
          photo={props.photo}
          signature={props.signature}
          signatureSalesperson={props.signatureSalesperson}
          lang={lang}
          language={props.language}
          saveSignature={props.saveSignature}
          tryToSavePhoto={props.tryToSavePhoto}
          payWithCard={props.payWithCard}>
          {content}
        </SpainContract>
      );
    }
    return <h1>{lang('Region is not ready to sell.')}</h1>;
  };

  return (
    <Page showLeftSidebar={false} nav navBarType={'nav--dark'}>
      <div className="contract-page">
        {props.person ? (
          getLocaleComponent(
            <Row>
              <PrivacyPolicy
                showError={props.showError}
                lang={lang}
                change={props.change}
                privacyPolicy={props.person.privacyPolicy}
                hasPolicyBeenOpened={props.person.hasPolicyBeenOpened}
              />
              <br />
              <br />
              <button
                className={`button ${(props.loading || !props.person.privacyPolicy) && 'disabled'}`}
                onClick={props.confirmContract}
                disabled={props.loading || !props.person.privacyPolicy}>
                {props.loading ? <Loader /> : lang('PAY NOW')}
              </button>
            </Row>
          )
        ) : (
          <h1>{lang('No info about person entered')}</h1>
        )}
      </div>
    </Page>
  );
});

export default Contract;
