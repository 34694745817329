import moment from 'moment-timezone';

const getScheduleEventOccursDate = (startOfWeek, scheduleEvent) => {
  return startOfWeek.add(scheduleEvent.isoWeekday - 1, 'days');
};

export const filterAccordingToCurrentWeek = (datesArray, actualDate) => {
  const newDatesArray = datesArray.map(scheduleEvent => {
    const startOfWeek = moment(actualDate)
      .tz('GMT')
      .startOf('week')
      .startOf('day');
    const scheduleEventOccurDate = getScheduleEventOccursDate(startOfWeek, scheduleEvent);
    const scheduleEventStartDate = moment(scheduleEvent.startDate)
      .tz('GMT')
      .startOf('day');
    const scheduleEventEndDate = moment(scheduleEvent.endDate)
      .tz('GMT')
      .startOf('day');
    if (
      (!scheduleEvent.startDate || scheduleEventStartDate.isSameOrBefore(scheduleEventOccurDate)) &&
      (!scheduleEvent.endDate || scheduleEventEndDate.isSameOrAfter(scheduleEventOccurDate))
    ) {
      return scheduleEvent;
    }
    return null;
  });
  return newDatesArray.filter(Boolean);
};

export const calendarFormatData = data => {
  const sortedData = data.sort((a, b) => parseInt(a.startTime.replace(':', ''), 10) - parseInt(b.startTime.replace(':', '')), 10);

  let longestDay = 0;
  const days = sortedData.reduce((days, day) => {
    const dayIndex = parseInt(day.isoWeekday, 10) - 1;
    if (!days[dayIndex]) days[dayIndex] = [];
    const currentDay = days[dayIndex];
    currentDay.push(day);
    if (currentDay.length > longestDay) longestDay = currentDay.length;
    return days;
  }, []);

  const hours = [];
  for (let i = 0; i < longestDay; i++) {
    for (const dayIndex in days) {
      if (!hours[i]) hours[i] = [];
      hours[i][dayIndex] = days[dayIndex][i] || null;
    }
  }
  return hours;
};
