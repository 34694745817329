import React from 'react';
import {Link} from 'react-router';
import './style.scss';
import lang from '../../utils/translator';

const transformItem = (item, type) => {
  if (!item) return null;
  const {data: itemData} = item.content.find(c => c.component === type);
  return {
    id: item.id,
    url: item.url,
    date: item.publishedFrom,
    title: itemData.title,
    imgURl: itemData.image && itemData.image.url,
  };
};

const ClubTourItem = ({item, type}) => {
  const textAlign = type === 'Next' ? 'left' : 'right';
  const text = (
    <div className="text-container" style={{textAlign}}>
      <p>{lang(type)}</p>
      <h2>{item.title}</h2>
    </div>
  );

  return (
    <Link key={item.id} to={item.url}>
      <div className="item-container">
        {type === 'Previous' && text}
        <img className="nav-img" src={item.imgURl} alt="Snow" />
        {type === 'Next' && text}
      </div>
    </Link>
  );
};

const ClubTourItemNav = ({closestItems, type}) => {
  const prevItem = transformItem(closestItems[0], type);
  const nextItem = transformItem(closestItems[1], type);

  return (
    <div className="container">
      {prevItem && <ClubTourItem item={prevItem} type={'Previous'} />}
      {nextItem && <ClubTourItem item={nextItem} type={'Next'} />}
    </div>
  );
};

export default ClubTourItemNav;
