import moment from 'moment';
import React, {PureComponent} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import Wrapper from '../../../components/Wrapper/index';
import getDateFormat from '../../../utils/helpers/getDateFormat';
import ContractFooter from '../components/ContractFooter';
import ContractHeader from '../components/ContractHeader';
import FieldRenderer from '../components/FieldRenderer';
import FuturePayments from '../components/FuturePayments';
import InicialPayment from '../components/InicialPayment';
import MemberInfo from '../components/MemberInfo';
import {getInObj, transformData} from '../helpers';

class PortugalContract extends PureComponent {
  state = {
    agree: true,
    scriptInjected: false,
  };

  componentDidMount() {
    this.props.setCheckoutData(null);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.checkoutData && !this.state.scriptInjected) {
      this.setState({scriptInjected: true}, () => {
        const script = document.createElement('script');
        const url = process.env.SIBS_PAYMENT_WIDGET_URL || 'https://oppwa.com/v1/paymentWidgets.js';
        script.src = `${url}?checkoutId=${newProps.checkoutData.id}`;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }

  getRenderData() {
    const headerFields = [[{label: this.props.lang('CLUB'), value: getInObj(this.props, ['clubInfo', 'club', 'name'], ''), size: 12}]];

    const memberInfoFields = [
      [
        {label: this.props.lang('NAME'), value: getInObj(this.props, ['person', 'name'], ''), size: 4},
        {label: this.props.lang('SURNAME'), value: getInObj(this.props, ['person', 'surname'], ''), size: 4},
        {
          label: this.props.lang('GENDER'),
          value: transformData(getInObj(this.props, ['person', 'gender'], ''), 'gender'),
          size: 4,
        },
      ],
      [
        {
          label: this.props.lang('DATE OF BIRTH'),
          value: moment(getInObj(this.props, ['person', 'birthDate'], '')).format(getDateFormat(8)),
          size: 4,
        },
        {label: this.props.lang('ADRESS'), value: getInObj(this.props, ['person', 'street'], ''), size: 8},
      ],
      [
        {label: this.props.lang('POSTAL CODE'), value: getInObj(this.props, ['person', 'postalCode'], ''), size: 4},
        {label: this.props.lang('CITY'), value: getInObj(this.props, ['person', 'city'], ''), size: 4},
        {
          label: this.props.lang('DISTRICT'),
          value: transformData(getInObj(this.props, ['person', 'district'], ''), 'district'),
          size: 4,
        },
      ],
      [
        {
          label: this.props.lang('PHONE NUMBER'),
          value: `${getInObj(this.props, ['person', 'phoneCode'], '')} ${getInObj(this.props, ['person', 'mobile'], '')}`,
          size: 4,
        },
        {label: this.props.lang('E-MAIL'), value: getInObj(this.props, ['person', 'email'], ''), size: 4},
        {
          label: this.props.lang(`${getInObj(this.props, ['person', 'nif']) ? 'FISCAL NUMBER' : 'PASSPORT'}`),
          value: getInObj(this.props, ['person', 'nif']) || getInObj(this.props, ['person', 'passport']),
          size: 4,
        },
      ],
      [
        {
          label: this.props.lang('CATEGORY'),
          value: `${getInObj(this.props, ['selectedMembership', 'title'], '')} (${getInObj(this.props, ['selectedMembership', 'externalId'], '')})`,
          size: 6,
        },
        {
          label: this.props.lang('STARTING DATE'),
          value: moment(getInObj(this.props, ['person', 'startDate'])).format(getDateFormat(8)),
          size: 6,
        },
      ],
    ];

    const inicialPaymentFields = [
      [
        {
          label: this.props.lang('CURRENT MONTH'),
          value: `${getInObj(this.props, ['contractInfo', 'proRataPrice'], '0')} ${getInObj(this.props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: this.props.lang('JOINING PACK'),
          value: `${getInObj(this.props, ['contractInfo', 'joiningFeePrice'], '0')} ${getInObj(this.props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: this.props.lang('TOTAL'),
          value: `${getInObj(this.props, ['contractInfo', 'totalAmount'], '0')} ${getInObj(this.props, ['currency'], '')}`,
          size: 12,
        },
      ],
    ];

    const futurePaymentsFields = [
      [
        {
          label: this.props.lang('FOLLOWING MONTHS(PER MONTH)'),
          value: `${getInObj(this.props, ['contractInfo', 'normalPeriodPrice'], '0')} ${getInObj(this.props, ['currency'], '')}`,
          size: 12,
        },
      ],
      [
        {
          label: this.props.lang('STARTING'),
          value: moment(getInObj(this.props, ['person', 'startDate'], Date.now()))
            .add(1, 'month')
            .startOf('month')
            .format('D/M/Y'),
          size: 12,
        },
      ],
    ];

    if (this.props.contractInfo.startupCampaignEndDate) {
      const date = `(${moment()
        .add(1, 'month')
        .startOf('month')
        .format('DD/MM')} - 
			${
        moment(this.props.contractInfo.startupCampaignEndDate).isBefore(
          moment()
            .add(1, 'month')
            .startOf('month')
        )
          ? moment(
              moment()
                .add(1, 'month')
                .startOf('month')
            )
              .endOf('month')
              .format('DD/MM/YYYY')
          : moment(this.props.contractInfo.startupCampaignEndDate).format('DD/MM/YYYY')
      })`;

      futurePaymentsFields.unshift([
        {
          label: `${this.props.lang('NEXT MONTH')} ${date}`,
          value: `${getInObj(this.props, ['contractInfo', 'startupCampaignPrice'], '0')} ${getInObj(this.props, ['currency'], '')}`,
          size: 12,
        },
      ]);
    }

    return {
      headerFields,
      memberInfoFields,
      inicialPaymentFields,
      futurePaymentsFields,
    };
  }

  render() {
    const renderData = this.getRenderData();
    return (
      <Wrapper>
        <div className="contract">
          <ContractHeader lang={this.props.lang} tryToSavePhoto={this.props.tryToSavePhoto} photo={this.props.photo}>
            <FieldRenderer rows={renderData.headerFields} />
          </ContractHeader>
          <br />
          <MemberInfo tryToSavePhoto={this.props.tryToSavePhoto} photo={this.props.photo} lang={this.props.lang}>
            <FieldRenderer rows={renderData.memberInfoFields} />
          </MemberInfo>
          <br />
          <Row>
            <Col md={6}>
              <InicialPayment lang={this.props.lang}>
                <FieldRenderer rows={renderData.inicialPaymentFields} />
              </InicialPayment>
            </Col>
            <Col md={6}>
              <FuturePayments lang={this.props.lang}>
                <FieldRenderer rows={renderData.futurePaymentsFields} />
              </FuturePayments>
            </Col>
          </Row>
          <br />
          <ContractFooter
            signature={this.props.signature}
            signatureSalesperson={this.props.signatureSalesperson}
            saveSignature={this.props.saveSignature}
            payWithCard={this.props.payWithCard}
            clubInfo={this.props.clubInfo}
            language={this.props.language}
            lang={this.props.lang}
          />
        </div>
        {this.props.children}
        {this.props.checkoutData && (
          <Modal.Dialog className="paymentModal" style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
            <Modal.Header>
              <Modal.Title>{this.props.lang('Payment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                hidden={!this.state.agree}
                className={'text-center'}
                dangerouslySetInnerHTML={{
                  __html: `<form action="${window.location.origin}/twoweeksplan?checkout=${this.props.checkoutData.reference}&club=${this.props.clubInfo.club.externalId}" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>`,
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.setCheckoutData(null);
                }}>
                {this.props.lang('Close')}
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        )}
      </Wrapper>
    );
  }
}

export default PortugalContract;
