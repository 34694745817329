import React from 'react';
import {Button, MenuItem, DropdownButton, FormControl, FormGroup} from 'react-bootstrap';
import './style.scss';

const LoginForm = React.memo(props => {
  return (
    <div className="LoginForm">
      <p>{props.lang('Please Log in to access your sales dashboard')}</p>
      <FormGroup className="region-dropdown">
        <DropdownButton
          key="dropdownCountry"
          className="dropdown-customStyle region"
          id="Country"
          title={props.selectedRegion || props.lang('select region')}
          onSelect={props.onSelectRegion}
          onChange={props.onChange}>
          {props.regions
            .sort((a, b) => (a < b ? -1 : 1))
            .map((region, i) => (
              <MenuItem key={`regionId${i}`} eventKey={region}>
                {region}
              </MenuItem>
            ))}
        </DropdownButton>
      </FormGroup>
      <FormGroup className="clubs-dropdown">
        <DropdownButton
          key="dropdown"
          id="Club"
          className="dropdown-customStyle club"
          title={props.club || props.lang('select club')}
          onSelect={props.changeClub}
          onChange={props.onChange}>
          {props.allClubs
            .filter(club => club.status === 'active')
            .map(club => (
              <MenuItem key={`clubId${club.id}`} eventKey={club.id}>
                {club.name}
              </MenuItem>
            ))}
        </DropdownButton>
      </FormGroup>
      <FormGroup>
        <FormControl type="text" className="username" placeholder={props.lang('username')} id="username" onChange={props.onChange} value={props.username} />
      </FormGroup>
      <FormGroup>
        <FormControl type="password" className="password" placeholder={props.lang('password')} id="password" onChange={props.onChange} value={props.password} />
      </FormGroup>
      <FormGroup>
        <FormControl type="text" className={props.lang('pin')} placeholder="PIN" id="pinCode" onChange={props.onChange} value={props.pinCode} />
      </FormGroup>
      <FormGroup>
        <Button onClick={props.onClick} className={'button'}>
          {props.lang('log in')}
        </Button>
      </FormGroup>
    </div>
  );
});

export default LoginForm;
