import {SAVE_SIGNATURE, SAVE_PHOTO} from './constants';
import {API_CONFIRM_AS_MEMBER} from '../../api/constants';
import {showError} from '../../Actions';
import * as errors from '../../App/errorConstatns';

export function saveSignature(name, salesPerson, signature) {
  return {
    type: SAVE_SIGNATURE,
    name,
    salesPerson,
    signature,
  };
}

export function confirmContract() {
  return {
    type: API_CONFIRM_AS_MEMBER,
  };
}

function savePhoto(photo) {
  return {
    type: SAVE_PHOTO,
    photo,
  };
}

export function tryToSavePhoto(event) {
  return dispatch => {
    try {
      const file = event.target.files[0];

      if (file.type !== 'image/jpeg') {
        const FileTypeError = new Error();
        FileTypeError.name = 'FileTypeError';
        throw FileTypeError;
      }

      const reader = new FileReader();
      const img = new Image();

      reader.addEventListener(
        'load',
        () => {
          img.addEventListener('load', () => {
            let urlData = img.src;
            if (getSizeInKb(urlData) > 199) urlData = lowerImageQuality(img, 0.9);

            dispatch(savePhoto(urlData));
          });
          img.src = reader.result;
        },
        false
      );

      if (file) reader.readAsDataURL(file);
    } catch (e) {
      if (e.name === 'FileTypeError') dispatch(showError(errors.WRONG_FILE_TYPE));
      else dispatch(showError(errors.UNKNOWN_FILE_ERROR));
    }
  };
}

const lowerImageQuality = (img, quality) => {
  if (quality < 0.5) throw new Error();

  const canvas = document.createElement('canvas');
  canvas.width = 1024;
  canvas.height = (img.height / img.width) * canvas.width;
  canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
  const dataUrl = canvas.toDataURL('image/jpeg', quality);

  if (getSizeInKb(dataUrl) > 199) return lowerImageQuality(img, quality - 0.1);
  else return dataUrl;
};

const getSizeInKb = urlData => (urlData.length * 3) / 4 / 1000;
