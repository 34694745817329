import moment from 'moment';
import React from 'react';
import {Link} from 'react-router';
import {CLUB_TOUR_EVENTS_PAGE} from '../../../App/constants.js';
import {NO_EVENTS_FOUND} from '../../../App/errorConstatns';
import ClubTourSubMenu from '../../../components/ClubTourSubMenu';
import Heading from '../../../components/Heading';
import HeadingBlock from '../../../components/HeadingBlock';
import TileSquare from '../../../components/TileSquare';
import TileSquareContentBlock from '../../../components/TileSquare/ContentBlock';
import TileSquareMainText from '../../../components/TileSquare/MainText';
import TileSquareSubText from '../../../components/TileSquare/SubText';
import lang from '../../../utils/translator';
import Page from '../../Page';
import {getInObj} from '../helpers';
import './style.scss';

const Events = React.memo(({events: allEvents, currentClubId}) => {
  const events = allEvents
    .map(event => {
      const data = event.content.find(c => c.component === 'EventDetail');
      if (!data) return null;
      const result = data;
      result.id = event.structureId;
      result.url = event.url;
      result.publishedTo = event.publishedTo;
      result.publishedFrom = event.publishedFrom;
      return result;
    })
    .filter(Boolean);

  const filterEvents = ({publishedTo, publishedFrom, clubId}) => {
    if (!publishedFrom || !publishedTo) return false;
    const getMomentDate = isoDate => moment(isoDate);
    return moment().isBetween(getMomentDate(publishedFrom), getMomentDate(publishedTo));
  };

  const sortEvents = ({data: e1}, {data: e2}) => {
    const [e1Hour, e1Minute] = (e1.startTime && e1.startTime.split(':')) || ['0', '0'];
    const [e2Hour, e2Minute] = (e2.startTime && e2.startTime.split(':')) || ['0', '0'];

    const shouldBeFirst = moment(e1.startDate)
      .set({hour: Number(e2Hour), minute: Number(e2Minute)})
      .isBefore(
        moment(e2.startDate).set({
          hour: Number(e1Hour),
          minute: Number(e1Minute),
        })
      );
    return shouldBeFirst ? -1 : 1;
  };

  const getDateRange = ({startDate, endDate}) => {
    const momentStartDate = startDate && moment(startDate);
    const momentEndDate = endDate && moment(endDate);
    const fullFormat = 'DD.MM.YYYY';
    const dayFormat = 'DD';

    if (!endDate || startDate === endDate) {
      return momentStartDate.format(fullFormat);
    }
    if (momentStartDate.month() === momentEndDate.month() && momentStartDate.year() === momentEndDate.year()) {
      return `${momentStartDate.format(dayFormat)} - ${momentEndDate.format(fullFormat)}`;
    }
    return `${momentStartDate.format(fullFormat)} - ${momentEndDate.format(fullFormat)}`;
  };

  const renderItems = events
    .filter(filterEvents)
    .sort(sortEvents)
    .map(({data, title, id, url}) => {
      if (data) {
        const imageUrl =
          getInObj(data, ['perex', 'url'], '')
            .split(' ')
            .join('%20') ||
          getInObj(data, ['image', 'url'], '')
            .split(' ')
            .join('%20') ||
          '';
        return (
          <Link key={id} to={url}>
            <TileSquare hasFilter backgroundImage={{src: imageUrl, title: ''}}>
              <TileSquareContentBlock>
                <TileSquareMainText>{data.title}</TileSquareMainText>
                <TileSquareSubText>{getDateRange(data)}</TileSquareSubText>
              </TileSquareContentBlock>
            </TileSquare>
          </Link>
        );
      }
      return null;
    })
    .filter(Boolean);

  return (
    <Page nav navBarType={'nav--dark'}>
      <div className="club-tour-events">
        <ClubTourSubMenu current={CLUB_TOUR_EVENTS_PAGE} lang={lang} />
        <HeadingBlock>
          <Heading>{lang('events')}</Heading>
        </HeadingBlock>
        <div className="club-tour-events__tiles-block tile-animated">
          {renderItems.length ? (
            renderItems
          ) : (
            <div className="memberships">
              <h1 className="warning">{lang(NO_EVENTS_FOUND)}</h1>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
});

export default Events;
