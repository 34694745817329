import {API_PRICE_COUNT} from '../../api/constants';
import {CHANGE_START_DATE} from './constants';

let countWithPromo;

export const countTotalPrice = (clubExternalId, membershipExternalId, starterPackExternalId, promo, startDate) => {
  return {
    type: API_PRICE_COUNT,
    clubExternalId,
    membershipExternalId,
    starterPackExternalId,
    promo,
    startDate,
  };
};

export const changeStartDate = date => {
  return {
    type: CHANGE_START_DATE,
    date,
  };
};

export const promoChange = (dispatch, clubExternalId, membershipExternalId, starterPackExternalId, startDate, event) => {
  const promoValue = event.target.value;
  clearTimeout(countWithPromo);
  countWithPromo = setTimeout(() => {
    dispatch(countTotalPrice(clubExternalId, membershipExternalId, starterPackExternalId, promoValue, startDate));
  }, 1000);
};
