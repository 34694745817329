import {connect} from 'react-redux';
import {selectEvents} from './selectors.js';
import {selectLangObj, selectClubId} from '../../../App/selectors';
import EventsComponent from './Events.js';
import {connectLanguage} from '../../../utils/connectLanguage';

const mapStateToProps = state => {
  return {
    events: selectEvents(state),
    currentLang: selectLangObj(state),
    currentClubId: selectClubId(state),
  };
};

const Events = connect(mapStateToProps, null)(EventsComponent);
export default connectLanguage(Events, connect);
