import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

/**
 * @param { Array<Array<{ label: string; value: string | number; size: number }>> } properties
 */
class FieldRenderer extends Component {
  processData() {
    const {rows} = this.props;
    const result = [];

    rows.forEach((cols, rowIndex) => {
      let row = [];
      cols.forEach((col, colIndex) => {
        row.push(
          <Col key={`col_${colIndex}`} md={col.size} className="contract__field">
            <p className="contract__field__label" style={{textTransform: 'uppercase'}}>
              {col.label}
            </p>
            <p className="contract__field__text">{col.value}</p>
            <hr />
          </Col>
        );
      });
      result.push(<Row key={`row_${rowIndex}`}>{row}</Row>);
    });

    return result;
  }

  render() {
    return <div>{this.processData()}</div>;
  }
}

export default FieldRenderer;
