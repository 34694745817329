import {connect} from 'react-redux';
import {selectContacts} from '../../App/selectors';
import {connectLanguage} from '../../utils/connectLanguage';
import ContactsComponent from './Contacts';

const mapStateToProps = state => {
  return {
    contacts: selectContacts(state),
  };
};

const Contacts = connect(mapStateToProps)(ContactsComponent);
export default connectLanguage(Contacts, connect);
