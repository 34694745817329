export default regionId => {
  /*switch(regionId) {
		case 8:
		case 9:
			return 'DD/MM/YYYY';
		default:
			return 'DD.MM.YYYY';
  }
  */

  return 'DD-MM-YYYY';
};
