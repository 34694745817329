import React from 'react';
import {CLUB_TOUR_NEWS_PAGE} from '../../../../App/constants.js';
import {REQUESTED_NEWS_NOT_FOUND} from '../../../../App/errorConstatns';
import ClubTourHeader from '../../../../components/ClubTourHeader';
import ClubTourMore from '../../../../components/ClubTourMore';
import ClubTourNormalizedHtml from '../../../../components/ClubTourNormalizedHtml';
import ClubTourSubMenu from '../../../../components/ClubTourSubMenu';
import ClubTourWrapper from '../../../../components/ClubTourWrapper';
import ClubTourWrapperInner from '../../../../components/ClubTourWrapperInner';
import lang from '../../../../utils/translator';
import Page from '../../../Page';
import {getInObj} from '../../helpers';

const getClosetBlogs = (pages, currentPage) => {
  const currentPageIndex = pages.findIndex(page => page.id === currentPage.id);
  return [pages[currentPageIndex - 1], pages[currentPageIndex + 1]];
};

const Detail = ({allNews, newsItem}) => {
  const blog = newsItem && newsItem.content.find(c => c.component === 'BlogPost');
  const closestBlogs = newsItem ? getClosetBlogs(allNews, newsItem) : [];

  return (
    <Page nav navBarType={'nav--dark'}>
      <div className="club-tour-news-detail">
        <ClubTourSubMenu aboveContent={false} current={CLUB_TOUR_NEWS_PAGE} currentClickable lang={lang} />
        {blog ? (
          <ClubTourWrapper>
            <ClubTourWrapperInner>
              <ClubTourHeader title={getInObj(blog, ['data', 'title'], '')} subtitle={getInObj(blog, ['data', 'subtitle'], '')} banner={getInObj(blog, ['data', 'banner'], {})} />
            </ClubTourWrapperInner>
            <ClubTourWrapperInner>
              <ClubTourNormalizedHtml html={getInObj(blog, ['data', 'text'], '')} />
            </ClubTourWrapperInner>
            {(closestBlogs[0] || closestBlogs[1]) && <ClubTourMore closestItems={closestBlogs} type={'BlogPost'} />}
          </ClubTourWrapper>
        ) : (
          <div>{lang(REQUESTED_NEWS_NOT_FOUND)}</div>
        )}
      </div>
    </Page>
  );
};

export default Detail;
