export function getMorningLessons(data) {
  return data.filter(lesson => lesson.startTime < '12:00');
}

export function getAfternoonLessons(data) {
  return data.filter(lesson => lesson.startTime >= '12:00' && lesson.startTime < '18:00');
}

export function getEveningLessons(data) {
  return data.filter(lesson => lesson.startTime >= '18:00');
}

export function getLessonsByHallId(id) {
  return data => data.filter(lesson => lesson.hallId === parseInt(id));
}

const isInCategories = lesson => categorie => categorie.categoryId === lesson.categoryId;

export function getCategories(data) {
  return data.reduce((categories, lesson) => {
    if (!categories.find(isInCategories(lesson)))
      categories.push({
        categoryId: lesson.categoryId,
        categoryName: lesson.categoryName,
        categoryNameEn: lesson.categoryNameEn,
        categoryNameAlt: lesson.categoryNameAlt,
        color: lesson.color,
      });
    return categories;
  }, []);
}

const isInPrograms = lesson => program => program.programId === lesson.programId;

export function getPrograms(data) {
  return data.reduce((programs, lesson) => {
    if (!programs.find(isInPrograms(lesson)))
      programs.push({
        programId: lesson.programId,
        name: lesson.name,
        nameEn: lesson.nameEn,
        nameAlt: lesson.nameAlt,
        programIcon: lesson.programIcon,
        color: lesson.color,
      });
    return programs;
  }, []);
}

const isInHalls = lesson => hall => hall.hallId === lesson.hallId;

export function getHalls(data) {
  return data.reduce((halls, lesson) => {
    if (!halls.find(isInHalls(lesson)))
      halls.push({
        hallId: lesson.hallId,
        hallName: lesson.hallName,
        hallNameEn: lesson.hallNameEn,
        hallNameAlt: lesson.hallNameAlt,
        color: lesson.color,
      });
    return halls;
  }, []);
}
