import {connect} from 'react-redux';
import moment from 'moment';

import CalendarViewComponent from './CalendarView';
import {addToPlan, getCalendarData, removeFromPlan} from './actions';
import {selectCalendarData} from './selectors';
import {getWeekNumber, selectClub, getLang} from '../../App/selectors';
import {connectLanguage} from '../../utils/connectLanguage';

const mapStateToProps = state => {
  return {
    calendarData: selectCalendarData(state),
    monday: moment()
      .startOf('week')
      .add(getWeekNumber(state), 'd')
      .format('YYYY-MM-DD'),
    weekNumber: getWeekNumber(state),
    clubInfo: selectClub(state),
    week: getWeekNumber(state) > 0 && getWeekNumber(state) < 8 ? 'weekOne' : 'weekTwo',
    language: getLang(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToPlan: (lesson, number) => dispatch(addToPlan(lesson, number)),
    removeFromPlan: (lesson, number) => dispatch(removeFromPlan(lesson, number)),
    getCalendarData: number => dispatch(getCalendarData(number)),
  };
};

const Calendar = connect(mapStateToProps, mapDispatchToProps)(CalendarViewComponent);
export default connectLanguage(Calendar, connect);
