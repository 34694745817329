import {connect} from 'react-redux';
import SummaryComponent from './Summary';
import {connectLanguage} from '../../utils/connectLanguage';

import * as selectors from '../../App/selectors';
import {selectStartDate} from './selectors';
import {countTotalPrice, changeStartDate, promoChange} from './actions';

const mapStateToProps = state => {
  return {
    promo: selectors.selectPromo(state),
    contract: selectors.selectContractInfo(state),
    membership: selectors.selectSelectedMembership(state),
    starterPack: selectors.selectSelectedStarterpack(state),
    clubId: selectors.selectClubExternalId(state),
    loading: selectors.selectLoader(state),
    currency: selectors.selectCurrency(state),
    startDate: selectStartDate(state),
    region: selectors.selectRegion(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    promoChange: (clubExternalId, membershipExternalId, starterPackExternalId, startDate) => event =>
      promoChange(dispatch, clubExternalId, membershipExternalId, starterPackExternalId, startDate, event),

    countTotalPrice: (clubExternalId, membershipExternalId, starterPackExternalId, promo, startDate) =>
      dispatch(countTotalPrice(clubExternalId, membershipExternalId, starterPackExternalId, promo, startDate)),

    changeStartDate: event => dispatch(changeStartDate(event.time)),
  };
};

const Summary = connect(mapStateToProps, mapDispatchToProps)(SummaryComponent);
export default connectLanguage(Summary, connect);
