import React from 'react';
import ConfirmModal from '../../components/ConfirmModal';
import LeftSidebar from '../../components/LeftSidebar';
import Nav from '../../components/Nav';
import RightSidebar from '../../components/RightSidebar';
import VerifyModal from '../../components/VerifyModal/index';
import ErrorBox from '../../containers/ErrorBox';
import lang from '../../utils/translator';
import './style.scss';

class Page extends React.PureComponent {
  componentWillMount() {
    if (!this.props.salespersonsName) this.props.getSalespersonsName();
  }

  render() {
    return (
      <div className="Page">
        <ErrorBox />

        {this.props.nav && (
          <Nav
            salespersonsName={this.props.salespersonsName}
            leftMenuOpen={this.props.leftMenuOpen}
            rightMenuOpen={this.props.rightMenuOpen}
            rightMenuToggle={this.props.onRightMenuToggle}
            leftMenuToggle={this.props.onLeftMenuToggle}
            app={this.props.app}
            zone={this.props.zone}
            logout={this.props.logout}
            languages={this.props.languages}
            language={this.props.lang}
            lang={lang}
            changeLang={this.props.changeLang}
            verify={this.props.verify}
            verifyPersonReset={this.props.verifyPersonReset}
          />
        )}

        <LeftSidebar
          show={this.props.leftMenuOpen}
          logout={this.props.logout}
          languages={this.props.languages}
          language={this.props.lang}
          lang={lang}
          startNewLead={this.props.startNewLead}
          changeLang={this.props.changeLang}
          app={this.props.app}
        />

        <RightSidebar
          show={this.props.rightMenuOpen}
          logout={this.props.logout}
          answers={this.props.answers}
          confirmAsProspect={this.props.confirmAsProspect}
          resetPerson={this.props.resetPerson}
          language={this.props.lang}
          lang={lang}
          zone={this.props.zone}
          changeLang={this.props.changeLang}
          languages={this.props.languages}
        />

        <VerifyModal {...this.props} lang={lang} />

        <ConfirmModal {...this.props} lang={lang} />

        <div className="wrapper debugger" onClick={() => this.props.closeSidebars(this.props.leftMenuOpen, this.props.rightMenuOpen, this.props.zone)}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Page;
