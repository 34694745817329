import {createSelector} from 'reselect';
import _ from 'lodash';
import {validateBirthdate, validateEmail, validatePhoneNumber} from '../utils/validators';

export const getErrors = state => state.getIn(['mainReducer', 'error']);
export const getSuccess = state => state.getIn(['mainReducer', 'success']);
export const getAlertType = state => state.getIn(['mainReducer', 'alertType']);
export const getZone = state => state.getIn(['mainReducer', 'zone']);
export const getClub = state => state.getIn(['mainReducer', 'club', getLangCode(state)]);
export const getContract = state => state.getIn(['contractReducer', 'contract']);
export const getContractInfo = state => state.getIn(['contractReducer', 'contractInfo']);
export const getCheckout = state => state.getIn(['contractReducer', 'checkout']);
export const getClubInfo = state => getClub(state).get('club');
export const getSettings = state => getClubInfo(state).get('settings');
export const getClubUrl = state => getClub(state).get('url');
export const getClasses = state => getClub(state).get('classes');
export const getOffers = state => getClub(state).get('offers');
export const getClubName = state => getClubInfo(state).get('name');
export const getClubExternalId = state => getClubInfo(state).get('externalId');
export const getClublId = state => getClubInfo(state).get('id');
export const getLeftMenuOpen = state => state.getIn(['mainReducer', 'leftMenuOpen']);
export const getRightMenuOpen = state => state.getIn(['mainReducer', 'rightMenuOpen']);
export const getVerifyInfo = state => state.getIn(['personReducer', 'verifyPerson']);
export const getConfirm = state => state.getIn(['mainReducer', 'confirm']);
export const getPerson = state => state.getIn(['personReducer', 'person']);
export const getPayWithCard = state => getPerson(state).get('payWithCard');
export const getNotes = state => getPerson(state).get('notes');
export const getDuplicatePerson = state => state.getIn(['personReducer', 'duplicatePerson']);
export const getCurrency = state => state.getIn(['mainReducer', 'currency']);
export const getMemberships = state => getClub(state).get('memberships');
export const getStarterpacks = state => getClub(state).get('starterpacks');
export const getSelectedMembership = state => getContract(state).get('selectedMembership');
export const getSelectedStarterpack = state => getContract(state).get('selectedPackage');
export const getPromo = state => state.getIn(['contractReducer', 'contract', 'promo']);
export const getActivePromo = state => state.getIn(['mainReducer', 'contract', 'promoValid']);
export const getStartDate = state => getPerson(state).get('startDate');
export const getLoader = state => state.getIn(['mainReducer', 'loader']);
export const getConfirmLoader = state => state.getIn(['mainReducer', 'confirmLoader']);
export const getPhoto = state => state.getIn(['contractReducer', 'contract', 'photo']);
export const getSignature = state => state.getIn(['contractReducer', 'contract', 'signature']);
export const getSignatureSalesperson = state => state.getIn(['contractReducer', 'contract', 'signatureSalesperson']);
export const getPlan = state => state.getIn(['mainReducer', 'plan']);
export const getCalendarData = state => getClub(state).get('classes');
export const getWeekNumber = state => state.getIn(['mainReducer', 'weekNumber']);
export const getPersonName = state => getPerson(state).get('name');
export const getLoginForm = state => state.getIn(['mainReducer', 'login']);
export const getLanguages = state =>
  state
    .getIn(['mainReducer', 'languages'])
    .toJS()
    .map(lang => lang.code);
export const getLang = state => state.getIn(['mainReducer', 'language']);
export const getLangCode = state => state.getIn(['mainReducer', 'language', 'code']);
export const getServices = state => getClub(state).get('services');
export const getClubs = state =>
  selectLoginForm(state)
    .getIn(['allClubs'])
    .toJS();
export const getWelcomeText = state => getClubInfo(state).get('welcomText');
export const getWelcomeTextEn = state => getClubInfo(state).get('welcomTextEn');
export const getLeadInfo = state => state.getIn(['mainReducer', 'leadInfo']);
export const getApp = state => state.getIn(['mainReducer', 'app']);
export const getContacts = state => state.getIn(['mainReducer', 'contacts']);
export const getSalespersonsName = state => state.getIn(['mainReducer', 'salespersonsName']);
export const getSalespersonExternalId = state => state.getIn(['mainReducer', 'salespersonExternalId']);
export const getRegion = state => selectLoginForm(state).getIn(['region']);
export const getSelectedCampaign = state => getContract(state).get('selectedCampaign');
export const getSelectedOffer = state => getContract(state).get('selectedOffer');

// Club
export const selectClub = createSelector([getClub], club => club.toJS());
export const selectClubName = createSelector([getClubName], clubName => clubName);
export const selectClubExternalId = createSelector([getClubExternalId], externalId => externalId);
export const selectClubId = createSelector([getClublId], id => id);
export const selectClubInfo = createSelector([getClubInfo], clubInfo => clubInfo.toJS());
export const selectRegions = createSelector([getClubs], clubs => _.uniq(clubs.map(club => (club.region ? club.region.name : 'No data'))));
export const selectLoginForm = createSelector([getLoginForm], login => login);
export const selectAllClubs = createSelector([getClubs, getRegion], clubsByRegion);
export const selectRegion = createSelector([getClub], club => club.toJS().region);

// Person
export const selectPerson = createSelector([getPerson], person => person && person.toJS());
export const selectVerifyInfo = createSelector([getVerifyInfo], verify => verify && verify.toJS());
export const selectDuplicatePerson = createSelector([getDuplicatePerson], duplicates => duplicates && duplicates.toJS());

// Memberships
export const selectMemberships = createSelector([getMemberships], memberships => memberships && memberships.toJS());
export const selectSelectedMembership = createSelector([getSelectedMembership, getMemberships], (selectedMembership, memberships) => {
  const membership = memberships.find(membership => membership.get('externalId') === selectedMembership);
  return membership && membership.toJS();
});

// Calendar
export const selectWeekNumber = createSelector([getWeekNumber], weekNumber => weekNumber);

// Starterpacks
export const selectSelectedPackage = createSelector([getSelectedStarterpack], packages => packages);
export const selectStarterpacks = createSelector([getStarterpacks], starterpacks => starterpacks.toJS());
export const selectSelectedStarterpack = createSelector([getSelectedStarterpack, getStarterpacks], (selectedPackage, starterpacks) => {
  const starterPack = starterpacks.find(pack => pack.get('externalId') === selectedPackage);
  return starterPack && starterPack.toJS();
});

// Contract
export const selectContract = createSelector([getContract], contract => contract.toJS());
export const selectContractInfo = createSelector([getContractInfo], contract => contract.toJS());
export const selectPromo = createSelector([getPromo], promo => (promo ? promo : ''));
export const selectSignature = createSelector([getSignature], signature => signature);
export const selectSignatureSalesperson = createSelector([getSignatureSalesperson], signature => signature);
export const selectPhoto = createSelector([getPhoto], photo => photo);
export const selectCheckoutData = createSelector([getCheckout], checkoutData => (checkoutData ? checkoutData.toJS() : checkoutData));
export const selectPaymentMethod = createSelector([getPayWithCard], payment => payment);
export const selectSalespersonExternalId = createSelector([getSalespersonExternalId], id => id);

// Club tour
export const selectWelcomeText = createSelector([getWelcomeText], text => text);
export const selectWelcomeTextEn = createSelector([getWelcomeTextEn], text => text);

// Lead tracker
export const selectLeadInfo = createSelector([getLeadInfo], info => info.toJS());
export const selectContacts = createSelector([getContacts], contacts => contacts.toJS());

// GUI
export const selectLoader = createSelector([getLoader], loader => loader);
export const selectConfirmLoader = createSelector([getLoader], loader => loader);

// Options
export const selectCurrency = createSelector([getCurrency], currency => currency);
export const selectLanguages = createSelector([getLanguages], lang => lang);
export const selectLang = createSelector([getLangCode], lang => lang);
export const selectLangObj = createSelector([getLang], lang => lang.toJS());
export const selectZone = createSelector([getZone], zone => zone);
export const selectApp = createSelector([getApp], app => app);

// Validation
function clubsByRegion(clubs, region) {
  if (!region) return clubs;

  return clubs.filter(club => club.region.name === region);
}

export const clubRegionExternalId = state => {
  const clubInfo = selectClubInfo(state);
  return selectAllClubs(state).filter(club => {
    return club.id === clubInfo.id;
  })[0].region.externalId;
};

export const validateField = (state, name) => {
  const person = state.getIn(['personReducer', 'person']).toJS();
  switch (name) {
    case 'email':
      return validateEmail(person[name]);
    case 'mobile':
      return validatePhoneNumber(person[name], person.phoneCode);
    case 'birthDate':
      return validateBirthdate(person[name], name);
    case 'startDate':
      return validateBirthdate(person[name], name);
    case 'identificationNumber':
      return state.get('validNif');
    default:
      return person[name] ? true : false;
  }
};
