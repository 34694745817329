import React, {Component} from 'react';
import './style.scss';

/**
 * Standard usage:
 * <HeadingBlock>
 * 		<Heading>This is H1</Heading>
 * 		<HeadingText>This is the centered text block for heading</HeadingText>
 * </HeadingBlock>
 */
class HeadingBlock extends Component {
  render() {
    return (
      <div className="heading-block">
        <div className="heading-block__content">{this.props.children}</div>
      </div>
    );
  }
}

export default HeadingBlock;
