export function createCustomError(name, message) {
  function customError() {
    this.name = name;
    this.type = 'Custom Error';
    this.message = message || 'No message';
    this.stack = new Error().stack;
  }

  customError.prototype = Object.create(Error.prototype);
  customError.prototype.constructor = customError;

  return customError;
}
