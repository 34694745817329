import React, {Component} from 'react';
import Page from '../../containers/Page';
import Wrapper from '../../components/Wrapper';
import Container from '../../components/Container';
import MembershipsSubMenu from '../../components/MembershipsSubMenu';
import {sortByPriority} from '../../utils/helpers/membershipHelpers';
import {MEMBERSHIP_PAGE} from '../../App/constants.js';
import './style.scss';

import {NO_MEMBERSHIPS_FOUND} from '../../App/errorConstatns';
import lang from '../../utils/translator';

class Memberships extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selectedMemberships,
    };

    this.scrolling = false;
    this.currentScroll = 0;
  }

  shouldScroll(to, duration) {
    if (this.scrolling) return false;
    else this.scrollTo(to, duration);
  }

  scrollTo(to, duration) {
    if (duration <= 10) {
      this.scrolling = false;
      return;
    }
    this.scrolling = true;
    var difference = to - this.carusel.scrollLeft;
    var perTick = (difference / duration) * 10;

    setTimeout(() => {
      this.carusel.scrollLeft = this.carusel.scrollLeft + perTick;
      if (this.carusel.scrollLeft === to) return;
      this.scrollTo(to, duration - 10);
    }, 10);
  }

  select(externalId) {
    return () => {
      if (this.state.selected === externalId) return this.setState({selected: null});

      this.setState({selected: externalId});
    };
  }

  isSelected(externalId) {
    if (this.state.selected === externalId) return true;

    return false;
  }

  renderWarning() {
    return (
      <div className="memberships">
        <h1 className="warning">{lang(NO_MEMBERSHIPS_FOUND)}</h1>
      </div>
    );
  }

  onMembershipClick = membership => () => {
    const gtmObject = {
      event: 'productClick',
      ecommerce: {
        click: {
          membership: {
            name: membership.title,
            id: membership.externalId,
            price: membership.price,
            position: membership.priority,
          },
        },
      },
    };
    if (window && window.dataLayer) {
      window.dataLayer.push(gtmObject);
    }
    this.props.chooseMembership(membership.externalId)();
  };

  renderMemberships() {
    const memberships = this.props.memberships.filter(m => m.dspAvailable === true).sort(sortByPriority);

    return (
      <div className="memberships">
        <MembershipsSubMenu current={MEMBERSHIP_PAGE} membershipsExist={this.props.memberships} packagesExist={this.props.starterpacks} lang={lang} />
        <Wrapper>
          {memberships.length > 3 && (
            <div
              className="carousel-arrows carousel-arrows--right faa-angle-double-right"
              onClick={() => this.scrollTo(this.carusel.scrollLeft + this.carusel.clientWidth - 100, 500)}></div>
          )}
          {memberships.length > 3 && (
            <div
              className="carousel-arrows carousel-arrows--left faa-angle-double-left"
              onClick={() => this.scrollTo(this.carusel.scrollLeft - this.carusel.clientWidth - 100, 500)}></div>
          )}

          <div className="blockSlider" ref={carusel => (this.carusel = carusel)}>
            {memberships.map(membership => (
              <Container
                className="membershipContainer"
                key={membership.externalId}
                title={membership.title}
                price={membership.price}
                currency={this.props.currency}
                description={membership.description}
                active={this.isSelected(membership.externalId)}
                onSelect={this.onMembershipClick(membership)}
                onClick={this.select(membership.externalId)}
                easing="linear"
                lang={lang}
              />
            ))}
          </div>
        </Wrapper>
      </div>
    );
  }

  render() {
    let showMemberships = false;
    if (this.props.memberships && this.props.memberships.length > 0) showMemberships = true;

    return (
      <Page nav navBarType={'nav--dark'}>
        {showMemberships ? this.renderMemberships() : this.renderWarning()}
      </Page>
    );
  }
}

export default Memberships;
