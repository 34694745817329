import React from 'react';
import {Alert, Modal} from 'react-bootstrap';
import Loader from '../LoaderExtraSmall';

const ConfirmModal = React.memo(props => {
  return (
    <Modal show={props.confirm.open} className="Page__modal">
      <Modal.Body>
        <Alert bsStyle={props.confirm.warning ? 'warning' : 'info'}>{props.confirm.msg && props.lang(props.confirm.msg)}</Alert>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={() => props.confirmContinue(props.confirm.prospect)}>
          {(props.confirmLoader && <Loader />) || props.lang('Confirm')}
        </button>
        <button className="btn" onClick={props.closeConfirmModal}>
          {props.lang('close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default ConfirmModal;
