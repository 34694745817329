import {browserHistory} from 'react-router';
import {PERSON_VALIDATE_FORM} from '../../Actions/constants';
import {calendarSetWeekNumber, confirmAsMember, confirmAsProspect, showError} from '../../Actions/index';
import {getCalendarData} from '../../containers/CalendarView/actions';
import {CALENDAR_WEEK_NUMBER} from '../../containers/CalendarView/constants';
import {SUBMIT_CONTACT_INFO} from '../../containers/ContactInformation/constants';
import {CONFIRMED_CONTRACT, CONFIRM_CONTRACT} from '../../containers/Contract/constants';
import {CONTRACT_PAGE, HELLO_PAGE} from '../constants';
import * as errors from '../errorConstatns';
import lang from '../../utils/translator';
import {selectPerson, selectSignature, selectSignatureSalesperson, validateField} from '../selectors';
import {CONFIRM_MEMBER, CONFIRM_PROSPECT} from './constants';

const findNotFilledData = state => {
  const mandatoryData = ['name', 'surname', 'birthDate', 'street', 'city', 'country', 'email', 'mobile', 'startDate', 'postalCode'];
  const customer = state.getIn(['personReducer', 'person']);

  function missingData(data) {
    return !customer.get(data);
  }
  return mandatoryData.filter(missingData);
};

export const validateMiddleware = store => next => action => {
  let state = store.getState();
  switch (action.type) {
    case PERSON_VALIDATE_FORM:
      let notFilledData = findNotFilledData(state) || [];
      let errorsContract = notFilledData.map(key => ({key: key, message: lang('The field is required')}));
      if (!selectPerson(state).name || !selectPerson(state).surname) return store.dispatch(showError(errors.FIELDS_ARE_REQUIRED));
      if (action.formType === 'contract') if (notFilledData.length > 0) return store.dispatch(showError(`missing ${notFilledData}`));

      if (!validateField(state, 'email')) return store.dispatch(showError(errors.INVALID_EMAIL));
      if (!validateField(state, 'mobile')) return store.dispatch(showError(errors.INVALID_PHONE_NUMBER));
      if (!validateField(state, 'birthDate')) return store.dispatch(showError(errors.INVALID_BIRTHDATE));
      if (!selectPerson(state).gender) return store.dispatch(showError(errors.FIELDS_ARE_REQUIRED));
      if (typeof selectPerson(state).gdpr !== 'boolean') return store.dispatch(showError(errors.FIELDS_ARE_REQUIRED));

      if (action.formType === 'contract') {
        if (notFilledData.length === 0) browserHistory.push(CONTRACT_PAGE);
        return store.dispatch({type: SUBMIT_CONTACT_INFO, errors: errorsContract});
      } else if (state.getIn(['mainReducer', 'app']) === 'DSP') {
        browserHistory.push(HELLO_PAGE);
      }
      break;
    case CALENDAR_WEEK_NUMBER:
      store.dispatch(calendarSetWeekNumber(action.number));
      store.dispatch(getCalendarData(action.number));
      break;
    case CONFIRM_PROSPECT:
      function objectContains(obj, propertys) {
        for (let i = 0; i < propertys.length; i++) {
          if (!Object.prototype.hasOwnProperty.call(obj, propertys[i])) return false;

          if (!obj[propertys[i]]) return false;
        }
        return true;
      }
      if (!objectContains(selectPerson(state), ['name', 'surname', 'email', 'birthDate', 'mobile', 'gender'])) return store.dispatch(showError(errors.PERSON_IS_MISSING));
      if (state.getIn(['contractReducer', 'contract', 'selectedMembership']))
        return store.dispatch(
          confirmAsProspect({
            open: true,
            msg: 'are you sure you want select prospect, this person has selected membership',
            warning: true,
            prospect: true,
          })
        );

      return store.dispatch(confirmAsProspect({open: true, msg: 'are you sure you want to continue', warning: false, prospect: true}));
    case CONFIRM_MEMBER:
      if (!selectPerson(state)) return store.dispatch(showError(errors.PERSON_IS_MISSING));

      if (!state.get('contract').get('selectedMembership')) return store.dispatch(showError(errors.MEMBERSHIP_NOT_SELECTED));

      if (!state.get('contract').get('selectedPackage')) return store.dispatch(showError(errors.STARTERPACK_NOT_SELECTED));

      return store.dispatch(confirmAsMember({open: true, msg: 'are you sure you want to continue', warning: false, prospect: false}));
    case CONFIRM_CONTRACT:
      if (!selectSignature(state) || !selectSignatureSalesperson(state)) return store.dispatch(showError(errors.MISSING_SIGNATURE + new Date()));
      return store.dispatch({type: CONFIRMED_CONTRACT, confirmed: true});
    default:
      next(action);
  }
};
