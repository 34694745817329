/* eslint-disable jsx-a11y/anchor-is-valid */
// FIXME: SKU eslint link => button
import React, {PureComponent} from 'react';
import {Col, Row} from 'react-bootstrap';
import {CLUB_TOUR_CLASSES_SCHEDULE_PAGE} from '../../../App/constants';
import ClubTourSubMenu from '../../../components/ClubTourSubMenu';
import DoubleArrow from '../../../components/DoubleArrow';
import {LEFT, RIGHT} from '../../../components/DoubleArrow/constants';
import lang from '../../../utils/translator';
import CalendarView from '../../CalendarView';
import Page from '../../Page';
import './style.scss';

class ClassesSchedule extends PureComponent {
  state = {
    timePeriods: ['morning', 'afternoon', 'evening'],
  };

  getTimeperiod = () => (this.state.timePeriods.length === 1 ? this.state.timePeriods[0] : 'all');

  changePeriod = period => () => {
    if (this.getTimeperiod() === period) period = 'all';
    switch (period) {
      case 'morning':
        this.setState({timePeriods: ['morning']});
        break;
      case 'afternoon':
        this.setState({timePeriods: ['afternoon']});
        break;
      case 'evening':
        this.setState({timePeriods: ['evening']});
        break;
      default:
        this.setState({timePeriods: ['morning', 'afternoon', 'evening']});
    }
  };

  render() {
    return (
      <Page nav navBarType={'nav--dark'}>
        <div className="club-tour-classes-schedule">
          <ClubTourSubMenu aboveContent={false} current={CLUB_TOUR_CLASSES_SCHEDULE_PAGE} lang={lang} />
          <div className="club-tour-classes-schedule__inner">
            <Row className="calendar__arrows">
              <Col lg={3} md={3} xs={3}>
                <div>
                  <h4>{lang('Previous')}</h4>
                  <a className="arrow__link" onClick={() => this.props.loadPrevious(this.props.weekNumber)}>
                    <DoubleArrow orientation={LEFT} />
                  </a>
                </div>
              </Col>
              <Col lg={6} md={6} xs={6}>
                <h2 className="text-gold heading-md text-center calendar__arrows__heading">{lang('classes schedule')}</h2>
              </Col>
              <Col lg={3} md={3} xs={3}>
                <div>
                  <h4>{lang('Next')}</h4>
                  <a className="arrow__link" onClick={() => this.props.loadNext(this.props.weekNumber)}>
                    <DoubleArrow orientation={RIGHT} />
                  </a>
                </div>
              </Col>
            </Row>

            <Row className="schedule__filterButton--holder">
              <Col md={6} mdOffset={3} xs={12}>
                <Col md={4} sm={4} xs={4}>
                  <div
                    className={'schedule__filterButton button--small button--small--gold ' + (this.getTimeperiod() === 'morning' ? 'schedule__filterButton--active' : '')}
                    onClick={this.changePeriod('morning')}>
                    {lang('morning')}
                  </div>
                </Col>

                <Col md={4} sm={4} xs={4}>
                  <div
                    className={'schedule__filterButton button--small button--small--gold ' + (this.getTimeperiod() === 'afternoon' ? 'schedule__filterButton--active' : '')}
                    onClick={this.changePeriod('afternoon')}>
                    {lang('afternoon')}
                  </div>
                </Col>

                <Col md={4} sm={4} xs={4}>
                  <div
                    className={'schedule__filterButton button--small button--small--gold ' + (this.getTimeperiod() === 'evening' ? 'schedule__filterButton--active' : '')}
                    onClick={this.changePeriod('evening')}>
                    {lang('evening')}
                  </div>
                </Col>
              </Col>
            </Row>

            <CalendarView loader={this.props.loader} lang={lang} timePeriods={this.state.timePeriods} />
          </div>
        </div>
      </Page>
    );
  }
}

export default ClassesSchedule;
