import React from 'react';
import List from '../List';
import './style.scss';

const Container = React.memo(({active, onClick, title, price, currency, oneTimePayment, description, list, onSelect, lang}) => {
  const isActive = () => (active ? 'container__card container__card--active' : 'container__card');
  const isContentActive = () => (active ? 'container__card__content container__card__content--active' : 'container__card__content');

  return (
    <div className={isActive()} style={{paddingBottom: 120}} onClick={onClick}>
      {title && <h4 className={'container__card__title'}>{title}</h4>}
      {price && (
        <h1 className={'container__card__price'} style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{fontSize: '1.2em', lineHeight: '0.8em'}}>{price.toString(10).split('.')[0]}</div>
          <div className="col__holder__card__number--small" style={{fontSize: '0.4em', textAlign: 'left'}}>
            <div>
              {'.'}
              {
                Number(price)
                  .toFixed(2)
                  .toString(10)
                  .split('.')[1]
              }{' '}
              {currency}
            </div>
            {!oneTimePayment && <div>{'month'}</div>}
          </div>
        </h1>
      )}
      {description && <div className={isContentActive()} dangerouslySetInnerHTML={{__html: description}}></div>}
      {list && <List data={list} />}
      {onSelect && (
        <button onClick={onSelect} className="button">
          {lang('Activate')}
        </button>
      )}
    </div>
  );
});

export default Container;
