import {browserHistory} from 'react-router';
import {CHOOSE_PACKAGE} from './constants';
import {SUMMARY_PAGE} from '../../App/constants';

function choosePackage(selectedPackage) {
  return dispatch => {
    dispatch({type: CHOOSE_PACKAGE, selectedPackage});
    browserHistory.push(SUMMARY_PAGE);
  };
}

export {choosePackage};
