import React from 'react';
import {Provider} from 'react-redux';
import {browserHistory, Route, Router} from 'react-router';
import {calendarSetWeekNumber, saveURLtoStore, showError, switchApp} from '../Actions/index';
import ClubTourClassesSchedule from '../containers/ClubTour/ClassesSchedule';
import ClubTourEvents from '../containers/ClubTour/Events';
import ClubTourEventDetail from '../containers/ClubTour/Events/Detail';
import ClubTourNews from '../containers/ClubTour/News';
import ClubTourNewsDetail from '../containers/ClubTour/News/Detail';
import ClubTourWelcome from '../containers/ClubTour/Welcome';
import ContactInformation from '../containers/ContactInformation';
import Contacts from '../containers/Contacts';
import Contract from '../containers/Contract';
import EnjoyTheJourney from '../containers/EnjoyTheJourney';
import HelloFirstName from '../containers/HelloFirstName';
import Login from '../containers/Login';
import Memberships from '../containers/Memberships';
import Packages from '../containers/Packages';
import {openModal, verifyPersonForm} from '../containers/Page/actions.js';
import {TOGGLE_LEFT_MENU, TOGGLE_RIGHT_MENU} from '../containers/Page/constants';
import Summary from '../containers/Summary';
import TellUsMore from '../containers/TellUsMore';
import ThankYou from '../containers/ThankYou';
import TwoWeeksPlan from '../containers/TwoWeeksPlan';
import Welcome from '../containers/Welcome';
import translator from '../utils/translator';
import {routerZoneTransition} from './actions';
import './App.scss';
import * as constants from './constants';
import * as errors from './errorConstatns';
import './faarrows.scss';
import * as selectors from './selectors';
import {store} from './store';
import './variables.scss';

function simpleMiddleware() {
  const functions = arguments;
  return (nextState, replace) => {
    for (let i = 0; i < functions.length; i++) if (!functions[i](nextState, replace)) break;
  };
}

function objectContains(obj, properties) {
  for (let i = 0; i < properties.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(obj, properties[i])) {
      return false;
    }

    if (!obj[properties[i]]) {
      return false;
    }
  }
  return true;
}

class App extends React.PureComponent {
  render() {
    const requireAuth = (nextState, replace) => {
      if (
        !store
          .getState()
          .get('mainReducer')
          .toJS().accessToken
      ) {
        replace({pathname: constants.LOGIN_PAGE});
        return false;
      }

      return true;
    };

    const checkLogin = (nextState, replace) => {
      if (
        store
          .getState()
          .get('mainReducer')
          .toJS().accessToken
      ) {
        replace({pathname: constants.WELCOME_PAGE});
        return false;
      }
    };

    const requirePerson = (nextState, replace) => {
      if (!objectContains(selectors.selectPerson(store.getState()), ['name', 'surname', 'email', 'birthDate', 'mobile', 'gender'])) {
        if (store.getState().getIn(['mainReducer', 'app']) === 'DSP') {
          replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
          store.dispatch(verifyPersonForm(false, 'verify'));
          store.dispatch(verifyPersonForm(false, 'modal'));
          store.dispatch(routerZoneTransition(constants.ZONE_CUSTOMER));
        } else replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        store.dispatch(showError(errors.PERSON_IS_MISSING));
        return false;
      }

      return true;
    };

    const requireSelectedMembership = (nextState, replace) => {
      if (!selectors.selectContract(store.getState()).selectedMembership) {
        store.dispatch(showError(errors.MEMBERSHIP_NOT_SELECTED));
        replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        return false;
      }

      return true;
    };

    const requireSelectedPackage = (nextState, replace) => {
      if (!selectors.selectContract(store.getState()).selectedPackage) {
        replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        store.dispatch(showError(errors.STARTERPACK_NOT_SELECTED));
        return false;
      }

      return true;
    };

    const requireContractInfo = (nextState, replace) => {
      if (!objectContains(selectors.selectPerson(store.getState()), ['name', 'surname', 'birthDate', 'street', 'city', 'country', 'email', 'mobile', 'startDate', 'postalCode'])) {
        replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        store.dispatch(showError(errors.CONTRACT_INFO_MISSING));
        return false;
      }

      return true;
    };

    const requireContract = (nextState, replace) => {
      if (!selectors.selectContractInfo(store.getState()).totalAmount) {
        store.dispatch(showError(translator(errors.NO_CONTRACT)));
        replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        return false;
      }

      return true;
    };

    const requireMembership = (nextState, replace) => {
      if (!store.getState().getIn(['personReducer', 'person', 'memberships', 0, 'id'])) {
        replace({pathname: store.getState().getIn(['mainReducer', 'URL'])});
        store.dispatch(showError(errors.MEMBERSHIP_REQUIRED));
        return false;
      }

      return true;
    };

    const changeToLeadTracker = (nextState, replace) => {
      if (store.getState().getIn(['mainReducer', 'app']) !== 'LT') store.dispatch(switchApp('LT'));

      return true;
    };

    const changeToDSP = (nextState, replace) => {
      if (store.getState().getIn(['mainReducer', 'app']) !== 'DSP') store.dispatch(switchApp('DSP'));

      return true;
    };

    const saveURL = (nextState, replace) => {
      return store.dispatch(saveURLtoStore(window.location.pathname));
    };

    const enterZone = zone => (nextState, replace) => {
      let lastZone = store.getState().getIn(['mainReducer', 'zone']);

      //customer to salesperson
      if (zone === constants.ZONE_SALESPERSON)
        if (lastZone === constants.ZONE_CUSTOMER) if (!selectors.selectVerifyInfo(store.getState()).verify) store.dispatch(openModal(true, 'redirect'));

      store.dispatch(verifyPersonForm(false, 'modal'));
      store.dispatch(routerZoneTransition(zone));
      return true;
    };
    const enterZoneCustomer = enterZone(constants.ZONE_CUSTOMER);
    const enterZoneSalesperson = enterZone(constants.ZONE_SALESPERSON);

    const sidebarsHide = () => {
      if (store.getState().getIn(['mainReducer', 'leftMenuOpen'])) store.dispatch({type: TOGGLE_LEFT_MENU, leftMenuOpen: false});
      if (store.getState().getIn(['mainReducer', 'rightMenuOpen'])) store.dispatch({type: TOGGLE_RIGHT_MENU, rightMenuOpen: false});

      return true;
    };

    const setCalendarDate = () => {
      store.dispatch(calendarSetWeekNumber(1));
      return true;
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
      return true;
    };

    /**
     * Zones are just used
     *
     * Apparently this works with ReactRouter (but it's no use inheriting from Route
     * to get a common behavior for Zones)
     **/
    const Zone = () => {};

    return (
      <Provider store={store}>
        <div>
          <Router history={browserHistory}>
            <Zone onEnter={simpleMiddleware(enterZoneSalesperson)}>
              <Route path={constants.LOGIN_PAGE} component={Login} onEnter={simpleMiddleware(checkLogin, sidebarsHide, scrollToTop, changeToDSP, saveURL)} />
              <Route
                path={constants.MEMBERSHIP_PAGE}
                component={Memberships}
                onEnter={simpleMiddleware(requireAuth, scrollToTop, requirePerson, sidebarsHide, changeToDSP, saveURL)}
              />
              <Route
                path={constants.PACKAGES_PAGE}
                component={Packages}
                onEnter={simpleMiddleware(requireAuth, scrollToTop, requireSelectedMembership, requirePerson, sidebarsHide, changeToDSP, saveURL)}
              />
              <Route
                path={constants.SUMMARY_PAGE}
                component={Summary}
                onEnter={simpleMiddleware(requireAuth, scrollToTop, requireSelectedPackage, requireSelectedMembership, requirePerson, sidebarsHide, changeToDSP, saveURL)}
              />
              <Route
                path={constants.CONTACT_INFORMATION_PAGE}
                component={ContactInformation}
                onEnter={simpleMiddleware(
                  requireAuth,
                  scrollToTop,
                  requireSelectedPackage,
                  requireSelectedMembership,
                  requirePerson,
                  requireContract,
                  sidebarsHide,
                  changeToDSP,
                  saveURL
                )}
              />
              <Route
                path={constants.CONTRACT_PAGE}
                component={Contract}
                onEnter={simpleMiddleware(
                  requireAuth,
                  requireSelectedPackage,
                  requireContract,
                  requireContractInfo,
                  requireSelectedMembership,
                  requirePerson,
                  scrollToTop,
                  sidebarsHide,
                  changeToDSP,
                  saveURL
                )}
              />
            </Zone>
            <Zone onEnter={simpleMiddleware(enterZoneCustomer)}>
              <Route path={constants.HOME_PAGE} component={Login} onEnter={simpleMiddleware(checkLogin, sidebarsHide, scrollToTop, changeToDSP, saveURL)} />
              <Route path={constants.HELLO_PAGE} component={HelloFirstName} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route path={constants.TELLUSMORE_PAGE} component={TellUsMore} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route path={constants.WELCOME_PAGE} component={Welcome} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route path={constants.CLUB_TOUR_WELCOME_PAGE} component={ClubTourWelcome} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route
                path={constants.CLUB_TOUR_CLASSES_SCHEDULE_PAGE}
                component={ClubTourClassesSchedule}
                onEnter={simpleMiddleware(requireAuth, setCalendarDate, scrollToTop, sidebarsHide, changeToDSP, saveURL)}
              />
              <Route path={constants.CLUB_TOUR_NEWS_PAGE} component={ClubTourNews} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route
                path={constants.CLUB_TOUR_NEWS_DETAIL_PAGE}
                component={ClubTourNewsDetail}
                onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)}
              />
              <Route path={constants.CLUB_TOUR_EVENTS_PAGE} component={ClubTourEvents} onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)} />
              <Route
                path={constants.CLUB_TOUR_EVENTS_DETAIL_PAGE}
                component={ClubTourEventDetail}
                onEnter={simpleMiddleware(requireAuth, scrollToTop, sidebarsHide, changeToDSP, saveURL)}
              />
            </Zone>
            <Zone onEnter={simpleMiddleware(enterZoneSalesperson)}>
              <Route path={constants.ENJOY_PAGE} component={EnjoyTheJourney} onEnter={simpleMiddleware(requireAuth, sidebarsHide, changeToLeadTracker, saveURL)} />
              <Route path={constants.PERSONAL_INFO_PAGE} component={TellUsMore} onEnter={simpleMiddleware(requireAuth, sidebarsHide, changeToLeadTracker, saveURL)} />
              <Route path={constants.CONTACTS_PAGE} component={Contacts} onEnter={simpleMiddleware(requireAuth, sidebarsHide, changeToLeadTracker, saveURL)} />
            </Zone>
            <Zone>
              <Route path={constants.THANKYOU_PAGE} component={ThankYou} onEnter={simpleMiddleware(requireAuth, sidebarsHide, saveURL)} />
              <Route
                path={constants.TWO_WEEKS_PLAN_PAGE}
                component={TwoWeeksPlan}
                onEnter={simpleMiddleware(requireAuth, setCalendarDate, requireMembership, scrollToTop, sidebarsHide, saveURL)}
              />
            </Zone>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
