import React, {PureComponent} from 'react';
import {Button, Col, FormGroup, Row} from 'react-bootstrap';
import {SUMMARY_PAGE} from '../../App/constants';
import Wrapper from '../../components/Wrapper/index';
import Page from '../../containers/Page';
import lang from '../../utils/translator';
import GDPR from './components/gdpr';
import {checkForErrors, getContractFormFields, getDirectDebitFormFields, hasErrors, renderField, getNotesField} from './helpers';
import cardSvg from './images/card.svg';
import cashSvg from './images/cash.svg';
import './style.scss';

class ContactInformation extends PureComponent {
  state = {
    errors: {},
  };

  hasErrors() {
    return hasErrors(getContractFormFields(this.props.clubInfo, this.props.personalInfo, lang), this.props.personalInfo);
  }

  sendFormIfValid = e => {
    if (!this.hasErrors()) return this.props.submit(e);
  };

  checkErrors(personalInfo = null) {
    this.setState({
      errors: checkForErrors(getContractFormFields(this.props.clubInfo, this.props.personalInfo, lang), personalInfo === null ? this.props.personalInfo : personalInfo),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.checkErrors(nextProps.personalInfo);
  }

  componentDidMount() {
    this.setDefaultCountry();
    this.checkErrors();
  }

  setDefaultCountry() {
    this.props.change('country')((this.props.country === 'Spain' && 'ES') || (this.props.country === 'Portugal' && 'PT'));
  }

  getButtons(errors) {
    const styles = {
      margin: 8,
      minWidth: 160,
      verticalAlign: 'middle',
    };

    return (
      <div>
        <Button
          style={styles}
          className={'button cardPay'}
          type={'submit'}
          title={'Pay with card'}
          onClick={e => {
            this.props.change('payWithCard')(true);
            this.sendFormIfValid(e);
          }}
          disabled={errors}>
          <img src={cardSvg} alt="cashSVG" />
        </Button>
        <Button
          style={styles}
          className={'button cashPay'}
          type={'submit'}
          title={'Pay on reception'}
          onClick={e => {
            this.props.change('payWithCard')(false);
            this.sendFormIfValid(e);
          }}
          disabled={errors}>
          <img src={cashSvg} alt="cashSVG" />
        </Button>
      </div>
    );
  }

  render() {
    const {region, clubInfo, personalInfo, change, gdprOption} = this.props;

    return (
      <Page nav navBarType={'nav--dark'} back={SUMMARY_PAGE}>
        <div className="recap contract-info">
          <Wrapper>
            <Col md={12}>
              <h2 className={'heading-md text-gold text-center'}>{lang('Contract information')}</h2>
            </Col>
            <FormGroup style={{width: '100%'}} className="line-form--gold input-group">
              <Row>
                <h3 className="contract-info__heading">{lang('Personal information')}</h3>
                {getContractFormFields(clubInfo, personalInfo, lang).map(field => renderField(field, personalInfo, change, this.state.errors, region.externalId))}
              </Row>
              <Row>
                <h3 className="contract-info__heading">{lang('Direct debit')}</h3>
                {getDirectDebitFormFields(clubInfo, personalInfo, lang).map(field => {
                  return renderField(field, personalInfo, change, this.state.errors, region.externalId);
                })}
                <div style={{textAlign: 'left'}} className={'line-form--gold line-form col-xs-12'}>
                  {lang('We will only use your bank details if you finalize your inscription. They will be used for your future monthly subscription payments.')}
                </div>
              </Row>
              <Row>
                <h3 className="contract-info__heading">{lang('Notes')}</h3>
                {renderField(getNotesField(), personalInfo, change, this.state.errors, region.externalId)}
              </Row>
              <Row>
                <GDPR value={gdprOption} onChange={change('gdpr')} lang={lang} />
              </Row>
              <Row>
                <Col md={12} className={'contract-info__signBtn'}>
                  {this.getButtons(this.hasErrors())}
                </Col>
              </Row>
            </FormGroup>
          </Wrapper>
        </div>
      </Page>
    );
  }
}

export default ContactInformation;
