import {fromJS} from 'immutable';
import {initialState} from '../initialstate';

export const resetPerson = state => {
  const initialMainReducer = initialState.get('mainReducer');
  return state
    .set('leadInfo', initialMainReducer.get('leadInfo'))
    .set('plan', initialMainReducer.get('plan'))
    .set('validNif', initialMainReducer.get('plan'));
};

export const setContractPrices = (state, action) => {
  return state
    .setIn(['contractInfo', 'totalPrice'], action.totalPrice)
    .setIn(['contract', 'promoValid'], action.promo)
    .setIn(['contractInfo', 'membershipPrice'], action.membershipPrice)
    .setIn(['contractInfo', 'starterPackPrice'], action.starterPackPrice)
    .setIn(['contractInfo', 'monthlyPrice'], action.monthlyPrice)
    .setIn(['contractInfo', 'remainingDaysInMonth'], action.remainingDaysInMonth)
    .setIn(['contractInfo', 'followingMonthsPrice'], action.followingMonthsPrice)
    .setIn(['contractInfo', 'promoExpires'], action.promoExpires);
};
export const addToPlan = (state, action) => {
  let plan = state.get('plan').toJS();
  action.lesson['weekNumber'] = action.number;
  plan.push(action.lesson);
  return state.set('plan', fromJS(plan));
};
export const removeFromPlan = (state, action) => {
  let plan = state.get('plan').toJS();
  for (let i = 0; i < plan.length; i++) {
    if (
      plan[i].programId === action.lesson.programId &&
      plan[i].weekNumber === action.number &&
      plan[i].weekDay === action.lesson.weekDay &&
      plan[i].startTime === action.lesson.startTime
    ) {
      plan.splice(i, 1);
      break;
    }
  }
  return state.set('plan', fromJS(plan));
};
export const createPerson = (state, action) => {
  const payload = JSON.parse(JSON.stringify(action.data));
  payload.name = payload.name || state.get('person').get('name');
  payload.surname = payload.surname || state.get('person').get('surname');
  payload.birthDate = payload.birthDate || state.get('person').get('birthDate');
  payload.mobile = payload.mobile || state.get('person').get('mobile');
  payload.gender = payload.sex || state.get('person').get('sex');
  payload.passport = !payload.nif && payload.passport;
  payload.isCitizen = Boolean(payload.nif);
  payload.docTypeCannotBeChanged = true;
  payload.gdpr = payload.communication ? payload.communication.gdprOptin : null;
  delete payload.sex;
  delete payload.communication;
  return state.set('person', fromJS(payload)).set('duplicatePerson', fromJS({duplicate: false, selected: null, duplicates: [], loader: false}));
};
