import {fromJS} from 'immutable';
import {rootReducer} from './reducers';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {apiMiddleware} from '../../api';
import {initialState} from './initialstate';
import {validateMiddleware} from '../middleware/middleware';
import {checkRefresh, prepareForRefresh} from '../../utils/storageReset';
import {composeWithDevTools} from 'redux-devtools-extension';

checkRefresh();
window.onbeforeunload = () => prepareForRefresh();

/**
 * Any of the state can be used by any component in the application
 *
 * All objects (and the state itself) in the state must strictly be immutable
 * Please converted to plain JS in the Selector
 */

if (!localStorage.getItem('initialState') || localStorage.getItem('initialState') === 'undefined') localStorage.setItem('initialState', JSON.stringify(initialState));

/**
 * Checks if initalState was changed, so it can be compatible with data inside of local storage
 */

if (localStorage.getItem('initialState') !== JSON.stringify(initialState)) {
  localStorage.setItem('initialState', JSON.stringify(initialState));
  localStorage.setItem('state', JSON.stringify(initialState));
}

/**
 * Create a Redux store using the initialState and apply redux-logger middleware
 * (See your browser's console for the Magic)
 */

if (!localStorage.getItem('state') || localStorage.getItem('state') === 'undefined') localStorage.setItem('state', JSON.stringify(initialState));

let currentState = JSON.parse(localStorage.getItem('state'));

const store = createStore(rootReducer, fromJS(currentState), composeWithDevTools(applyMiddleware(thunk, validateMiddleware, apiMiddleware)));

store.subscribe(() => {
  try {
    localStorage.setItem('state', JSON.stringify(store.getState()));
  } catch (exception) {
    console.error('Error with local storage', exception);
  }
});

export {store, initialState};
