import {connect} from 'react-redux';
import {connectLanguage} from '../../../utils/connectLanguage';
import {setNumber} from '../../CalendarView/actions';
import {selectLoader} from '../selectors';
import ClassesScheduleComponent from './ClassesSchedule';
import {getWeekNumber} from '../../../App/selectors';

const mapStateToProps = state => {
  return {
    loader: selectLoader(state),
    weekNumber: getWeekNumber(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNext: number => dispatch(setNumber(number + 7)),
    loadPrevious: number => dispatch(setNumber(number - 7)),
  };
};

const Detail = connect(mapStateToProps, mapDispatchToProps)(ClassesScheduleComponent);
export default connectLanguage(Detail, connect);
