import React from 'react';
import {Grid, Col} from 'react-bootstrap';

import Loader from '../Loader';

const Wrapper = React.memo(props => {
  return (
    <div>
      {props.loader && props.load ? (
        <Loader />
      ) : (
        <Grid>
          <Col md={12}>
            <Col md={5} mdOffset={5}>
              {props.title}
            </Col>
            {props.children}
          </Col>
        </Grid>
      )}
    </div>
  );
});
export default Wrapper;
