import axios from './config';

export function getClubs() {
  const fileds = ['id', 'name', 'externalId', 'regionId', 'externalSalesProcessURL', 'status', 'isDspActive'];
  return axios.get(`/memberzone/clubs?field=${fileds.join('&field=')}`);
}

export function login(username, password, clubId, pinCode) {
  return axios.post('/admin/signin/salesperson', {
    username,
    password,
    clubId,
    pinCode,
  });
}

export function clubInfo(clubId) {
  return axios.get(`/admin/club/detail/${clubId}`).then(response => response.data);
}

export function clubSchedule(clubId, langId) {
  return axios.get(`/admin/club/schedule/${clubId}/${langId}`).then(response => response.data);
}

export function clubPages(clubId, langId) {
  return axios.get(`/admin/club/pages/${clubId}/${langId}`).then(response => response.data);
}

export function clubEvents(regionId, langId, websiteId) {
  return axios.get(`/backoffice/blog/pages/${regionId}/${langId}?websiteId=${websiteId}&type=5&count=50`).then(response => response.data);
}

export function clubNews(regionId, langId, websiteId) {
  return axios.get(`/backoffice/blog/pages/${regionId}/${langId}?websiteId=${websiteId}&type=7&count=50`).then(response => response.data);
}

export function clubMemberships(clubId, langId) {
  return axios.get(`/backoffice/membership/${clubId}/${langId}`).then(response => response.data);
  // return axios.get(`/admin/club/memberships/${clubId}/${langId}`).then(response => response.data);
}

export function clubStarterpacks(clubId, langId) {
  return axios.get(`/backoffice/starterpack/${clubId}/${langId}`).then(response => response.data);
  // return axios.get(`/admin/club/starterpacks/${clubId}/${langId}`).then(response => response.data);
}

export function clubPagesByType(regionId, langId, websiteId, type = 1) {
  return axios.get(`/backoffice/blog/pages/${regionId}/${langId}?websiteId=${websiteId}&type=${type}`).then(response => response.data);
}

export function checkPerson(email, clubExternalId) {
  return axios.post('/admin/person/check', {email, clubExternalId});
}

export function verifyPerson(data) {
  return axios.post('/admin/verify', data);
}

export function insertTwoWeeksPlan(plan, person, monday, langId) {
  return axios.post('/admin/twoweeksplan/insert', {
    plan,
    person,
    monday,
    langId,
  });
}

export function countPrice(clubExternalId, membershipExternalId, starterPackExternalId, promoCode, startDate) {
  return axios.post('/admin/price', {
    clubExternalId,
    membershipExternalId,
    starterPackExternalId,
    promoCode,
    startDate,
  });
}

export function confirmProspect(data) {
  return axios.post('/admin/confirm/prospect', data);
}

export function getSalespersonsName() {
  return axios.get('admin/name/salesperson');
}

export function checkNif(clubExternalId, nif) {
  return axios.post('/admin/validate/nif', {
    clubExternalId,
    nif,
  });
}

export function confirmMemberDSP(data) {
  return axios.post('/admin/orders/onlinesalesprocess', data);
}

export function prepareCheckout({regionId, person, amount, currency, reference}) {
  return axios.post(`/admin/payments/prepareCheckout/${regionId}`, {
    amount,
    currency,
    paymentType: 'DB',
    paymentBrand: 'VISA',
    customer: {
      reference: reference,
      name: person.name,
      surname: person.surname,
      email: person.email,
      phone: `${person.phoneCode}${person.mobile}`,
    },
    createRegistration: true,
  });
}

export function getRedirectUrl({regionId, reference, amount, currency, languageCode, returnURL, cancelURL, iban, bic}) {
  const query = [
    `reference=${reference}`,
    `amount=${amount}`,
    `currency=${currency}`,
    `languageCode=${languageCode}`,
    `returnURL=${returnURL}`,
    `cancelURL=${cancelURL}`,
    `iban=${iban}`,
    `bic=${bic}`,
  ];
  return axios.get(`/admin/payments/getredirecturl/${regionId}?${query.join('&')}`);
}

export function newMemberNotify({regionId, reference}) {
  return axios.post(`/admin/payments/notify/${regionId}?reference=${reference}`, null, {headers: {'Content-Type': 'application/json'}});
}
