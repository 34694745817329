import {browserHistory} from 'react-router';
import {setState} from '../../Actions/index';
import {TELLUSMORE_PAGE} from '../../App/constants';

const goToTellUsMore = () => {
  browserHistory.push(TELLUSMORE_PAGE);
  return setState();
};

export {goToTellUsMore};
